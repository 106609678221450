import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const sharedLinkStyles = css`
  color: ${({ active, theme }) => (active ? theme.colors.neutral.white : theme.text.primary)};
  background-color: ${({ active, theme }) => (active ? theme.buttons.primary : "transparent")};

  display: flex;
  align-items: center;

  padding: 10px 20px;
  margin-bottom: 5px;

  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.buttons.primaryHover};
    color: ${({ theme }) => theme.colors.neutral.white};

    .sidebar-icon {
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.neutral.white} !important;
        }
      }
    }
  }
`;

export const LinkContainer = styled(({ active, ...props }) => <Link {...props} />)`
  ${sharedLinkStyles}
`;

export const LinkIcon = styled.div`
  margin-right: 10px;
`;

export const LinkLabel = styled.span`
  font-size: 14px;
`;
