import styled from "styled-components";
import noImgIcon from "../../../assets/images/no-image-icon.png";
import noDockerIcon from "../../../assets/images/no-docker-icon.png";

const styles = {
  VenueCardContainer: styled.div`
    width: 233px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.neutral.white};
    position: relative;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0px 5px 5px 0px ${({ theme }) => theme.colors.shadows.dark},
        0px 10px 20px 0px ${({ theme }) => theme.colors.shadows.light};
    }
  `,
  Image: styled.img`
    height: 216px;
    width: 233px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    background: ${({ theme }) => theme.surfaces.background};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => (props.img ? `url(${props.img})` : `url(${noImgIcon})`)};
    object-fit: cover;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus {
      ${(props) => !props.hasDockerId && "background-image: url(" + noDockerIcon + ")"};
      outline: none;
    }
  `,
  CheckboxContainer: styled.div`
    position: absolute;
    top: 8px;
    left: 8px;
  `,
  StatusCircleContainer: styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
  `,
  Info: styled.div`
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 0 0;
  `,
  Title: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    user-select: none;
  `,
  Subtitle: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
    opacity: 0.7;
    max-width: 137px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  `,
  Actions: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    user-select: none;

    & > * {
      user-select: none;

      &:focus {
        outline: none;
      }
    }
  `,
};

export default styles;
