import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_VENUE } from "graphql/graph";

const useVenueData = (venueId) => {
  const [venue, setVenue] = useState(null);
  const [expandedAreas, setExpandedAreas] = useState({});
  const {
    data: retrieveVenueData,
    loading: retrieveVenueLoading,
    error: retrieveVenueError,
  } = useQuery(GET_VENUE, {
    context: { clientName: "graph" },
    variables: { id: venueId },
  });

  useEffect(() => {
    if (retrieveVenueData) {
      setVenue(retrieveVenueData.retrieveVenue.data);
      const initialExpandedAreas = retrieveVenueData.retrieveVenue.data.Areas.reduce(
        (acc, area) => {
          acc[area.id] = true;
          return acc;
        },
        {},
      );
      setExpandedAreas(initialExpandedAreas);
    } else {
      setVenue(null);
      setExpandedAreas({});
    }
  }, [retrieveVenueData]);

  const totalCameras = venue?.Areas.reduce((acc, area) => acc + area.Machines.length, 0) || 0;
  const activeCameras =
    venue?.Areas.reduce(
      (acc, area) => acc + area.Machines.filter((machine) => machine.machineStatus === 1).length,
      0,
    ) || 0;
  const totalInferences =
    venue?.Areas.reduce(
      (acc, area) => acc + area.Machines.filter((machine) => machine.machineInferenceStatus).length,
      0,
    ) || 0;

  const overviewItems = [
    { value: totalCameras, label: "Cameras", icon: "cameras" },
    { value: activeCameras, label: "Active Cameras", icon: "venues" },
    { value: totalInferences, label: "Inferences", icon: "inferences" },
  ];

  return {
    venue,
    expandedAreas,
    setExpandedAreas,
    retrieveVenueLoading,
    retrieveVenueError,
    overviewItems,
  };
};

export default useVenueData;
