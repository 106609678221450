import styled from "styled-components/macro";

const styles = {
  ManageModelsContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  HeaderText: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,
  StatusContainer: styled.div`
    display: flex;
    padding: 8px 10px 8px 8px;
    align-items: center;
    justify-content: space-evenly;
    gap: 7px;
    width: 100px;
    border-radius: 180px;
    background: ${({ theme }) => theme.surfaces.background};
    border: 1px solid
      ${({ theme, isActive }) => (isActive ? theme.camera.active : theme.colors.neutral.grey500)};
  `,
  TableContainer: styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;
  `,
  TableHead: styled.thead``,
  TableBody: styled.tbody``,
  TableRow: styled.tr`
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    }
  `,
  TableHeader: styled.th`
    background: ${({ theme }) => theme.colors.neutral.white};
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    padding: 0px 16px 12px 16px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    opacity: 0.6;
  `,
  TableCell: styled.td`
    height: 68px;
    padding: 12px;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    vertical-align: middle;
    positon: relative;

    &:last-child {
      width: 68px;
      padding: 12px;
    }
  `,
  MenuWrapper: styled.div`
    display: flex;
    justify-content: right;
    cursor: pointer;
    position: relative;
  `,
  MenuCard: styled.div`
    display: flex;
    width: 161px;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.13);
    position: absolute;
    right: 5px;
    margin-right: 10px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  MenuItemsContainer: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `,
  MenuItem: styled.div`
    gap: 6px;
    align-items: center;
    display: flex;
    cursor: pointer;
  `,
};

export default styles;
