import React, { useEffect } from "react";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Icons
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
//Styles
import Styles from "./styles";
//Components
import { FormGroup } from "../../Shared/FormGroup";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import Icon from "components/Shared/Icon/Icon";

const updatePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  password: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .max(15)
    .required("Password is required"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
});

const UpdatePassword = (props) => {
  const {
    updatePassword,
    updatePasswordData,
    updatePasswordSuccess,
    setUpdatePasswordSuccess,
    updatePasswordError,
    setUpdatePasswordError,
    showOldPassword,
    setShowOldPassword,
    showNewPassword,
    setShowNewPassword,
    showConfirmPassword,
    setShowConfirmPassword,
  } = props;

  //React-Hook-Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  //Effects
  useEffect(() => {
    if (updatePasswordData) {
      reset();
    }
  }, [updatePasswordData, reset]);

  useEffect(() => {
    if (
      errors.oldPassword?.message ||
      errors.password?.message ||
      errors.confirmPassword?.message
    ) {
      setUpdatePasswordSuccess(false);
      setUpdatePasswordError("");
    }
  }, [errors, setUpdatePasswordSuccess, setUpdatePasswordError]);

  //Handlers
  const handleUpdatePassword = (updatePasswordFormData) => {
    updatePassword({
      variables: {
        oldPassword: updatePasswordFormData.oldPassword.trim(),
        newPassword: updatePasswordFormData.password.trim(),
      },
    });
  };

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  usePreventEnterAction(handleUpdatePassword);

  return (
    <Styles.FormAndTitleContainer>
      <Styles.Title>Update Your Password</Styles.Title>
      <Styles.Form onSubmit={handleSubmit(handleUpdatePassword)}>
        <Styles.FormContainer>
          <FormGroup
            labelText={"Type your old password"}
            errorMessage={errors.oldPassword?.message}
            profileForm
            required
          >
            <Styles.InputContainer>
              <Styles.Input
                type={showOldPassword ? "text" : "password"}
                name="oldPassword"
                {...register("oldPassword")}
              />
              <Styles.IconWrapper onClick={handleShowOldPassword}>
                {showOldPassword ? (
                  <BsEyeSlashFill />
                ) : (
                  <Icon name="eye" className="sidebar-icon" isActive={false} />
                )}
              </Styles.IconWrapper>
            </Styles.InputContainer>
          </FormGroup>
          <FormGroup
            labelText={"Type your new password"}
            errorMessage={errors.password?.message}
            profileForm
            required
          >
            <Styles.InputContainer>
              <Styles.Input
                type={showNewPassword ? "text" : "password"}
                name="password"
                {...register("password")}
              />
              <Styles.IconWrapper onClick={handleShowNewPassword}>
                {showNewPassword ? (
                  <BsEyeSlashFill />
                ) : (
                  <Icon name="eye" className="sidebar-icon" isActive={false} />
                )}
              </Styles.IconWrapper>
            </Styles.InputContainer>
          </FormGroup>
          <FormGroup
            labelText={"Confirm your new password"}
            errorMessage={errors?.confirmPassword && "Passwords must match!"}
            profileForm
            required
          >
            <Styles.InputContainer>
              <Styles.Input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                {...register("confirmPassword")}
              />
              <Styles.IconWrapper onClick={handleShowConfirmPassword}>
                {showConfirmPassword ? (
                  <BsEyeSlashFill />
                ) : (
                  <Icon name="eye" className="sidebar-icon" isActive={false} />
                )}
              </Styles.IconWrapper>
            </Styles.InputContainer>
          </FormGroup>
        </Styles.FormContainer>
        {updatePasswordSuccess ? (
          <Styles.SubmitSuccess>Password updated successfully!</Styles.SubmitSuccess>
        ) : updatePasswordError ? (
          <Styles.SubmitError>{updatePasswordError}</Styles.SubmitError>
        ) : null}
        <PrimaryBtn
          type="submit"
          label="Update Password"
          padding="10px 20px"
          icon="edit"
          iconSize={"20px"}
        />
      </Styles.Form>
    </Styles.FormAndTitleContainer>
  );
};

export default UpdatePassword;
