import React, { useState, useRef } from "react";
//Styles
import Styles from "./styles";
// Components
import CompanyVenues from "./CompanyVenues";
import UpdateCompanyModal from "../../../Modals/AddUpdateCompany/UpdateCompany";
import { useToastModal } from "../../../../context/ToastModalContext";
import Icon from "components/Shared/Icon/Icon";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
import AddVenueModal from "../../../Modals/AddUpdateVenue/AddVenue";
//Hooks
import useClickOutside from "hooks/useClickOutside";

const CompanyRow = ({
  company,
  createVenue,
  updateVenue,
  deleteVenue,
  updateCompany,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
}) => {
  const [companyObj, setCompanyObj] = useState({});
  const [showUpdateCompanyModal, setShowUpdateCompanyModal] = useState(false);
  const [showAddVenueModal, setShowAddVenueModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const menuRef = useRef(null);

  const { addToast } = useToastModal();

  const handleUpdateCompanyClick = (company) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, company.id)) {
      setCompanyObj({ id: company.id, companyName: company.companyName });
      setShowUpdateCompanyModal((prev) => !prev);
    } else {
      addToast("You must be a company Admin to update company!", "error");
    }
  };

  const handleClickAddVenue = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, company)) {
      setShowAddVenueModal((prev) => !prev);
    } else {
      addToast("You must be a Company Admin to add a Venue!", "error");
    }
  };

  const handleToggleMenu = (company) => {
    setSelectedCompany((prev) => (prev === company ? null : company));
  };

  useClickOutside(menuRef, () => setSelectedCompany(null));

  const renderMenuCard = (company) => {
    return (
      <Styles.CompanyMenuCard ref={menuRef}>
        <Styles.MenuItemsContainer>
          <Styles.MenuItem onClick={() => handleUpdateCompanyClick(company)}>
            <Icon name="edit" size="18px" />
            <span>Edit</span>
          </Styles.MenuItem>
        </Styles.MenuItemsContainer>
      </Styles.CompanyMenuCard>
    );
  };
  return (
    <Styles.CompanyContainer>
      <Styles.HeaderContainer>
        <Styles.CompanyNameContainer>
          <Styles.LogoContainer>
            <Icon name="venue" size="38px" />
          </Styles.LogoContainer>
          <Styles.CompanyName>{company.companyName}</Styles.CompanyName>
          <Styles.MenuWrapper onClick={() => handleToggleMenu(company)}>
            {selectedCompany === company && renderMenuCard(company)}
            <Icon name="menu" size="11.667px" />
          </Styles.MenuWrapper>
        </Styles.CompanyNameContainer>
        <SecondaryBtn
          icon="plus"
          size="20px"
          label="Add Venue"
          padding="10px 20px"
          onClick={handleClickAddVenue}
        />
        <UpdateCompanyModal
          showModal={showUpdateCompanyModal}
          setShowModal={setShowUpdateCompanyModal}
          companyObj={companyObj}
          updateCompany={updateCompany}
          title={"Update Company Name"}
        />
        <AddVenueModal
          showModal={showAddVenueModal}
          setShowModal={setShowAddVenueModal}
          createVenue={createVenue}
          companyId={company.id}
          title={`Add Venue to ${company.companyName}`}
        />
      </Styles.HeaderContainer>

      <Styles.Divider />
      <CompanyVenues
        companyId={company.id}
        venues={company.Venues}
        createVenue={createVenue}
        updateVenue={updateVenue}
        deleteVenue={deleteVenue}
        checkUserPermissionIsAdmin={checkUserPermissionIsAdmin}
        loggedUserAttributes={loggedUserAttributes}
      />
    </Styles.CompanyContainer>
  );
};

export default CompanyRow;
