import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { START_INFERENCE, STOP_INFERENCE } from "graphql/graph";
import { checkUserPermissionIsAdmin } from "configs/helpers";
import { AuthContext } from "context/authContext";
import { useToastModal } from "context/ToastModalContext";

const useInferenceHandlers = (camera) => {
  const { loggedUserAttributes } = useContext(AuthContext);
  const { addToast, addModal } = useToastModal();

  const [startInference] = useMutation(START_INFERENCE, {
    context: { clientName: "graph" },
    onError: (error) => {
      addToast(`Failed to start inference: ${error.message}`, "error");
    },
  });

  const [stopInference] = useMutation(STOP_INFERENCE, {
    context: { clientName: "graph" },
    onError: (error) => {
      addToast(`Failed to stop inference: ${error.message}`, "error");
    },
  });

  const handleClickStartInference = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, camera.companyId, camera.venueId)) {
      addModal(`Start Inference ${camera.machineName} camera ?`, false, () => {
        startInference({
          variables: {
            id: camera.id,
          },
        }).then(() => {
          addToast("Inference started successfully", "success");
        });
      });
    } else {
      addToast("You must be a venue Admin to start inference!", "error");
    }
  };

  const handleClickStopInference = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, camera.companyId, camera.venueId)) {
      addModal(`Stop Inference ${camera.machineName} camera ?`, false, () => {
        stopInference({
          variables: {
            id: camera.id,
          },
        }).then(() => {
          addToast("Inference stopped successfully", "success");
        });
      });
    } else {
      addToast("You must be a venue Admin to stop inference!", "error");
    }
  };

  return { handleClickStartInference, handleClickStopInference };
};

export default useInferenceHandlers;
