import React, { useEffect } from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

const updateCompanySchema = yup.object().shape({
  companyName: yup.string().required("Company name is a required field."),
});

const UpdateCompanyModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  updateCompany,
  companyObj,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateCompanySchema),
    },
    { defaultValues: companyObj },
  );

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(companyObj);
  }, [reset, companyObj]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, update Company
  const submitForm = (companyFormData) => {
    updateCompany({
      variables: {
        id: companyObj.id,
        companyName: companyFormData.companyName,
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        handleModalForwardClick={handleModalForwardClick}
      >
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Name</Styles.Label>
            <Styles.Input
              type="text"
              name="companyName"
              /* placeholder="Company name ..." */
              {...register("companyName")}
            />
            <Styles.SubmitError>{errors.companyName?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              withIcon={false}
              width="138px"
              height="40px"
              onClick={handleClickCancelBtn}
            />
            <PrimaryBtn
              label="Update"
              withIcon={false}
              width="138px"
              height="40px"
              type="submit"
              id="submit"
            />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default UpdateCompanyModal;
