import React, { useRef } from "react";
// Styles
import Styled from "./ContextMenu.styled";
// Components
import Icon from "components/Shared/Icon/Icon";
// Hooks
import useClickOutside from "hooks/useClickOutside";

const ContextMenu = ({
  isVisible,
  onClose,
  position,
  onRefreshRTSP,
  onStartInferencing,
  onUpdateArea,
  onDeleteArea,
}) => {
  const contextMenuRef = useRef(null);

  useClickOutside(contextMenuRef, onClose);

  if (!isVisible) return null;

  const handleActionClick = (action) => {
    action();
    onClose();
  };

  return (
    <Styled.ContextMenuContainer ref={contextMenuRef} style={position}>
      <Styled.ContextMenuHeading>Group actions</Styled.ContextMenuHeading>

      {/* <Styled.MenuItem onClick={() => handleActionClick(() => {})}>
        <Icon name="select_multiple" />
        Select all cameras
      </Styled.MenuItem> */}
      <Styled.MenuItem onClick={() => handleActionClick(onRefreshRTSP)}>
        <Icon name="refresh" />
        Refresh RTSP preview all cameras
      </Styled.MenuItem>
      <Styled.MenuItem onClick={() => handleActionClick(onStartInferencing)}>
        <Icon name="start_inference" />
        Start inferencing all cameras
      </Styled.MenuItem>
      <Styled.MenuItem onClick={() => handleActionClick(onUpdateArea)}>
        <Icon name="edit_pencil" />
        Edit area’s name
      </Styled.MenuItem>
      <Styled.MenuItem onClick={() => handleActionClick(onDeleteArea)}>
        <Icon name="trash_empty" />
        Delete area
      </Styled.MenuItem>
    </Styled.ContextMenuContainer>
  );
};

export default ContextMenu;
