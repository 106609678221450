import React from "react";
//Styles
import Styles from "./styles";
//Components
import SpinnerComponent from "../../../../components/Loader";
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
import { useToastModal } from "../../../../context/ToastModalContext";
//GraphQl
import { useQuery } from "@apollo/client";
import { RETRIEVE_USER_VENUE_AND_COMPANY } from "../../../../graphql/graph";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";

const VenuesAndRole = ({
  setUserCurrentVenuePermission,
  setShowAddVenueToUserModal,
  setClickedUserId,
  setShowVenuePermissionTypeModal,
  user,
  companyId,
  modalTitles,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
  setSelectedUserEmail,
  removeUserFromVenue,
}) => {
  const {
    data: retrieveUserVenueAndCompanyData,
    //TODO Handle graphql error
    loading: retrieveUserVenueAndCompanyLoading,
    /* error: retrieveUserVenueAndCompanyError, */
  } = useQuery(RETRIEVE_USER_VENUE_AND_COMPANY, {
    variables: {
      id: user.userId,
    },
    context: { clientName: "graph" },
  });
  const { addToast, addModal } = useToastModal();

  const handleClickAssignVenue = () => {
    setClickedUserId(user.userId);
    setSelectedUserEmail(user.userEmail);
    setShowAddVenueToUserModal((prev) => !prev);
  };

  const handleClickEditVenuePermission = (id, permissionType, venueId) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      setUserCurrentVenuePermission({
        id,
        permissionType,
      });
      setClickedUserId(user.userId);
      setShowVenuePermissionTypeModal((prev) => !prev);
    } else {
      addToast("You must be a venue Admin to edit this user venue permissions!", "error");
    }
  };

  const handleClickRemoveUserFromVenue = (venueId) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      addModal(modalTitles.removeUserFromVenueConfirmation, false, () => {
        removeUserFromVenue(user.userId, venueId);
      });
    } else {
      addToast("You must be a venue Admin to remove this user!", "error");
    }
  };

  return (
    <>
      {retrieveUserVenueAndCompanyLoading ? (
        <Styles.LoaderContainer>
          <SpinnerComponent width={"4rem"} height={"4rem"} />
        </Styles.LoaderContainer>
      ) : retrieveUserVenueAndCompanyData?.retrieveUserVenueAndCompany?.data.Venues.length > 0 ? (
        retrieveUserVenueAndCompanyData?.retrieveUserVenueAndCompany?.data.Venues.map(
          (venue, i) => (
            <Styles.VenueRowContainer key={venue.venueId}>
              <Styles.VenuesRow
                lastRow={
                  retrieveUserVenueAndCompanyData?.retrieveUserVenueAndCompany?.data.Venues.length -
                    1 ===
                  i
                    ? +true
                    : +false
                }
              >
                <Styles.VenueName>{venue.venueName}</Styles.VenueName>
                <Styles.UserRole>{venue.permissionType}</Styles.UserRole>
                <Styles.ActionIconsContainer>
                  <Styles.EditIcon
                    onClick={() =>
                      handleClickEditVenuePermission(venue.id, venue.permissionType, venue.venueId)
                    }
                  />
                  <Styles.DeleteIcon
                    onClick={() => handleClickRemoveUserFromVenue(venue.venueId)}
                  />
                </Styles.ActionIconsContainer>
              </Styles.VenuesRow>
            </Styles.VenueRowContainer>
          ),
        )
      ) : (
        <Styles.LoaderContainer>
          <Styles.NoUsersText>No venues assign to this user!</Styles.NoUsersText>
        </Styles.LoaderContainer>
      )}
      {
        <Styles.AssignVenueBtnContainer>
          <PrimaryBtn
            label="Assign Venue"
            padding="10px 20px"
            handleClick={handleClickAssignVenue}
            id={user.userId}
          />
        </Styles.AssignVenueBtnContainer>
      }
    </>
  );
};

export default VenuesAndRole;
