import styled from "styled-components";

const styles = {
  VenueContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
  `,
  VenueCardsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
  `,
  AreaContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  AreaHeader: styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
  `,
  AreaName: styled.h2`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    color: ${({ theme }) => theme.colors.primary.dark};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    user-select: none;

    &:focus {
      outline: none;
    }
  `,
  AreaIcons: styled.div`
    display: flex;
    gap: 8px;
    cursor: pointer;

    & > * {
      user-select: none;

      &:focus {
        outline: none;
      }
    }
  `,
  CamerasContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  `,
  NoDataMessage: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    color: ${({ theme }) => theme.colors.neutral.grey500};
    text-align: center;
    margin-top: 20px;
  `,
  CardsButtonContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
};

export default styles;
