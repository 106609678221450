import React from "react";
// Styles
import { sharedTableStyles as Styled } from "../../Shared/Shared.styled";
// Components
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
// Utils
import { arStatus, crStatus } from "configs/configEnviroment";
import { timeStampToDateString } from "configs/helpers";

const Row = ({ requestItem, updateItem, cameraMap }) => {
  const startDate = timeStampToDateString(
    requestItem.crStartNow ? requestItem.createdAt : requestItem.crStartTimestamp,
  );

  const collectionCompleteDate = timeStampToDateString(requestItem.crCollectionCompleteDate);

  const arCollectionCompleteDate = timeStampToDateString(
    requestItem.AnnotationRequest?.arCollectionCompleteDate,
  );

  const startTimeText = requestItem.crStartNow
    ? `Immediately ${startDate}`
    : startDate.toLocaleString();

  const crCollectionStatus = `${
    crStatus[requestItem.crCollectionStatus]
  } ${collectionCompleteDate}`;

  // if there is annotation
  const annotationStatus = requestItem.AnnotationRequest
    ? `${arStatus[requestItem.AnnotationRequest?.arStatus]} ${arCollectionCompleteDate}`
    : "";

  return (
    <Styled.TableRowEntriesContainer>
      <Styled.TableRowEntries>{cameraMap[requestItem.machineId]}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{startTimeText}</Styled.TableRowEntries>
      <Styled.TableRowEntries>
        {requestItem.crMaxFrames} frames/{requestItem.crInterval} sec
      </Styled.TableRowEntries>
      <Styled.TableRowEntries>{crCollectionStatus}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{annotationStatus}</Styled.TableRowEntries>
      <Styled.TableRowEntries align="center" narrow="true">
        <PrimaryBtn width={"70px"} label="Edit" handleClick={() => updateItem(requestItem)} />
      </Styled.TableRowEntries>
    </Styled.TableRowEntriesContainer>
  );
};

export const AnnotationRequests = ({ requestsList, updateItem, cameraMap }) => {
  const renderNestedRows = () => {
    return requestsList.map((requestItem) => {
      return (
        <Styled.TableRow key={requestItem.id}>
          <Row requestItem={requestItem} updateItem={updateItem} cameraMap={cameraMap} />
        </Styled.TableRow>
      );
    });
  };

  return (
    <Styled.Table>
      <Styled.TableHeader>
        <Styled.TableHeaderEntriesContainer>
          <Styled.TableHeaderEntries>Camera</Styled.TableHeaderEntries>
          <Styled.TableHeaderEntries>Start Date</Styled.TableHeaderEntries>
          <Styled.TableHeaderEntries>Max Frames/Interval</Styled.TableHeaderEntries>
          <Styled.TableHeaderEntries>Capture</Styled.TableHeaderEntries>
          <Styled.TableHeaderEntries>Annotation</Styled.TableHeaderEntries>
          <Styled.TableHeaderEntries narrow="true"></Styled.TableHeaderEntries>
        </Styled.TableHeaderEntriesContainer>
      </Styled.TableHeader>
      {renderNestedRows()}
    </Styled.Table>
  );
};

export default AnnotationRequests;
