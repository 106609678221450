import styled from "styled-components/macro";

const styles = {
  UserProfileContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    margin: 0;
  `,
};

export default styles;
