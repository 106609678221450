import { useMemo } from "react";
// Utils
import { formatName } from "utils/formatName";

const useTableData = (venueDetails) => {
  const tableData = useMemo(() => {
    if (!venueDetails) return [];

    return venueDetails.reduce((acc, venue) => {
      if (venue.Areas.length === 0) {
        acc.push({
          venueId: venue.id,
          venue: venue.venueName,
          areaId: null,
          area: "N/A",
          camera: "Please add a camera",
          controller: "N/A",
          inference: "Inactive",
          isActive: false,
          useCase: "N/A",
        });
      } else {
        venue.Areas.forEach((area) => {
          if (area.Machines.length === 0) {
            acc.push({
              venueId: venue.id,
              venue: venue.venueName,
              areaId: area.id,
              area: area.areaName,
              camera: "Please add a camera",
              controller: "N/A",
              inference: "Inactive",
              isActive: false,
              useCase: "N/A",
            });
          } else {
            area.Machines.forEach((camera) => {
              acc.push({
                id: camera.id,
                dockerId: camera.dockerId,
                venueId: camera.venueId,
                venue: venue.venueName,
                areaId: area.id,
                area: area.areaName,
                camera: camera.machineName,
                controller: formatName(camera.dockerURL),
                inference: camera.machineStatus === 1 ? "Active" : "Inactive",
                isActive: camera.machineStatus === 1,
                useCase: formatName(camera.machineModel),
                thumbnail: camera.thumbnail?.machineThumbnailURL || "No thumbnail",
              });
            });
          }
        });
      }
      return acc;
    }, []);
  }, [venueDetails]);

  return tableData;
};

export default useTableData;
