import styled from "styled-components/macro";

const IconWrapper = styled(({ isActive, size, ...props }) => <div {...props} />).attrs((props) => ({
  size: props.size,
}))`
  display: inline-block;

  svg {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }

  &.sidebar-icon {
    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: ${({ isActive, theme }) =>
          isActive ? theme.colors.neutral.white : theme.colors.neutral.grey400} !important;
        stroke-width: 2;
      }
    }
  }
  &.tab-icon {
    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: ${({ isActive, theme }) =>
          isActive ? theme.colors.neutral.white : theme.colors.primary.blue800} !important;
        stroke-width: 2;
      }
    }
  }
  &.action-icon {
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const styles = {
  IconWrapper,
};

export default styles;
