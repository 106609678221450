import styled from "styled-components";

const styles = {
  OverviewContainer: styled.div``,
  ItemsContainer: styled.div`
    display: flex;
    gap: 8px;
    margin-top: 12px;
  `,
  Title: styled.h1`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
};

export default styles;
