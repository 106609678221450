import React from "react";
// Styles
import Styled from "./SideBarLink.styled";
// Components
import Icon from "components/Shared/Icon/Icon";
// Tools
import { useLocation } from "react-router-dom";

const SidebarLink = ({ label, icon, path, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <Styled.LinkContainer to={path} active={isActive} onClick={onClick}>
      <Styled.LinkIcon>
        <Icon name={icon} className="sidebar-icon" isActive={isActive} />
      </Styled.LinkIcon>
      <Styled.LinkLabel>{label}</Styled.LinkLabel>
    </Styled.LinkContainer>
  );
};

export default SidebarLink;
