import React, { useState } from "react";
// Styles
import Styled from "./Fallback.styled";

const FallbackUI = ({ message = "An unexpected error occurred. Please try again.", onRetry }) => {
  const [isRetrying, setIsRetrying] = useState(false);

  const handleRetry = async () => {
    setIsRetrying(true);
    try {
      await onRetry();
    } finally {
      setIsRetrying(false);
    }
  };

  return (
    <Styled.FallbackContainer>
      <Styled.ErrorMessage>{message}</Styled.ErrorMessage>
      <Styled.RetryButton onClick={handleRetry} disabled={isRetrying}>
        {isRetrying ? "Retrying..." : "Retry"}
      </Styled.RetryButton>

      <Styled.ContactSupport>
        If the problem persists, please contact our administrators.
      </Styled.ContactSupport>
    </Styled.FallbackContainer>
  );
};

export default FallbackUI;
