import React from "react";
// Styles
import Styled from "./FiltersDropdown.styled";
// Components
import Checkbox from "components/Shared/Checkbox/Checkbox";

const FiltersDropdown = ({ filterData, selectedFilters, setSelectedFilters, filterType }) => {
  const handleCheckboxChange = (filter) => {
    const isChecked = selectedFilters[filterType].includes(filter.id || filter.name);
    setSelectedFilters((prev) => ({
      ...prev,
      [filterType]: isChecked
        ? prev[filterType].filter((item) => item !== (filter.id || filter.name))
        : [...prev[filterType], filter.id || filter.name],
    }));
  };

  return (
    <Styled.FiltersDropDownContainer>
      {filterData.length === 0 ? (
        <Styled.EmptyMessage>No data available</Styled.EmptyMessage>
      ) : (
        filterData.map((filter) => (
          <Styled.FilterItem key={filter.id || filter.name}>
            <Checkbox
              checked={selectedFilters[filterType].includes(filter.id || filter.name)}
              onChange={() => handleCheckboxChange(filter)}
            />
            <Styled.FilterText>{filter.name}</Styled.FilterText>
          </Styled.FilterItem>
        ))
      )}
    </Styled.FiltersDropDownContainer>
  );
};

export default FiltersDropdown;
