import React, { useState, useEffect, useContext } from "react";
//Tools
import { Outlet, useLocation, useParams } from "react-router-dom";
//Styles
import Styles from "./styles";
//Components
import Sidebar from "../../components/Sidebar/Sidebar";
import AddVenueModal from "../../containers/Modals/AddUpdateVenue/AddVenue";
import { useToastModal } from "../../context/ToastModalContext";
//GraphQl
import { useQuery, useMutation } from "@apollo/client";
import {
  LIST_COMPANY,
  CREATE_VENUE,
  LIST_OBJECT_DETECTION_ITEMS,
  LIST_CONFIG,
} from "../../graphql/graph";

import { DashboardContext } from "../../context/DashboardContext";

const Padme = () => {
  const {
    setObjectDetectionItems,
    companiesList,
    setCompaniesList,
    setPadmeModelColors,
    setPadmeModelTypes,
    setCompaniesDict,
  } = useContext(DashboardContext);

  const [activeLocation, setActiveLocation] = useState("");
  // const [companiesStateData, setCompaniesStateData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [showAddVenueModal, setShowAddVenueModal] = useState(false);
  const location = useLocation();
  const { companyId } = useParams();
  const [showSideMenu, setShowSideMenu] = useState(true);

  const {
    data: companiesData,
    loading: companiesLoading,
    error: companiesError,
  } = useQuery(LIST_COMPANY, {
    context: { clientName: "graph" },
  });

  const { data: objectDetectionData } = useQuery(LIST_OBJECT_DETECTION_ITEMS, {
    context: { clientName: "graph" },
  });

  const { data: configData } = useQuery(LIST_CONFIG, {
    context: { clientName: "graph" },
  });

  const [
    createVenue,
    //TODO: Handle GraphQl loading (if needed)
    {
      data: createVenueData,
      /* loading: createVenueLoading, */
      error: createVenuelError,
    },
  ] = useMutation(CREATE_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COMPANY,
        context: { clientName: "graph" },
      },
    ],
  });

  useEffect(() => {
    // Event handler to toggle based on window width
    const handleResize = () => {
      setShowSideMenu(window.innerWidth > 953); // Adjust the window width condition as needed
    };

    // Initial call to set the initial toggle state
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup: Remove the event listener when component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setActiveLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (configData && configData.listConfig) {
      setPadmeModelColors(
        configData.listConfig.data
          .filter((config) => config.configType === "MASKING_COLORS")
          .map((config) => ({
            label: config.configName,
            stroke: config.configValue,
            fill: config.configValue2,
          })),
      );

      setPadmeModelTypes(
        configData.listConfig.data
          .filter((config) => config.configType === "MASKING_TYPES")
          .map((config) => ({
            modelId: config.id,
            label: config.configName,
            value: config.configValue,
          })),
      );
    }
  }, [configData, setPadmeModelColors, setPadmeModelTypes]);

  useEffect(() => {
    if (companiesData) {
      setCompaniesList(companiesData.listCompany.data);
      // setCompaniesStateData(companiesData.listCompany.data);

      // write company dict as well
      const companyDict = [];

      companiesData.listCompany.data.forEach((row) => {
        companyDict[row.id] = row.companyName;
      });
      setCompaniesDict(companyDict);
    }
  }, [companiesData, setCompaniesDict, setCompaniesList]);

  useEffect(() => {
    if (objectDetectionData) {
      setObjectDetectionItems(objectDetectionData.listObjectDetectionItems.data);
    }
  }, [objectDetectionData, setObjectDetectionItems]);

  const { addToast } = useToastModal();

  //Toast Create Venue
  useEffect(() => {
    if (createVenueData) {
      addToast("Successfully added!", "success");
    }
    if (createVenuelError) {
      addToast("Something went wrong!", "error");
    }
  }, [createVenueData, createVenuelError]);

  //TODO: Handle GraphQl errors
  if (companiesError) return `Error! ${companiesError}`;

  const handleAddVenueModalClose = (modalState) => {
    setShowAddVenueModal(modalState);
    setSelectedCompanyName("");
  };

  return (
    <Styles.AlignmentContainer>
      <Styles.DashboardContainer>
        <Sidebar companiesList={companiesList} companiesLoading={companiesLoading} />

        <Styles.MainOutletContainer>
          <Outlet context={[companiesList]} />
        </Styles.MainOutletContainer>
      </Styles.DashboardContainer>

      <AddVenueModal
        showModal={showAddVenueModal}
        setShowModal={handleAddVenueModalClose}
        createVenue={createVenue}
        title={`Add Venue to ${selectedCompanyName}`}
        companyId={companyId}
      />
    </Styles.AlignmentContainer>
  );
};

export default Padme;
