import { useMutation } from "@apollo/client";
import { checkUserPermissionIsAdmin } from "configs/helpers";
import { useToastModal } from "context/ToastModalContext";
import { DELETE_AREA, GET_VENUE, PREVIEW_RTSP, START_ALL_INFERENCE } from "graphql/graph";

export const useVenueHandlers = (
  setShowUpdateAreaModal,
  setAreaNameObj,
  setCreateUpdateObj,
  setShowAddUpdateCameraModal,
  loggedUserAttributes,
  companyId,
) => {
  const { addToast, addModal } = useToastModal();

  const [previewRTSP] = useMutation(PREVIEW_RTSP, {
    context: { clientName: "graph" },
  });

  const [startAllInference] = useMutation(START_ALL_INFERENCE, {
    context: { clientName: "graph" },
  });

  const [deleteArea] = useMutation(DELETE_AREA, {
    context: { clientName: "graph" },
  });

  const handleClickPreviewRTSP = (venueId) => {
    previewRTSP({
      variables: {
        venueId,
      },
    })
      .then(() => {
        addToast("RTSP preview refreshed successfully!", "success");
      })
      .catch((error) => {
        addToast("Failed to refresh RTSP preview!", "error");
      });
  };

  const handleClickStartInference = (venueId, area) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      const machines = area.Machines;
      const machineIds = machines.map((machine) => machine.id);

      addModal(`Start Inference ${machineIds.length} cameras?`, false, () => {
        startAllInference({
          variables: {
            venueId,
            machineIds: JSON.stringify(machineIds),
          },
        })
          .then(() => {
            addToast("Inference started for all cameras successfully!", "success");
          })
          .catch((error) => {
            addToast("Failed to start inference for cameras!", "error");
          });
      });
    } else {
      addToast("You must be a Venue Admin to start camera!", "error");
    }
  };

  const handleClickUpdateArea = (area) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, area.venueId)) {
      setAreaNameObj({ id: area.id, areaName: area.areaName });
      setShowUpdateAreaModal((prev) => !prev);
    } else {
      addToast("You must be an Admin to edit area!", "error");
    }
  };

  const handleClickDeleteArea = ({ areaName, Machines }, venueId, areaId) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      const camerasTitle = Machines.length > 1 ? "cameras" : "camera";
      const modalMessage = `Delete ${areaName} and ${Machines.length} ${camerasTitle}?`;

      addModal(modalMessage, false, () => {
        deleteArea({
          variables: {
            id: areaId,
          },
          refetchQueries: [{ query: GET_VENUE, variables: { id: venueId } }],
        })
          .then(() => {
            addToast("Area successfully deleted!", "success");
          })
          .catch((error) => {
            addToast(`Failed to delete area! Error: ${error.message}`, "error");
          });
      });
    } else {
      addToast("You must be a Venue Admin to delete an area!", "error");
    }
  };

  const handleClickAddUpdateCamera = (venue) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venue?.id)) {
      setCreateUpdateObj({ venueId: venue.id, venueAreas: venue.Areas });
      setShowAddUpdateCameraModal(true);
    } else {
      addToast("You must be an Admin to add/update a camera!", "error");
    }
  };

  return {
    handleClickPreviewRTSP,
    handleClickStartInference,
    handleClickDeleteArea,
    handleClickUpdateArea,
    handleClickAddUpdateCamera,
  };
};
