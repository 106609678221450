import styled from "styled-components/macro";
import ReactSlider from "react-slider";
import { FaPlusCircle } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import arrow_down from "../../assets/icons/arrow-down.svg";

const styles = {
  SpinnerContainer: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  `,
  TwoColumnContainer: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: ${({ wide }) => (wide ? "100%" : undefined)};
  `,
  InfoContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  InputAndErrorContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ContainerRow: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  `,
  ListContainer: styled.div`
    border: 1px solid #d0d5dd;
    padding: 3px;
    max-height: 150px;
    overflow-y: auto;
  `,
  Row: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    padding-top: 3px;
    padding-bottom: 3px;
  `,
  Button: styled.div`
    font-family: "Poppins500";
    font-size: 14px;
    color: #37474f;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    text-align: center;
  `,
  LabelButton: styled.label`
    font-family: "Poppins500";
    font-size: 14px;
    color: #37474f;
    cursor: pointer;
  `,
  Label: styled.label`
    font-size: ${({ theme }) => theme.fontSizes.field};
    margin-bottom: 3px;
  `,
  CheckboxAndIconContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  `,
  Input: styled.input`
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 30px;
    width: ${(props) => (props.narrow ? "auto" : "100%")};
    background: ${({ theme }) => theme.colors.neutral.white};
    font-size: ${({ theme }) => theme.fontSizes.field};
    padding: 0 0.5rem;
    color: #37474f;
    outline: none;
    &:disabled {
      opacity: 0.7;
    }
  `,
  TextArea: styled.textarea`
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 60px;
    width: 100%;
    background: transparent;
    font-family: "Roboto400";
    font-size: 14px;
    padding: 0 0.5rem;
    color: #37474f;
    outline: none;
  `,
  ComboSelect: styled.div`
    width: 100%;
  `,
  Select: styled.select`
    border: none;
    outline: none;
    appearance: none;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
    cursor: pointer;
    background-image: url(${arrow_down});
    background-repeat: no-repeat;
    background-position: right 10px center;
  `,
  Optgroup: styled.optgroup``,
  Option: styled.option``,
  SubmitInput: styled.input`
    background: #e6866e;
    border: none;
    border-radius: 100px;
    width: 132px;
    height: 40px;
    text-decoration: none;
    color: #ffffff;
    font-family: Poppins600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    ${(props) =>
      props.disabled &&
      `
        background: #ccc; /* Change to the desired background color for disabled state */
        cursor: not-allowed;
      `}
  `,
  SubmitError: styled.span`
    font-family: "Roboto400";
    font-size: 14px;
    color: #ff3333;
    padding-left: 5px;
    padding-top: 5px;
  `,
  SplitContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  Text: styled.span`
    font-family: "Roboto400";
    font-size: 14px;
    color: #37474f;
  `,
  SelectVenueContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,

  VenueCheckboxContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,
  AddVenueIcon: styled(FaPlusCircle)`
    color: #e6866e;
    width: 25px;
    height: 25px;
    cursor: pointer;
  `,
  RemoveVenueIcon: styled(FaRegTimesCircle)`
    color: #e6866e;
    width: 25px;
    height: 25px;
    cursor: pointer;
  `,
  AddedVenueContainer: styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    height: 25px;
    width: 100%;
    background: rgb(235, 235, 228);
    font-family: "Roboto400";
    font-size: 14px;
    padding: 0 0.5rem;
    color: #37474f;
  `,
  AddNewVenueContainer: styled.div`
    display: flex;
  `,
  ImageCard: styled.img`
    width: 100%;
    resize-mode: contain;
  `,
  AddNewVenueText: styled.span`
    font-family: "Roboto400";
    font-size: 14px;
    color: #e6866e;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `,
  LoadingMessage: styled.p`
    font-family: "PoppinsRegular";
    font-size: 1rem;
    color: darkgray;

    animation: bounce 2s ease-in-out infinite;

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
        text-shadow: 0px 10px rgba(0, 0, 0, 0.1);
      }
      100% {
        transform: translateY(0);
      }
    }
  `,
  InfoMessage: styled.p`
    font-family: "PoppinsRegular";
    font-size: 0.85rem;
    color: #ec5f5d;
  `,
  Slider: styled(ReactSlider)`
    width: 100%;
    height: 25px;
  `,
  SliderThumb: styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
  `,
  SliderTrack: styled.div`
    top: 0;
    bottom: 0;
    background: ${(props) => (props.index === 2 ? "#f00" : props.index === 1 ? "#0f0" : "#ddd")};
    border-radius: 999px;
  `,
};

export default styles;
