import React, { useState, useEffect } from "react";
// Styles
import Styled from "./Network.styled";
// Components
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import Filters from "../Filters/Filters";
import SearchBar from "components/SearchBar/SearchBar";
import NetworkTable from "./NetworkTable/NetworkTable";
import SpinnerComponent from "components/Loader";
// Hooks
import { useDebounce } from "hooks/useDebounce";

const Network = ({ venueDetails }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    venues: [],
    areas: [],
    cameras: [],
    inferences: [],
    useCases: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const debouncedSelectedFilters = useDebounce(selectedFilters, 300);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    setLoading(true);
    setSelectedFilters({
      venues: [],
      areas: [],
      cameras: [],
      inferences: [],
      useCases: [],
    });
    setSearchTerm("");
  }, [venueDetails]);

  useEffect(() => {
    if (debouncedSelectedFilters && debouncedSearchTerm !== null) {
      setLoading(false);
    }
  }, [debouncedSelectedFilters, debouncedSearchTerm]);

  return (
    <Styled.NetworkContainer>
      <Styled.Header>
        <Styled.Title>Network</Styled.Title>
        {/* <PrimaryBtn
          label="Add Camera"
          height="44px"
          padding="10px 20px"
          icon={"plus"}
          iconSize={"20px"}
        /> */}
      </Styled.Header>

      <Styled.TableHeader>
        <Filters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          venueDetails={venueDetails}
        />
        <SearchBar
          placeholder="Search by camera name"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Styled.TableHeader>

      {loading ? (
        <SpinnerComponent />
      ) : (
        <NetworkTable
          venueDetails={venueDetails}
          selectedFilters={debouncedSelectedFilters}
          searchTerm={debouncedSearchTerm}
        />
      )}
    </Styled.NetworkContainer>
  );
};

export default Network;
