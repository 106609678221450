import React, { useContext, useState } from "react";
import ModelRow from "./ModelRow";
import { DashboardContext } from "../../../../context/DashboardContext";
//Styles
import Styles from "./styles";

const ModelTable = ({ modelVersions, model, updateModel, addVersion }) => {
  return modelVersions.map((modelVersion) => {
    return (
      <Styles.TableRow key={modelVersion.id}>
        <ModelRow
          modelVersion={modelVersion}
          model={model}
          updateModel={updateModel}
          addVersion={addVersion}
        />
      </Styles.TableRow>
    );
  });
};

const ModelTables = ({ dataRows, updateModel, addVersion }) => {
  const { companiesDict } = useContext(DashboardContext);
  const renderTables = (row) => {
    const { ModelVersions, ...model } = row;

    const mainTitle = `${model.modelName} ${model.modelStatus ? "(enabled)" : ""}`;
    let subTitle = `${model.modelBaseModel ? "Base " : ""}Model: ${model.modelType}`;

    if (model.companyId) {
      // add company name to subtitle
      subTitle = `${subTitle} | Company: ${companiesDict[model.companyId]}`;
    }
    return (
      <ModelTable
        key={row.id}
        modelVersions={ModelVersions}
        model={model}
        updateModel={updateModel}
        addVersion={addVersion}
      />
    );
  };
  return (
    <Styles.TableContainer>
      <Styles.Table>
        <Styles.TableHead>
          <Styles.TableRow>
            <Styles.TableHeader>Name</Styles.TableHeader>
            <Styles.TableHeader>Description</Styles.TableHeader>
            <Styles.TableHeader>Date Created</Styles.TableHeader>
            <Styles.TableHeader>Note</Styles.TableHeader>
            <Styles.TableHeader>Status</Styles.TableHeader>
            <Styles.TableHeader>Actions</Styles.TableHeader>
          </Styles.TableRow>
        </Styles.TableHead>
        <Styles.TableBody>{dataRows.map((row) => renderTables(row))}</Styles.TableBody>
      </Styles.Table>
    </Styles.TableContainer>
  );
};

export default ModelTables;
