import React, { useState } from "react";
// Styles
import Styled from "./Sidebar.styled";
// Components
import SidebarHeader from "./SidebarHeader/SidebarHeader";
import SidebarLink from "./SidebarLink/SidebarLink";
import ExpandableLink from "./ExpandableLink/ExpandableLink";
import SpinnerComponent from "../Loader";

const Sidebar = ({ companiesList, companiesLoading }) => {
  const [expandedCompany, setExpandedCompany] = useState(null);

  const handleCompanyClick = (companyId) => {
    setExpandedCompany(companyId);
  };

  const handleDashboardClick = () => {
    setExpandedCompany(null);
  };

  return (
    <Styled.SidebarContainer>
      <div>
        <SidebarHeader />
        <SidebarLink
          label="Dashboard"
          icon="dashboard"
          path="/manage-companies"
          onClick={handleDashboardClick}
        />

        {companiesLoading ? (
          <Styled.SpinnerContainer>
            <SpinnerComponent />
          </Styled.SpinnerContainer>
        ) : (
          <>
            {companiesList.map((company) => (
              <ExpandableLink
                key={company.id}
                company={company}
                isExpanded={expandedCompany === company.id}
                onClick={() => handleCompanyClick(company.id)}
              />
            ))}
          </>
        )}
      </div>

      <div>
        {/* 
        TODO:
        1. The onClick handlers and links will be revisited once the final designs are ready
        2. Adding placeholders
        */}

        <SidebarLink
          label="Settings"
          icon="settings"
          path="/settings/company-settings"
          onClick={handleDashboardClick}
        />
        {/* <SidebarLink label="Talk to us" icon="chat" path="/" onClick={handleDashboardClick} /> */}
        <SidebarLink label="Logout" icon="logout" path="/logout" onClick={handleDashboardClick} />
      </div>
    </Styled.SidebarContainer>
  );
};

export default Sidebar;
