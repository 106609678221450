import styled from "styled-components";

const styles = {
  FiltersContainer: styled.div`
    display: flex;
    align-items: flex-end;
    gap: 4px;
    margin-bottom: 16px;
  `,
  FilterButton: styled.div`
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 100px;
    cursor: pointer;
    position: relative;
  `,
};

export default styles;
