import React, { useEffect, useContext } from "react";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Styles
import Styles from "./styles";
//Components
import { FormGroup } from "../../Shared/FormGroup";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import Icon from "components/Shared/Icon/Icon";
//Context
import { AuthContext } from "../../../context/authContext";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const updateNameSchema = yup.object().shape({
  userFirstName: yup.string().required("First Name is a required field."),
  userLastName: yup.string().required("Last Name is a required field."),
});

const UpdateProfile = (props) => {
  const {
    updateProfile,
    updateProfileData,
    updateProfileSuccess,
    setUpdateProfileSuccess,
    updateProfileError,
    setUpdateProfileError,
    /* loggedUserAttributes */
  } = props;

  const { loggedUserAttributes } = useContext(AuthContext);

  //React-Hook-Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateNameSchema),
    },
    { defaultValues: loggedUserAttributes },
  );

  //Effects
  useEffect(() => {
    if (updateProfileData) {
      reset();
    }
  }, [updateProfileData, reset]);

  useEffect(() => {
    if (errors.firstName?.message || errors.lastName?.message) {
      setUpdateProfileSuccess(false);
      setUpdateProfileError("");
    }
  }, [errors, setUpdateProfileSuccess, setUpdateProfileError]);

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(loggedUserAttributes);
  }, [reset, loggedUserAttributes]);

  //Handlers
  const handleUpdateProfile = (updateNameFormData) => {
    updateProfile({
      variables: {
        /* id: loggedUserId, */
        userFirstName: updateNameFormData.userFirstName.trim(),
        userLastName: updateNameFormData.userLastName.trim(),
        userPhone: updateNameFormData.userPhone.trim(),
      },
    });
  };

  usePreventEnterAction(handleUpdateProfile);

  return (
    <Styles.FormAndTitleContainer>
      <Styles.Title>Update Your Profile</Styles.Title>
      <Styles.Form onSubmit={handleSubmit(handleUpdateProfile)}>
        <Styles.FormContainer>
          <FormGroup
            labelText={"First Name"}
            errorMessage={errors.firstName?.message}
            profileForm
            required
          >
            <Styles.InputContainer>
              <Styles.Input type="text" name="userFirstName" {...register("userFirstName")} />
              <Styles.IconWrapper>
                <Icon name="info" className="sidebar-icon" isActive={false} />
              </Styles.IconWrapper>
            </Styles.InputContainer>
          </FormGroup>
          <FormGroup
            labelText={"Last Name"}
            errorMessage={errors.lastName?.message}
            profileForm
            required
          >
            <Styles.InputContainer>
              <Styles.Input type="text" name="userLastName" {...register("userLastName")} />
              <Styles.IconWrapper>
                <Icon name="info" className="sidebar-icon" isActive={false} />
              </Styles.IconWrapper>
            </Styles.InputContainer>
          </FormGroup>
          <FormGroup
            labelText={"Phone Number"}
            errorMessage={errors.userPhone?.message}
            profileForm
          >
            <Styles.InputContainer>
              <Styles.Input type="text" name="userPhone" {...register("userPhone")} />
              <Styles.IconWrapper>
                <Icon name="info" className="sidebar-icon" isActive={false} />
              </Styles.IconWrapper>
            </Styles.InputContainer>
          </FormGroup>
        </Styles.FormContainer>
        {updateProfileSuccess ? (
          <Styles.SubmitSuccess>Profile updated successfully!</Styles.SubmitSuccess>
        ) : updateProfileError ? (
          <Styles.SubmitError>{updateProfileError}</Styles.SubmitError>
        ) : null}
        <PrimaryBtn type="submit" label="Update" padding="10px 20px" />
      </Styles.Form>
    </Styles.FormAndTitleContainer>
  );
};

export default UpdateProfile;
