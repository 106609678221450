import React from "react";
// Styles
import Styled from "./SidebarHeader.styled";
// Logo
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";

const SidebarHeader = () => {
  return (
    <Styled.HeaderContainer>
      <Logo />
    </Styled.HeaderContainer>
  );
};

export default SidebarHeader;
