import React, { useState, useEffect, useContext, useCallback } from "react";
//Tools
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Components
import Styles from "../styles";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import ObjectSelector from "../../../components/ObjectSelector";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
//Data
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import { DashboardContext } from "../../../context/DashboardContext";

import { GET_VENUE, LIST_DOCKERS, LIST_MODELS } from "../../../graphql/graph";
import { GraphQLHelperContext } from "../../../context/GraphQLHelperContext";
import { jsonParse } from "../../../configs/helpers";

const generateAddCameraSchema = (addNewArea) => {
  const commonSchema = {
    machineName: yup.string().required("Camera name is a required field."),
    machineRTSPUrl: yup.string().required("Camera Url is a required field."),
    machineCustomId: yup.string().typeError("Custom Id must be a number.").notRequired(),
    machineModel: yup.string().required("model type is a required field."),
    modelVersionId: yup.string().required("Model file is a required field for this model type"),
    dockerId: yup.string().required("Please provide a Docker Id"),
    areaId: yup.string().required("Please select an area or add a new one"),
  };

  const newAreaSchema = {
    newArea: yup.string().required("Area name is a required field."),
  };

  return yup.object().shape({ ...commonSchema, ...(addNewArea ? newAreaSchema : {}) });
};

const AddUpdateCamera = ({ handleModalForwardClick, showModal, setShowModal, cameraObj }) => {
  const isUpdate = cameraObj?.id ? true : false;

  const { companyId } = useParams();
  const { padmeModels, objectDetectionItems } = useContext(DashboardContext);
  const { createMachine, updateMachine } = useContext(GraphQLHelperContext);

  const { data: modelData } = useQuery(LIST_MODELS, {
    context: "graph",
    variables: {
      companyId,
    },
  });

  const { data: dockersData } = useQuery(LIST_DOCKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const [mlModels, setMlModels] = useState([]);
  const [areaOnChange, setAreaOnChange] = useState("");
  const [addNewArea, setAddNewArea] = useState(false);

  const [selectedModel, setSelectedModel] = useState(() => {
    if (!isUpdate) {
      // not update return basic cpu model
      return { ...padmeModels[0].inferenceCPU, type: padmeModels[0].type };
    }

    const model = padmeModels.find(({ value }) => value === cameraObj?.machineModel);
    return cameraObj?.machineType === "cuda"
      ? { ...model.inferenceGPU, type: model.type }
      : { ...model.inferenceCPU, type: model.type };
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    unregister,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(generateAddCameraSchema(addNewArea)),
    },
    {
      defaultValues: {
        machineName: "",
        machineRTSPUrl: "",
        machineCustomId: "",
        machineType: "cpu",
        machineOverlayLabel: "",
        machineInferenceInterval: padmeModels[0].inferenceCPU.defaultValue,
        machineSelectedObjects: [],
        machineIntervalType: "",
        modelVersionId: "",
        dockerId: "",
        areaId: null,
        areaName: null,
        machineModel: padmeModels.length && padmeModels[0].value,
      },
    },
  );

  const watchAll = watch();

  // manual default value setting in case of update
  useEffect(() => {
    if (cameraObj.id) {
      setValue("machineName", cameraObj.machineName);
      setValue("machineRTSPUrl", cameraObj.machineRTSPUrl);
      setValue("machineCustomId", cameraObj.machineCustomId);
      setValue("machineType", cameraObj.machineType);
      setValue("machineOverlayLabel", cameraObj.machineOverlayLabel);
      setValue("machineSelectedObjects", jsonParse(cameraObj.machineSelectedObjects));
      setValue("machineInferenceInterval", cameraObj.machineInferenceInterval);
      setValue("machineIntervalType", cameraObj.machineIntervalType);
      setValue("modelVersionId", cameraObj.modelVersionId);
      setValue("dockerId", cameraObj.dockerId);
      setValue("areaId", cameraObj.areaId);
      setValue("machineModel", cameraObj.machineModel);
    }
  }, [cameraObj]);

  // update machine type sliders
  const handleSelectedModelUpdate = useCallback(() => {
    if (watchAll.machineType && watchAll.machineModel) {
      const model = padmeModels.find(({ value }) => value === watchAll.machineModel);
      const selectThisModel =
        watchAll.machineType === "cuda"
          ? { ...model.inferenceGPU, type: model.type }
          : { ...model.inferenceCPU, type: model.type };
      // only update if different
      if (selectThisModel.key !== selectedModel.key) {
        setSelectedModel(selectThisModel);
        setValue("machineInferenceInterval", selectThisModel.defaultValue);
      }
    }
  }, [watchAll, setSelectedModel]);

  const objectSuggestions = objectDetectionItems
    .map((item) => ({
      value: item.objectDetectionIndex,
      label: item.objectDetectionLabel,
    }))
    .toSorted((a, b) => a.label.localeCompare(b.label));

  // build ml model list
  useEffect(() => {
    if (modelData?.listModel?.data && watchAll) {
      let newModelsList = [];

      const selectedModel = padmeModels.find((model) => model.value === watchAll.machineModel);

      if (selectedModel?.type === "density_estimation") {
        newModelsList = modelData.listModel.data.filter(
          (row) => row.modelType === selectedModel.type,
        );
      } else if (watchAll.machineSelectedObjects) {
        newModelsList = modelData?.listModel?.data.filter((row) => {
          const isSelectedModel = row.modelType === selectedModel?.type;

          const rowModelObjects = jsonParse(row.modelDetectionObjects).map(
            (object) => object.label,
          );
          const selectedObjects = watchAll?.machineSelectedObjects?.map((object) => object.label);
          const hasSelectedObjectsInRow = selectedObjects.every((sel) =>
            rowModelObjects.includes(sel),
          );

          return isSelectedModel && hasSelectedObjectsInRow;
        });
      }

      setMlModels(newModelsList);

      if (newModelsList.length) {
        // reapply model version id to trigger select box to update
        setTimeout(() => {
          setValue("modelVersionId", watchAll.modelVersionId);
        }, 300);
      }
    }
  }, [modelData, watchAll.machineModel, setMlModels, watchAll.machineSelectedObjects]);

  useEffect(() => {
    handleSelectedModelUpdate();
  }, [watchAll.machineModel, watchAll.machineType]);

  useEffect(() => {
    if (areaOnChange === "addArea") {
      setAddNewArea(true);
    } else if (areaOnChange !== "addArea" && addNewArea) {
      unregister("newArea");
      setAddNewArea(false);
    }
  }, [areaOnChange, addNewArea, unregister]);

  const handleClickCancelBtn = () => {
    reset();
    setAreaOnChange("");
    setShowModal();
  };

  const submitForm = (cameraFormData) => {
    //Handle submit Form
    if (!cameraObj.id) {
      createMachine({
        variables: {
          venueId: cameraObj.venueId,
          machineName: cameraFormData.machineName,
          machineRTSPUrl: cameraFormData.machineRTSPUrl,
          machineCustomId: cameraFormData.machineCustomId,
          machineType: cameraFormData.machineType,
          machineOverlayLabel: cameraFormData.machineOverlayLabel,
          machineInferenceInterval: cameraFormData.machineInferenceInterval,
          machineSelectedObjects: JSON.stringify(cameraFormData.machineSelectedObjects),
          machineIntervalType: selectedModel.valueType,
          modelVersionId: cameraFormData.modelVersionId,
          dockerId: cameraFormData.dockerId,
          areaId: cameraFormData.areaId === "addArea" ? null : cameraFormData.areaId,
          areaName: cameraFormData.areaId === "addArea" ? cameraFormData.newArea : null,
          machineModel: cameraFormData.machineModel,
        },
        refetchQueries: [{ query: GET_VENUE, variables: { id: cameraObj.venueId } }],
      });
    } else {
      updateMachine({
        variables: {
          id: cameraObj.id,
          machineName: cameraFormData.machineName,
          machineRTSPUrl: cameraFormData.machineRTSPUrl,
          machineCustomId: cameraFormData.machineCustomId,
          machineType: cameraFormData.machineType,
          machineOverlayLabel: cameraFormData.machineOverlayLabel,
          machineInferenceInterval: cameraFormData.machineInferenceInterval,
          machineSelectedObjects: JSON.stringify(cameraFormData.machineSelectedObjects),
          machineIntervalType: selectedModel.valueType,
          dockerId: cameraFormData.dockerId,
          modelVersionId: cameraFormData.modelVersionId,
          areaId: cameraFormData.areaId === "addArea" ? null : cameraFormData.areaId,
          areaName: cameraFormData.areaId === "addArea" ? cameraFormData.newArea : null,
          machineModel: cameraFormData.machineModel,
        },
      });
    }
    reset();
    setAreaOnChange("");
    setShowModal();
  };

  usePreventEnterAction(submitForm);

  const handleAreaOnChange = (e) => {
    setAreaOnChange(e.target.value);
    clearErrors("areaId");
    clearErrors("newArea");
  };

  const handleObjectChange = (objects) => {
    console.log("handleObjectChange", objects);
    setValue("machineSelectedObjects", objects);
  };

  const renderModelOptions = () => {
    return mlModels.map((model) => {
      return model.ModelVersions.map((modelVersion) => {
        return (
          <option value={modelVersion.id} key={modelVersion.id}>
            {model.modelName}, Ver.{modelVersion.modelVersionNo}
          </option>
        );
      });
    });
  };

  const Track = (props, state) => <Styles.SliderTrack {...props} index={state.index} />;
  const Thumb = (props, state) => {
    const title =
      selectedModel.valueType === "seconds" ? state.valueNow : `${state.valueNow * 100}%`;
    return <Styles.SliderThumb {...props}>{title}</Styles.SliderThumb>;
  };

  const title = !isUpdate ? "Add Camera" : "Update Camera";
  const venueAreas = cameraObj?.venueAreas || [];
  const dockersList = dockersData?.listDocker?.data || [];

  // console.log("cameraObj", cameraObj);
  // console.log("watchAll", watchAll);
  // console.log("selectedModel", selectedModel);
  // console.log(cameraObj, mlModels);
  // console.log(cameraObj, mlModels);

  return (
    <ModalWithChildren
      width={600}
      showModal={showModal}
      setShowModal={setShowModal}
      title={title}
      handleModalForwardClick={handleModalForwardClick}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Name</Styles.Label>
          <Styles.Input
            type="text"
            name="machineName"
            placeholder="Camera Name"
            {...register("machineName")}
          />
          <Styles.SubmitError>{errors.machineName?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>RTSP URL</Styles.Label>
          <Styles.Input
            type="text"
            name="machineRTSPUrl"
            placeholder="RTSP URL"
            {...register("machineRTSPUrl")}
          />
          <Styles.SubmitError>{errors.machineRTSPUrl?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Camera Id (optional)</Styles.Label>
          <Styles.Input type="text" name="machineCustomId" {...register("machineCustomId")} />
          <Styles.SubmitError>{errors.machineCustomId?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Use Case</Styles.Label>
          <Styles.Select name="machineModel" id="machineModel" {...register("machineModel")}>
            {padmeModels.map((defaultType) => (
              <option value={defaultType.value} key={defaultType.value}>
                {defaultType.label}
              </option>
            ))}
          </Styles.Select>
        </Styles.InputAndErrorContainer>
        {selectedModel.type === "object_detection" && (
          <Styles.InputAndErrorContainer>
            <Styles.Label>Objects to count</Styles.Label>
            <Styles.ComboSelect>
              <ObjectSelector
                values={watchAll.machineSelectedObjects || []}
                onChange={handleObjectChange}
                suggestions={objectSuggestions}
              />
            </Styles.ComboSelect>
          </Styles.InputAndErrorContainer>
        )}
        <Styles.InputAndErrorContainer>
          <Styles.Label>ML Model</Styles.Label>
          <Styles.Select id="model" {...register("modelVersionId")} defaultValue="">
            {
              <option value="" disabled>
                Select Model
              </option>
            }
            {renderModelOptions()}
          </Styles.Select>
          <Styles.SubmitError>{errors.modelVersionId?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Machine Type</Styles.Label>
          <Styles.Select name="machineType" id="machineType" {...register("machineType")}>
            <option value="openvino" key="openvino">
              CPU (Intel OpenVINO)
            </option>
            <option value="cuda" key="cuda">
              GPU (NVIDIA CUDA)
            </option>
            <option value="cpu" key="cpu">
              CPU (Most Compatible)
            </option>
          </Styles.Select>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>{selectedModel.title}</Styles.Label>
          <Styles.Slider
            defaultValue={selectedModel.defaultValue}
            value={watchAll.machineInferenceInterval}
            step={selectedModel.step}
            max={selectedModel.max}
            min={selectedModel.min}
            onChange={(value, index) => {
              setValue("machineInferenceInterval", value);
            }}
            renderTrack={Track}
            renderThumb={Thumb}
          />
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Docker ID</Styles.Label>
          <Styles.Select name="dockerId" id="dockerId" {...register("dockerId")} defaultValue="">
            <option value="" disabled>
              Select Docker
            </option>
            {dockersList.map((docker) => {
              return (
                <option value={docker.id} key={docker.id}>
                  {docker.dockerName}
                </option>
              );
            })}
          </Styles.Select>
          <Styles.SubmitError>{errors.dockerId?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Select/Add Area</Styles.Label>
          <Styles.Select
            name="areaId"
            id="areaId"
            {...register("areaId")}
            onChange={(e) => {
              handleAreaOnChange(e);
            }}
            defaultValue=""
          >
            <option value="" disabled>
              Select Area
            </option>
            <option value="addArea">Add new area +</option>
            {venueAreas.map((area) => {
              return (
                <option value={area.id} key={area.id}>
                  {area.areaName}
                </option>
              );
            })}
          </Styles.Select>
          <Styles.SubmitError>{errors.areaId?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Overlay Label</Styles.Label>
          <Styles.TextArea
            type="text"
            name="machineOverlayLabel"
            {...register("machineOverlayLabel")}
          />
        </Styles.InputAndErrorContainer>
        {addNewArea ? (
          <>
            <Styles.Input
              type="text"
              name="newArea"
              placeholder="New Area name"
              {...register("newArea")}
            />
            <Styles.SubmitError>{errors.newArea?.message}</Styles.SubmitError>
          </>
        ) : null}
        <Styles.ButtonsContainer>
          <SecondaryBtn label="Cancel" handleClick={handleClickCancelBtn} padding="10px 20px" />
          <PrimaryBtn
            type="submit"
            id="submit"
            label={isUpdate ? "Update" : "Add"}
            padding="10px 20px"
          />
        </Styles.ButtonsContainer>
      </Styles.Form>
    </ModalWithChildren>
  );
};

export default AddUpdateCamera;
