import styled from "styled-components/macro";

const styles = {
  DockerCard: styled.div`
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 38px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  Title: styled.h2`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  `,
  IconContainer: styled.div`
    padding-right: 6px;
  `,
  StatusCircle: styled.span`
    height: 14px;
    width: 14px;
    background-color: ${(props) => (props.active ? "#00BF00" : "#FF334E")};
    border-radius: 50%;
    display: inline-block;
    /* animation: blinker 1.5s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0.2;
      }
    } */
  `,
  StatusContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
};

export default styles;
