import styled from "styled-components";

const styles = {
  BrokersContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  Title: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  DockerContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  `,
};

export default styles;
