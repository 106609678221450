import styled, { css } from "styled-components/macro";

const MenuCard = css`
  display: flex;
  width: 147px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.13);
  position: absolute;
  background: ${({ theme }) => theme.colors.neutral.white};
  z-index: 1;
`;

const styles = {
  CompanySettingsContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    /* padding: 0.5rem 0; */
    :first-child {
      padding-left: 1rem;
    }
    @media screen and (max-width: 480px) {
      :first-child {
        padding-left: 0;
      }
      flex-direction: column;
      gap: 1rem;
    }
  `,
  HeaderText: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,
  CompaniesContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  `,
  CompanyContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 12px 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  HeaderContainer: styled.div`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
  `,
  CompanyNameContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  LogoContainer: styled.div`
    border-radius: 95px;
    width: 38px;
    height: 38px;
  `,
  CompanyName: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.subHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,
  Divider: styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    width: 95%;
    align-self: center;
  `,
  VenuesContainer: styled.div`
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 0px 0px 12px 12px;
  `,
  VenuesHeaderContainer: styled.div`
    display: flex;
    height: 12px;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  `,
  VenuesHeader: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.medium};
    opacity: 0.7;
  `,
  VenueRowContainer: styled.div`
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  `,
  VenuesRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  VenueName: styled.h4`
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    opacity: 0.7;
  `,
  MenuWrapper: styled.div`
    display: flex;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
  `,
  VenueMenuCard: styled.div`
    ${MenuCard}
    right: 100%;
    margin-right: 10px;
  `,
  CompanyMenuCard: styled.div`
    ${MenuCard}
    margin-left: 16px;
  `,
  MenuItemsContainer: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `,
  MenuItem: styled.div`
    gap: 6px;
    align-items: center;
    display: flex;
    width: 115px;
    cursor: pointer;
  `,
  LoaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

export default styles;
