export const colors = {
  primary: {
    blue900: "#0327E1",
    blue800: "#394DFC",
    blue700: "#2350FA",
    blue600: "#7B88FF",
    blue500: "#AFB7FF",
    blue300: "#D9DDFF",
    blue200: "#F0F2F7",
  },
  secondary: {
    cyan700: "#0BAEBE",
    cyan500: "#0CC4D7",
    cyan300: "#9ADBE2",
    cyan200: "#E9F9FB",
  },
  success: {
    green700: "#388E3C",
    green500: "#49B936",
    green300: "#66BB6A",
    green200: "#A5D6A7",
  },
  warning: {
    orange700: "#E48900",
    orange500: "#FFA826",
    orange300: "#FFD596",
    orange200: "#FFF8EC",
  },
  error: {
    red700: "#BB1C1C",
    red500: "#E01507",
    red300: "#FF5D5D",
    red200: "#FFE1E1",
  },
  neutral: {
    black900: "#18181D",
    grey800: "#2C313D",
    grey700: "#4D4D4D",
    grey600: "#717171",
    grey500: "#979DAD",
    grey400: "#B4B4C4",
    grey300: "#E4E6EB",
    grey200: "#F0F2F7",
    grey100: "#F7F8FA",
    white: "#FFFFFF",
  },
  shadows: {
    dark: "#0000001A",
    light: "#0000000D",
  },
};
