import React, { useRef, useState, useContext, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client/react";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Styles from "../styles";

import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../../components/Loader";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import ObjectSelector from "../../../components/ObjectSelector";
import FileUpload from "../../../components/FileUpload";
import ImageGallery from "../../../components/ImageGallery";

import {
  CREATE_ANNOTATION_REQUEST,
  UPDATE_FILE_ASSET,
  GET_ANNOTATION_REQUEST,
  LIST_FILES,
  LIST_COLLECTION_REQUESTS,
} from "../../../graphql/graph";
import { DashboardContext } from "../../../context/DashboardContext";
import { useToastModal } from "../../../context/ToastModalContext";
import { arStatus, crStatus } from "../../../configs/configEnviroment";
import { timeStampToDateString } from "../../../configs/helpers";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";

const StartAnnotationRequest = ({
  companyId,
  venuesList,
  showModal,
  setShowModal,
  requestToUpdate,
  onCloseModal,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const { addToast, addModal } = useToastModal();
  const { objectDetectionItems } = useContext(DashboardContext);
  const uploaderRef = useRef();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      collectionRequestId: null,
      arProvider: "",
      arStatus: 0,
      arManualReview: 1,
      arNotes: "",
    },
  });

  const [getAnnotationRequest, { data: annotationRequestData }] = useLazyQuery(
    GET_ANNOTATION_REQUEST,
    {
      context: { clientName: "graph" },
    },
  );

  const [getFiles, { data: getFilesData }] = useLazyQuery(LIST_FILES, {
    context: { clientName: "graph" },
  });

  const [updateFileAssetMutation] = useMutation(UPDATE_FILE_ASSET, {
    context: { clientName: "graph" },
  });

  const [
    addAnnotationMutation,
    { error: addAnnotationError, loading: addLoading, data: addAnnotationData },
  ] = useMutation(CREATE_ANNOTATION_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [{ query: LIST_COLLECTION_REQUESTS, variables: { companyId } }],
  });

  const requestStatus = requestToUpdate?.AnnotationRequest?.arStatus || 0;

  const collectionFiles = getFilesData?.listFiles?.data || [];

  const loading = addLoading;

  useEffect(() => {
    if (requestToUpdate) {
      getFiles({
        variables: {
          collectionRequestId: requestToUpdate.id,
          annotationRequestId: requestToUpdate?.AnnotationRequest?.id,
        },
      });

      let currentMachine;

      venuesList.forEach((venue) =>
        venue.Areas.forEach((area) =>
          area.Machines.forEach((machine) => {
            if (machine.id === requestToUpdate.machineId) {
              currentMachine = {
                ...machine,
                areaName: area.areaName,
                venueName: venue.venueName,
                crDetectionItems: requestToUpdate?.crDetectionItems
                  ? JSON.parse(requestToUpdate.crDetectionItems)
                  : [],
              };
            }
          }),
        ),
      );

      setSelectedMachine(currentMachine);
    }
    if (requestToUpdate && requestToUpdate?.AnnotationRequest) {
      getAnnotationRequest({ variables: { id: requestToUpdate?.AnnotationRequest.id } });
    }
  }, [requestToUpdate, setSelectedMachine]);

  useEffect(() => {
    if (getFilesData && getFilesData.listFiles) {
      setSelectedFiles(getFilesData.listFiles.data.filter((file) => file.annotationRequestId));
    }
  }, [getFilesData]);

  useEffect(() => {
    if (addAnnotationData) {
      addToast("Annotation created successfully!", "success");
      handleCloseModal();
    }
    if (addAnnotationError) {
      addToast(
        `Something went wrong! Annotation did not create. ${addAnnotationError.message}`,
        "error",
      );
    }
  }, [addAnnotationData, addAnnotationError]);

  const handleObjectChange = (objects) => {
    setValue("crDetectionItems", objects || []);
  };

  const handleCloseModal = () => {
    reset();
    setShowModal((prev) => !prev);
    onCloseModal();
  };

  const handleClickDeleteBtn = async () => {
    addModal("Rejct Collection Request?", false, () => {
      // deleteCollectionMutation({ variables: { id: requestToUpdate.id } });
    });
  };

  const submitForm = async (form) => {
    if (loading) return;
    addAnnotationMutation({
      variables: {
        collectionRequestId: requestToUpdate.id,
        arProvider: form.arProvider,
        arStatus: form.arStatus,
        arManualReview: form.arManualReview,
        arNotes: form.arNotes,
      },
    });
  };

  const handleExistingFileDelete = (fileId) => {
    addModal("Delete file?", false, () => {
      addModal("Delete file?", false, async () => {
        await updateFileAssetMutation({
          variables: {
            id: fileId,
            fileAssetVoid: 1,
          },
          refetchQueries: [
            {
              query: LIST_FILES,
              variables: {
                collectionRequestId: requestToUpdate.id,
                annotationRequestId: requestToUpdate?.AnnotationRequest?.id,
              },
            },
          ],
        });

        setSelectedFiles(selectedFiles.filter((existingFile) => existingFile.id !== fileId));
      });
    });
  };

  const handleCollectionFileDelete = (fileId) => {
    addModal("Delete file?", false, async () => {
      await updateFileAssetMutation({
        variables: {
          id: fileId,
          fileAssetVoid: 1,
        },
        refetchQueries: [
          {
            query: LIST_FILES,
            variables: {
              collectionRequestId: requestToUpdate.id,
              annotationRequestId: requestToUpdate?.AnnotationRequest?.id,
            },
          },
        ],
      });
    });
  };

  const handleFileUpload = async () => {
    // console.log(uploaderRef.current);
    if (uploaderRef && uploaderRef.current) {
      await uploaderRef.current.handleAnnotationFileUpload(requestToUpdate?.AnnotationRequest.id);
      handleCloseModal();
    }
  };

  usePreventEnterAction(submitForm);

  const objectSuggestions = objectDetectionItems.map((item) => ({
    value: item.objectDetectionIndex,
    label: item.objectDetectionLabel,
  }));

  const modalTitle = "Annotation Request";
  const collectionStatusText = `Collection Status: ${
    crStatus[requestToUpdate?.crCollectionStatus]
  } ${timeStampToDateString(requestToUpdate?.crCollectionCompleteDate)}`;

  const annotationStatusText = `Annotation Status: ${
    arStatus[requestStatus]
  } ${timeStampToDateString(requestToUpdate?.AnnotationRequest?.arCollectionCompleteDate)}`;

  const annotationStarted = requestToUpdate?.AnnotationRequest;

  const machineName = !selectedMachine
    ? ""
    : `${selectedMachine?.venueName}, ${selectedMachine?.areaName}, ${selectedMachine?.machineName}`;

  // console.log("requestToUpdate", requestToUpdate);

  return (
    <ModalWithChildren
      width={800}
      showModal={showModal}
      setShowModal={handleCloseModal}
      title={modalTitle}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Camera: {machineName}</Styles.Label>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Object Detection List</Styles.Label>
          <Styles.ComboSelect>
            <ObjectSelector
              values={selectedFiles.crDetectionItems}
              onChange={handleObjectChange}
              suggestions={objectSuggestions}
              disabled
            />
          </Styles.ComboSelect>
        </Styles.InputAndErrorContainer>
        <ImageGallery
          deleteFile={handleCollectionFileDelete}
          allowDelete
          images={collectionFiles.filter((file) => file.collectionRequestId)}
        />
        {annotationStarted && (
          <FileUpload
            title="Upload Annotation Data"
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            // selectedFilesError={selectedFilesError}
            deleteFile={handleExistingFileDelete}
            ref={uploaderRef}
          />
        )}
        <Styles.InputAndErrorContainer>
          <Styles.Label>{collectionStatusText}</Styles.Label>
          <Styles.Label>{annotationStatusText}</Styles.Label>
        </Styles.InputAndErrorContainer>
        {loading ? (
          <>
            <Styles.SpinnerContainer>
              <SpinnerComponent width={"5rem"} height={"5rem"} />
            </Styles.SpinnerContainer>
          </>
        ) : (
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Close"
              handleClick={handleCloseModal}
              width="138px"
              height="40px"
            />
            {!annotationStarted && (
              <PrimaryBtn type="submit" id="submit" label="Approve" width="138px" height="40px" />
            )}
            {annotationStarted && (
              <PrimaryBtn
                type="button"
                label="Upload"
                onClick={handleFileUpload}
                width="138px"
                height="40px"
              />
            )}
          </Styles.ButtonsContainer>
        )}
      </Styles.Form>
      {annotationStarted && requestStatus >= 0 && requestStatus < 2 && (
        <Styles.Button onClick={handleClickDeleteBtn}>[Cancel annotation request]</Styles.Button>
      )}
    </ModalWithChildren>
  );
};

export default StartAnnotationRequest;
