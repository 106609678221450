import styled from "styled-components";

const styles = {
  FiltersDropDownContainer: styled.div`
    display: flex;
    width: 166px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.13);
    position: absolute;
    z-index: 1;
  `,
  FilterItem: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  `,
  FilterText: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  `,
  EmptyMessage: styled.div`
    color: ${({ theme }) => theme.colors.neutral.grey500};
    font-size: 14px;
    text-align: center;
    width: 100%;
  `,
};

export default styles;
