import styled from "styled-components/macro";
//Icons
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/garbage_gray_icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow_down_gray.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrow_up_gray.svg";

const styles = {
  //Icons
  DeleteIcon: styled(DeleteIcon)`
    cursor: pointer;
  `,
  EditIcon: styled(EditIcon)`
    cursor: pointer;
  `,
  ArrowDown: styled(ArrowDown)`
    cursor: pointer;
  `,
  ArrowUp: styled(ArrowUp)`
    cursor: pointer;
  `,
  //Styles
  ManageVenueUsersContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    /* border-bottom: 1px solid #c4c4c4; */
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    margin: 0;
  `,
  UserRowContainer: styled.div`
    background: #ffffff;
    /* border-bottom:  1px solid #c4c4c4; */
    border-bottom: ${(props) => (props.rowToggle ? "none" : "1px solid #c4c4c4")};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  `,
  NoUsersText: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
  `,
  LoaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  UserInfoWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 0.5rem;
    }
  `,
  DetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @media screen and (max-width: 480px) {
      align-items: center;
    }
  `,
  NameAndRole: styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  Name: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
  `,
  Role: styled.span`
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 2px 5px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary.blue800};
  `,
  Email: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
    color: ${({ theme }) => theme.text.secondary};
  `,
  ActionIconsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
};

export default styles;
