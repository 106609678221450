import styled from "styled-components";

const styles = {
  StatusCircle: styled.div`
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.camera.active : theme.camera.error};
    border-radius: 50%;

    &.table {
      background-color: ${({ isActive, theme }) =>
        isActive ? theme.camera.active : theme.camera.inactive};
    }
  `,
};

export default styles;
