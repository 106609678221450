import styled from "styled-components/macro";

const styles = {
  SectionContainer: styled.section`
    min-height: calc(100vh - 105px);
    display: flex;
    justify-content: center;
    background-color: "#FFFFFF";
    background: radial-gradient(
      circle at 0% 51%,
      rgba(55, 158, 165, 0.12) 0%,
      rgba(229, 229, 229, 1) 100%
    );
  `,
  AlignmentContainer: styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 4rem;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
    @media screen and (max-width: 480px) {
      padding: 0 1rem;
    }
  `,
  Container: styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  ModelTitle: styled.h3`
    font-family: CircularStd700;
    font-size: 24px;
    color: #000000;
  `,
  ModelDescription: styled.p`
    font-family: "Roboto400";
    font-size: 21px;
    color: #000000;
  `,
  ErrorAndBackContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  `,
  ErrorMessage: styled.p`
    font-family: "PoppinsRegular";
    font-size: 17px;
    color: #ff3333;
  `,
};

export default styles;
