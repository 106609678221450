import React from "react";
// Styles
import Styled from "./SearchBar.styled";
// Components
import Icon from "components/Shared/Icon/Icon";

const SearchBar = ({ searchTerm, setSearchTerm, placeholder = "Search..." }) => {
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Styled.SearchContainer>
      <Icon name={"search"} size={"20px"} />
      <Styled.SearchInputField
        value={searchTerm}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Styled.SearchContainer>
  );
};

export default SearchBar;
