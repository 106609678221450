import { gql } from "@apollo/client";
import {
  CORE_OBJECT_DETECTION_ITEM_FIELDS,
  CORE_COMPANY_FIELDS,
  CORE_MACHINE_FIELDS,
  CORE_VENUE_FIELDS,
  CORE_MACHINE_THUMBNAIL_FIELDS,
  CORE_USER_FIELDS,
  CORE_MACHINE_AREA_FIELDS,
  CORE_DOCKER_FIELDS,
  CORE_BROKER_FIELDS,
  CORE_CONFIG_FIELDS,
  CORE_MODEL_FIELDS,
  CORE_MODEL_VERSION_FIELDS,
  CORE_FILE_ASSET_FIELDS,
  CORE_COLLECTION_REQUEST_FIELDS,
  CORE_ANNOTATION_REQUEST_FIELDS,
  CORE_TRAINING_REQUEST_FIELDS,
  CORE_RENTEDGPU_FIELDS,
  CORE_API_FIELDS,
} from "./fragments";

// Object counting
export const LIST_OBJECT_DETECTION_ITEMS = gql`
  ${CORE_OBJECT_DETECTION_ITEM_FIELDS}
  query listObjectDetectionItems {
    listObjectDetectionItems {
      data {
        ...CoreObjectDetectionItemFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_OBJECT_DETECTION_ITEM = gql`
  ${CORE_OBJECT_DETECTION_ITEM_FIELDS}
  mutation updateObjectDetectionItem(
    $objectDetectionIndex: Int
    $objectDetectionKey: String
    $objectDetectionLabel: String
    $objectDetectionStatus: Int
  ) {
    updateObjectDetectionItem(
      attributes: {
        objectDetectionIndex: $objectDetectionIndex
        objectDetectionKey: $objectDetectionKey
        objectDetectionLabel: $objectDetectionLabel
        objectDetectionStatus: $objectDetectionStatus
      }
    ) {
      data {
        ...CoreObjectDetectionItemFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_OBJECT_DETECTION_ITEM = gql`
  ${CORE_OBJECT_DETECTION_ITEM_FIELDS}
  mutation createObjectDetectionItem(
    $objectDetectionIndex: Int
    $objectDetectionKey: String
    $objectDetectionLabel: String
    $objectDetectionStatus: Int
  ) {
    createObjectDetectionItem(
      attributes: {
        objectDetectionIndex: $objectDetectionIndex
        objectDetectionKey: $objectDetectionKey
        objectDetectionLabel: $objectDetectionLabel
        objectDetectionStatus: $objectDetectionStatus
      }
    ) {
      data {
        ...CoreObjectDetectionItemFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//USER
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  ${CORE_USER_FIELDS}
  mutation updateUser($userFirstName: String, $userLastName: String, $userPhone: String) {
    updateUser(
      attributes: {
        userFirstName: $userFirstName
        userLastName: $userLastName
        userPhone: $userPhone
      }
    ) {
      data {
        ...CoreUserFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const ME = gql`
  query me {
    me {
      data {
        id
        userFirstName
        userLastName
        userEmail
        userPhone
        userStatus
        createdAt
        updatedAt
        isSuperAdmin
        Companies {
          id
          companyId
          companyName
          permissionType
        }
        Venues {
          id
          venueId
          venueName
          permissionType
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_USERS = gql`
  ${CORE_USER_FIELDS}
  query listUsers {
    listUsers {
      data {
        ...CoreUserFields
        userCompanies {
          id
          companyId
          permissionType
        }
        userVenues {
          id
          venueId
          permissionType
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_USER_BY_COMPANY = gql`
  query listUserByCompany($companyId: ID!) {
    listUserByCompany(companyId: $companyId) {
      data {
        id
        userFirstName
        userLastName
        userEmail
        createdAt
        updatedAt
        userId
        companyId
        permissionType
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_USER_BY_VENUE = gql`
  query listUserByVenue($venueId: ID!) {
    listUserByVenue(venueId: $venueId) {
      data {
        id
        userFirstName
        userLastName
        userEmail
        createdAt
        updatedAt
        userId
        companyId
        permissionType
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser(
    $companyId: ID!
    $userEmail: String!
    $permissionType: String!
    $venueIds: String
  ) {
    inviteUser(
      attributes: {
        companyId: $companyId
        userEmail: $userEmail
        permissionType: $permissionType
        venueIds: $venueIds
      }
    ) {
      message
      inviteCode
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const INVITE_USER_TO_VENUE = gql`
  mutation inviteUserToVenue(
    $companyId: ID!
    $userEmail: String
    $permissionType: String!
    $venueIds: String
    $userId: String
  ) {
    inviteUser(
      attributes: {
        companyId: $companyId
        userEmail: $userEmail
        permissionType: $permissionType
        venueIds: $venueIds
        userId: $userId
      }
    ) {
      message
      inviteCode
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const RETRIEVE_USER_VENUE_AND_COMPANY = gql`
  query retrieveUserVenueAndCompany($id: ID!) {
    retrieveUserVenueAndCompany(id: $id) {
      data {
        id
        userFirstName
        userLastName
        userEmail
        userPhone
        userStatus
        createdAt
        updatedAt
        Companies {
          id
          companyId
          companyName
          permissionType
        }
        Venues {
          id
          venueId
          venueName
          permissionType
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_USER_VENUE_PERMISSIONS = gql`
  mutation updateUserVenuePermission($id: ID!, $permissionType: String!) {
    updateUserVenuePermission(id: $id, permissionType: $permissionType) {
      data {
        id
        userId
        venueId
        permissionType
        createdAt
        updatedAt
        userFirstName
        userLastName
        userEmail
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const REMOVE_USER_FROM_VENUE = gql`
  mutation removeUserVenuePermission($venueId: ID!, $userId: ID!) {
    removeUserVenuePermission(userId: $userId, venueId: $venueId) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_USER_COMPANY_PERMISSIONS = gql`
  mutation updateUserCompanyPermission($id: ID!, $permissionType: String!) {
    updateUserCompanyPermission(id: $id, permissionType: $permissionType) {
      data {
        id
        userId
        companyId
        permissionType
        createdAt
        updatedAt
        userFirstName
        userLastName
        userEmail
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const REMOVE_USER_FROM_COMPANY = gql`
  mutation removeUserCompanyPermission($companyId: ID!, $userId: ID!) {
    removeUserCompanyPermission(companyId: $companyId, userId: $userId) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_DASHBOARD = gql`
  query listDashboard {
    listDashboard {
      data {
        totalCompanies
        totalVenues
        totalInferences
        Companies {
          id
          name
          venueCount
          cameraCount
          inferenceCount
          userCount
          licenseCount
          Venues {
            id
            name
          }
          Cameras {
            id
            name
          }
          Inferences {
            id
            name
          }
          Users {
            id
            name
          }
          Licenses {
            id
            name
          }
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//COMPANIES

export const LIST_COMPANY_STATS = gql`
  query listAllCompanyStats {
    listAllCompanyStats {
      data {
        machineCount
        inferenceCount
        inferenceActiveCount
        userCount
        venueCount
        companyCount
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;
export const LIST_COMPANY = gql`
  ${CORE_COMPANY_FIELDS}
  query listCompany {
    listCompany {
      data {
        ...CoreCompanyFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const RETRIEVE_COMPANY = gql`
  ${CORE_COMPANY_FIELDS}
  query retrieveCompany($id: ID!) {
    retrieveCompany(id: $id) {
      data {
        ...CoreCompanyFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  ${CORE_COMPANY_FIELDS}
  mutation createCompany(
    $companyName: String
    $companyStreet: String
    $companyAddress: String
    $companyState: String
    $companyCity: String
    $companyZip: String
    $companyCountry: String
    $companyContact: String
    $companyPhone: String
    $companyEmail: String
  ) {
    createCompany(
      attributes: {
        companyName: $companyName
        companyStreet: $companyStreet
        companyAddress: $companyAddress
        companyState: $companyState
        companyCity: $companyCity
        companyZip: $companyZip
        companyCountry: $companyCountry
        companyContact: $companyContact
        companyPhone: $companyPhone
        companyEmail: $companyEmail
      }
    ) {
      data {
        ...CoreCompanyFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  ${CORE_COMPANY_FIELDS}
  mutation updateCompany(
    $id: ID!
    $companyName: String
    $companyStreet: String
    $companyAddress: String
    $companyState: String
    $companyCity: String
    $companyZip: String
    $companyCountry: String
    $companyContact: String
    $companyPhone: String
    $companyEmail: String
  ) {
    updateCompany(
      id: $id
      attributes: {
        companyName: $companyName
        companyStreet: $companyStreet
        companyAddress: $companyAddress
        companyState: $companyState
        companyCity: $companyCity
        companyZip: $companyZip
        companyCountry: $companyCountry
        companyContact: $companyContact
        companyPhone: $companyPhone
        companyEmail: $companyEmail
      }
    ) {
      data {
        ...CoreCompanyFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//VENUES
export const CREATE_VENUE = gql`
  ${CORE_VENUE_FIELDS}
  mutation createVenue(
    $venueName: String
    $venueStreet: String
    $venueAddress: String
    $venueState: String
    $venueCity: String
    $venueZip: String
    $venueCountry: String
    $venueContact: String
    $venuePhone: String
    $venueEmail: String
    $companyId: ID!
  ) {
    createVenue(
      attributes: {
        venueName: $venueName
        venueStreet: $venueStreet
        venueAddress: $venueAddress
        venueState: $venueState
        venueCity: $venueCity
        venueZip: $venueZip
        venueCountry: $venueCountry
        venueContact: $venueContact
        venuePhone: $venuePhone
        venueEmail: $venueEmail
        companyId: $companyId
      }
    ) {
      data {
        ...CoreVenueFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_VENUE = gql`
  ${CORE_VENUE_FIELDS}
  ${CORE_MACHINE_AREA_FIELDS}
  mutation updateVenue(
    $id: ID!
    $venueName: String
    $venueStreet: String
    $venueAddress: String
    $venueState: String
    $venueCity: String
    $venueZip: String
    $venueCountry: String
    $venueContact: String
    $venuePhone: String
    $venueEmail: String
  ) {
    updateVenue(
      id: $id
      attributes: {
        venueName: $venueName
        venueStreet: $venueStreet
        venueAddress: $venueAddress
        venueState: $venueState
        venueCity: $venueCity
        venueZip: $venueZip
        venueCountry: $venueCountry
        venueContact: $venueContact
        venuePhone: $venuePhone
        venueEmail: $venueEmail
      }
    ) {
      data {
        ...CoreVenueFields
        Areas {
          ...CoreMachineAreaFields
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const RETRIEVE_VENUE = gql`
  ${CORE_VENUE_FIELDS}
  query retrieveVenue($id: ID!) {
    retrieveVenue(id: $id) {
      data {
        ...CoreVenueFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//MODELS
export const LIST_MODELS_ADMIN = gql`
  ${CORE_MODEL_FIELDS}
  query listModelAdmin($listAll: Int) {
    listModelAdmin(listAll: $listAll) {
      data {
        ...CoreModelFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_MODELS = gql`
  ${CORE_MODEL_FIELDS}
  query listModel($companyId: ID!) {
    listModel(companyId: $companyId) {
      data {
        ...CoreModelFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_FILE_ASSET = gql`
  ${CORE_FILE_ASSET_FIELDS}
  mutation ($id: ID!, $fileAssetVoid: Int) {
    updateFile(id: $id, attributes: { fileAssetVoid: $fileAssetVoid }) {
      data {
        ...CoreFileAssetFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_MODEL_VERSION = gql`
  ${CORE_MODEL_VERSION_FIELDS}
  mutation ($modelId: ID!, $modelVersionNotes: String, $modelVersionStatus: Int) {
    createModelVersion(
      attributes: {
        modelId: $modelId
        modelVersionNotes: $modelVersionNotes
        modelVersionStatus: $modelVersionStatus
      }
    ) {
      data {
        ...CoreModelVersionFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_MODEL_VERSION = gql`
  ${CORE_MODEL_VERSION_FIELDS}
  mutation ($id: ID!, $modelVersionNotes: String, $modelVersionStatus: Int) {
    updateModelVersion(
      id: $id
      attributes: { modelVersionNotes: $modelVersionNotes, modelVersionStatus: $modelVersionStatus }
    ) {
      data {
        ...CoreModelVersionFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_MODEL = gql`
  ${CORE_MODEL_FIELDS}
  mutation (
    $modelName: String
    $modelDescription: String
    $modelDetectionObjects: String
    $modelType: String
    $modelStatus: Int
    $modelBaseModel: Int
    $modelVersionNotes: String
    $modelVersionStatus: Int
    $companyId: ID
  ) {
    createModel(
      attributes: {
        modelName: $modelName
        modelDescription: $modelDescription
        modelDetectionObjects: $modelDetectionObjects
        modelType: $modelType
        modelStatus: $modelStatus
        modelBaseModel: $modelBaseModel
        modelVersionNotes: $modelVersionNotes
        modelVersionStatus: $modelVersionStatus
        companyId: $companyId
      }
    ) {
      data {
        ...CoreModelFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_MODEL = gql`
  ${CORE_MODEL_FIELDS}
  mutation (
    $id: ID!
    $modelVersionId: ID!
    $modelName: String
    $modelDescription: String
    $modelDetectionObjects: String
    $modelType: String
    $modelStatus: Int
    $modelBaseModel: Int
    $modelVersionNotes: String
    $modelVersionStatus: Int
    $companyId: ID
  ) {
    updateModel(
      id: $id
      modelVersionId: $modelVersionId
      attributes: {
        modelName: $modelName
        modelDescription: $modelDescription
        modelDetectionObjects: $modelDetectionObjects
        modelType: $modelType
        modelStatus: $modelStatus
        modelBaseModel: $modelBaseModel
        modelVersionNotes: $modelVersionNotes
        modelVersionStatus: $modelVersionStatus
        companyId: $companyId
      }
    ) {
      data {
        ...CoreModelFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_MODEL_VERSION = gql`
  ${CORE_MODEL_VERSION_FIELDS}
  ${CORE_FILE_ASSET_FIELDS}
  query ($id: ID!) {
    getModelVersion(id: $id) {
      data {
        ...CoreModelVersionFields
        FileAssets {
          ...CoreFileAssetFields
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//API
export const LIST_APIS = gql`
  ${CORE_API_FIELDS}
  query ($companyId: ID!) {
    listApi(companyId: $companyId) {
      data {
        ...CoreApiFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//DOCKERS
export const LIST_DOCKERS = gql`
  ${CORE_DOCKER_FIELDS}
  query listDocker($companyId: ID!) {
    listDocker(companyId: $companyId) {
      data {
        ...CoreDockerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_BROKERS = gql`
  ${CORE_BROKER_FIELDS}
  query ($companyId: ID) {
    listBroker(companyId: $companyId) {
      data {
        ...CoreBrokerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;
export const UPDATE_DOCKER = gql`
  ${CORE_DOCKER_FIELDS}
  mutation (
    $id: ID!
    $dockerName: String
    $dockerCustomId: String
    $venueId: ID
    $brokerId: ID
    $dockerNginxIP: String
    $dockerInferencePerTask: Int
    $dockerTaskCount: Int
    $dockerAutoRestart: Int
    $dockerStatus: Int
    $dockerAutoScale: Int
  ) {
    updateDocker(
      id: $id
      attributes: {
        dockerName: $dockerName
        dockerNginxIP: $dockerNginxIP
        dockerCustomId: $dockerCustomId
        venueId: $venueId
        brokerId: $brokerId
        dockerInferencePerTask: $dockerInferencePerTask
        dockerTaskCount: $dockerTaskCount
        dockerStatus: $dockerStatus
        dockerAutoRestart: $dockerAutoRestart
        dockerAutoScale: $dockerAutoScale
      }
    ) {
      data {
        ...CoreDockerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_BROKER = gql`
  ${CORE_BROKER_FIELDS}
  mutation (
    $id: ID!
    $brokerURL: String
    $brokerName: String
    $brokerURLType: String
    $brokerType: String
    $brokerPort: String
    $brokerHost: String
    $brokerChannel: String
    $brokerUser: String
    $brokerPassword: String
    $brokerSecret: String
    $brokerStatus: Int
  ) {
    updateBroker(
      id: $id
      attributes: {
        brokerURL: $brokerURL
        brokerName: $brokerName
        brokerURLType: $brokerURLType
        brokerType: $brokerType
        brokerPort: $brokerPort
        brokerHost: $brokerHost
        brokerChannel: $brokerChannel
        brokerUser: $brokerUser
        brokerPassword: $brokerPassword
        brokerSecret: $brokerSecret
        brokerStatus: $brokerStatus
      }
    ) {
      data {
        ...CoreBrokerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_BROKER = gql`
  ${CORE_BROKER_FIELDS}
  mutation ($id: ID!) {
    deleteBroker(id: $id) {
      data {
        ...CoreBrokerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const START_DOCKER = gql`
  query ($id: ID!, $dockerId: String) {
    startDocker(id: $id, dockerId: $dockerId) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const STOP_DOCKER = gql`
  query ($id: ID!, $dockerId: String) {
    stopDocker(id: $id, dockerId: $dockerId) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_DOCKER = gql`
  ${CORE_DOCKER_FIELDS}
  mutation ($id: ID!) {
    deleteDocker(id: $id) {
      data {
        ...CoreDockerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_DOCKER_STATUS = gql`
  ${CORE_DOCKER_FIELDS}
  mutation ($id: ID!, $dockerStatus: Int) {
    updateDockerStatus(id: $id, dockerStatus: $dockerStatus) {
      data {
        ...CoreDockerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_DOCKER = gql`
  ${CORE_DOCKER_FIELDS}
  mutation (
    $dockerName: String
    $dockerNginxIP: String
    $dockerCustomId: String
    $dockerInferencePerTask: Int
    $dockerTaskCount: Int
    $dockerAutoRestart: Int
    $dockerAutoScale: Int
    $dockerStatus: Int
    $companyId: ID!
    $brokerId: ID
    $venueId: ID
  ) {
    createDocker(
      attributes: {
        dockerName: $dockerName
        dockerNginxIP: $dockerNginxIP
        dockerCustomId: $dockerCustomId
        dockerInferencePerTask: $dockerInferencePerTask
        dockerTaskCount: $dockerTaskCount
        dockerAutoScale: $dockerAutoScale
        dockerAutoRestart: $dockerAutoRestart
        dockerStatus: $dockerStatus
        venueId: $venueId
        brokerId: $brokerId
        companyId: $companyId
      }
    ) {
      data {
        ...CoreDockerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_BROKER = gql`
  ${CORE_BROKER_FIELDS}
  mutation (
    $brokerURL: String
    $brokerType: String
    $brokerName: String
    $brokerURLType: String
    $brokerPort: String
    $brokerHost: String
    $brokerChannel: String
    $brokerUser: String
    $brokerPassword: String
    $brokerSecret: String
    $brokerStatus: Int
    $companyId: ID!
  ) {
    createBroker(
      attributes: {
        brokerURL: $brokerURL
        brokerType: $brokerType
        brokerName: $brokerName
        brokerURLType: $brokerURLType
        brokerPort: $brokerPort
        brokerHost: $brokerHost
        brokerChannel: $brokerChannel
        brokerUser: $brokerUser
        brokerPassword: $brokerPassword
        brokerSecret: $brokerSecret
        brokerStatus: $brokerStatus
        companyId: $companyId
      }
    ) {
      data {
        ...CoreBrokerFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_API = gql`
  ${CORE_API_FIELDS}
  mutation ($companyId: ID!, $apiName: String!) {
    createApi(companyId: $companyId, apiName: $apiName) {
      data {
        ...CoreApiFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_API = gql`
  ${CORE_API_FIELDS}
  mutation ($id: ID!, $apiStatus: Int, $apiName: String!) {
    updateApi(id: $id, apiStatus: $apiStatus, apiName: $apiName) {
      data {
        ...CoreApiFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_VENUE = gql`
  mutation ($id: ID!) {
    deleteVenue(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_VENUE = gql`
  ${CORE_MACHINE_AREA_FIELDS}
  ${CORE_VENUE_FIELDS}
  query retrieveVenue($id: ID!) {
    retrieveVenue(id: $id) {
      data {
        ...CoreVenueFields
        Areas {
          ...CoreMachineAreaFields
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_VENUES = gql`
  ${CORE_MACHINE_AREA_FIELDS}
  ${CORE_VENUE_FIELDS}
  query listVenues($venueIds: [ID]) {
    listVenues(venueIds: $venueIds) {
      data {
        ...CoreVenueFields
        Areas {
          ...CoreMachineAreaFields
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//AREAS
export const LIST_AREAS = gql`
  ${CORE_MACHINE_AREA_FIELDS}
  ${CORE_VENUE_FIELDS}
  query listArea($venueId: ID!) {
    listArea(venueId: $venueId) {
      area {
        ...CoreMachineAreaFields
      }
      venue {
        ...CoreVenueFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_AREA = gql`
  ${CORE_MACHINE_AREA_FIELDS}
  mutation updateArea($id: ID!, $areaName: String, $areaNotes: String) {
    updateArea(id: $id, attributes: { areaName: $areaName, areaNotes: $areaNotes }) {
      data {
        ...CoreMachineAreaFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_AREA = gql`
  mutation ($id: ID!) {
    deleteArea(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_AREAS = gql`
  mutation ($areaIds: String!) {
    deleteAreas(areaIds: $areaIds) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//MACHINES
export const CREATE_MACHINE = gql`
  ${CORE_MACHINE_FIELDS}
  mutation createMachine(
    $machineCustomId: String
    $machineName: String
    $machineRTSPUrl: String
    $machineWssToken: String
    $machineSpec: String
    $machineNotes: String
    $machineType: String
    $machineOverlayLabel: String
    $machineIntervalType: String
    $machineInferenceInterval: Float
    $machineSelectedObjects: String
    $areaId: ID
    $areaName: String
    $venueId: ID!
    $dockerId: ID
    $modelVersionId: ID!
    $machineModel: String
  ) {
    createMachine(
      attributes: {
        machineCustomId: $machineCustomId
        machineName: $machineName
        machineRTSPUrl: $machineRTSPUrl
        machineWssToken: $machineWssToken
        machineSpec: $machineSpec
        machineNotes: $machineNotes
        machineType: $machineType
        machineOverlayLabel: $machineOverlayLabel
        machineIntervalType: $machineIntervalType
        machineInferenceInterval: $machineInferenceInterval
        machineSelectedObjects: $machineSelectedObjects
        areaId: $areaId
        areaName: $areaName
        venueId: $venueId
        dockerId: $dockerId
        modelVersionId: $modelVersionId
        machineModel: $machineModel
      }
    ) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_MACHINE = gql`
  ${CORE_MACHINE_FIELDS}
  mutation (
    $id: ID!
    $machineName: String
    $machineCustomId: String
    $machineRTSPUrl: String
    $machineSpec: String
    $machineNotes: String
    $machineType: String
    $machineOverlayLabel: String
    $machineIntervalType: String
    $machineInferenceInterval: Float
    $machineSelectedObjects: String
    $areaName: String
    $areaId: ID
    $dockerId: ID
    $modelVersionId: ID!
    $machineModel: String
  ) {
    updateMachine(
      id: $id
      attributes: {
        machineCustomId: $machineCustomId
        dockerId: $dockerId
        machineName: $machineName
        machineRTSPUrl: $machineRTSPUrl
        machineSpec: $machineSpec
        machineNotes: $machineNotes
        machineType: $machineType
        machineOverlayLabel: $machineOverlayLabel
        machineIntervalType: $machineIntervalType
        machineInferenceInterval: $machineInferenceInterval
        machineSelectedObjects: $machineSelectedObjects
        areaId: $areaId
        areaName: $areaName
        modelVersionId: $modelVersionId
        machineModel: $machineModel
      }
    ) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_MACHINE_VECTORS = gql`
  ${CORE_MACHINE_FIELDS}
  mutation updateMachineVectors($id: ID!, $machineVectors: String!) {
    updateMachineVectors(id: $id, machineVectors: $machineVectors) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_MACHINE_RESOLUTION = gql`
  ${CORE_MACHINE_FIELDS}
  mutation UpdateMachineResolution(
    $id: ID!
    $machineWidth: String!
    $machineHeight: String!
    $machineMaskingScale: String!
  ) {
    updateMachineResolution(
      id: $id
      machineWidth: $machineWidth
      machineHeight: $machineHeight
      machineMaskingScale: $machineMaskingScale
    ) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_MACHINES = gql`
  mutation deleteMachines($machineIds: String!) {
    deleteMachines(machineIds: $machineIds) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const MACHINE_RTSP_NOW_UPDATED_SUBSCRIPTION = gql`
  ${CORE_MACHINE_FIELDS}
  subscription MachineRTSPNowUpdated($id: ID!) {
    machineRTSPNowUpdated(id: $id) {
      ...CoreMachineFields
    }
  }
`;

export const DOCKER_STATUS_UPDATED = gql`
  subscription DockerStatusUpdated($companyId: ID!) {
    dockerStatusUpdated(companyId: $companyId) {
      dockerId
      venueId
      companyId
      dockerLastPingTime
    }
  }
`;

export const MACHINE_STATUS_UPDATED_SUBSCRIPTION = gql`
  subscription MachineStatusUpdated($companyId: ID!) {
    machineStatusUpdated(companyId: $companyId) {
      machineId
      areaId
      companyId
      venueId
      machineLastPingTime
    }
  }
`;

export const DOCKER_CONTAINER_STATUS_UPDATED = gql`
  subscription DockerContainerStatusUpdated($dockerId: ID!) {
    dockerContainerStatusUpdated(dockerId: $dockerId) {
      dockerId
      data
    }
  }
`;

export const DOCKER_CMD_LOG_UPDATE_SUBSCRIPTION = gql`
  subscription DockerCmdLogUpdated($companyId: ID!) {
    dockerCmdLogUpdated(companyId: $companyId) {
      id
      dockerCmd
      dockerCmdResponse
      dockerCmdStatus
      venueId
      companyId
      userId
      machineId
      createdAt
      updatedAt
    }
  }
`;

export const MACHINE_THUMBNAIL_UPDATED_SUBSCRIPTION = gql`
  subscription MachineThumbnailUpdated($id: ID!) {
    machineThumbnailUpdated(id: $id) {
      id
      machineThumbnailURL
      createdAt
      updatedAt
    }
  }
`;

export const PREVIEW_RTSP = gql`
  mutation ($venueId: ID!) {
    retrieveMachineRTSPThumbnails(venueId: $venueId) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const PREVIEW_RTSP_NOW = gql`
  mutation PreviewRTSPNow($id: ID!) {
    previewRTSPNow(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const START_BENCHMARK = gql`
  ${CORE_MACHINE_FIELDS}
  mutation ($id: ID!) {
    startBenchmark(id: $id) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const START_INFERENCE = gql`
  ${CORE_MACHINE_FIELDS}
  mutation ($id: ID!) {
    startInference(id: $id) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const STOP_INFERENCE = gql`
  ${CORE_MACHINE_FIELDS}
  mutation ($id: ID!) {
    stopInference(id: $id) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const START_ALL_INFERENCE = gql`
  ${CORE_MACHINE_FIELDS}
  mutation ($venueId: ID!, $machineIds: String!) {
    startAllInference(venueId: $venueId, machineIds: $machineIds) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const STOP_ALL_INFERENCE = gql`
  ${CORE_MACHINE_FIELDS}
  mutation ($venueId: ID!, $machineIds: String!) {
    stopAllInference(venueId: $venueId, machineIds: $machineIds) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const RETRIEVE_MACHINE = gql`
  ${CORE_MACHINE_FIELDS}
  query retrieveMachine($id: ID!) {
    retrieveMachine(id: $id) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const RETRIEVE_MACHINE_THUMBNAIL = gql`
  ${CORE_MACHINE_THUMBNAIL_FIELDS}
  query retrieveMachineThumbnail($id: ID!) {
    retrieveMachineThumbnail(id: $id) {
      data {
        ...CoreMachineThumbnailFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//DEMO
export const CREATE_INFERENCE = gql`
  mutation createInference($userEmail: String!, $userUrl: String!, $inferenceModel: String!) {
    createInference(
      attributes: { userEmail: $userEmail, userUrl: $userUrl, inferenceModel: $inferenceModel }
    ) {
      data {
        id
        inferenceCode
        inferenceType
        inferenceModel
        inferenceStatus
        inferenceStartDate
        inferenceEndDate
        inferenceResult
        userEmail
        userUrl
        createdAt
        updatedAt
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_DOCKER_CONTAINER_STATUS = gql`
  query ($id: ID!) {
    getDockerStatus(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_DOCKER_CMD_LOG = gql`
  query ($companyId: ID, $venueId: ID, $machineId: ID) {
    listDockerCmdLog(companyId: $companyId, venueId: $venueId, machineId: $machineId) {
      logs {
        id
        dockerCmd
        dockerCmdResponse
        dockerCmdStatus
        venueId
        companyId
        machineId
        userId
        createdAt
        updatedAt
        companyName
        venueName
        machineName
        userName
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

// Constants
export const LIST_CONFIG = gql`
  ${CORE_CONFIG_FIELDS}
  query {
    listConfig {
      data {
        ...CoreConfigFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_CONFIG = gql`
  ${CORE_CONFIG_FIELDS}
  mutation (
    $configName: String
    $configValue: String
    $configType: String
    $configOrder: Int
    $configStatus: Int
  ) {
    createConfig(
      attributes: {
        configName: $configName
        configValue: $configValue
        configType: $configType
        configOrder: $configOrder
        configStatus: $configStatus
      }
    ) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_CONFIG = gql`
  ${CORE_CONFIG_FIELDS}
  mutation updateConfig(
    $id: ID!
    $configName: String
    $configValue: String
    $configValue2: String
    $configType: String
    $configOrder: Int
    $configStatus: Int
  ) {
    updateConfig(
      id: $id
      attributes: {
        configName: $configName
        configValue: $configValue
        configType: $configType
        configOrder: $configOrder
        configStatus: $configStatus
      }
    ) {
      data {
        ...CoreMachineFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_TRAINING_REQUEST = gql`
  ${CORE_TRAINING_REQUEST_FIELDS}
  mutation (
    $modelId: ID
    $modelVersionId: ID
    $companyId: ID!
    $mtModel: String
    $mtManualReview: Int
    $mtNewModel: Int
    $mtDetectionItems: String
    $mtNotes: String
    $mtName: String
    $annotationIds: String
  ) {
    createTrainingRequest(
      attributes: {
        modelId: $modelId
        modelVersionId: $modelVersionId
        companyId: $companyId
        mtModel: $mtModel
        mtName: $mtName
        mtNewModel: $mtNewModel
        mtManualReview: $mtManualReview
        mtDetectionItems: $mtDetectionItems
        mtNotes: $mtNotes
        annotationIds: $annotationIds
      }
    ) {
      data {
        ...CoreTrainingRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_TRAINING_REQUEST = gql`
  ${CORE_TRAINING_REQUEST_FIELDS}
  mutation (
    $id: ID!
    $modelId: ID
    $modelVersionId: ID
    $mtModel: String
    $mtManualReview: Int
    $mtDetectionItems: String
    $mtNewModel: Int
    $mtNotes: String
    $mtName: String
    $annotationIds: String
  ) {
    updateTrainingRequest(
      id: $id
      attributes: {
        modelId: $modelId
        modelVersionId: $modelVersionId
        mtModel: $mtModel
        mtNewModel: $mtNewModel
        mtName: $mtName
        mtManualReview: $mtManualReview
        mtDetectionItems: $mtDetectionItems
        mtNotes: $mtNotes
        annotationIds: $annotationIds
      }
    ) {
      data {
        ...CoreTrainingRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_TRAINING_REQUEST = gql`
  mutation ($id: ID!) {
    deleteTrainingRequest(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DEPLOY_TRAINING_REQUEST = gql`
  ${CORE_TRAINING_REQUEST_FIELDS}
  mutation ($id: ID!) {
    deployTrainingRequest(id: $id) {
      data {
        ...CoreTrainingRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const START_TRAINING_REQUEST = gql`
  ${CORE_TRAINING_REQUEST_FIELDS}
  mutation ($id: ID!, $gpuOfferId: Int!, $gpuMachineId: Int!) {
    startTrainingRequest(id: $id, gpuOfferId: $gpuOfferId, gpuMachineId: $gpuMachineId) {
      data {
        ...CoreTrainingRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const DELETE_ANNOTATION_REQUEST = gql`
  mutation ($id: ID!) {
    deleteAnnotationRequest(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_ANNOTATION_REQUEST = gql`
  ${CORE_ANNOTATION_REQUEST_FIELDS}
  mutation (
    $collectionRequestId: ID!
    $arProvider: String
    $arStatus: Int
    $arManualReview: Int
    $arNotes: String
  ) {
    createAnnotationRequest(
      attributes: {
        collectionRequestId: $collectionRequestId
        arProvider: $arProvider
        arStatus: $arStatus
        arManualReview: $arManualReview
        arNotes: $arNotes
      }
    ) {
      data {
        ...CoreAnnotationRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const CREATE_COLLECTION_REQUEST = gql`
  ${CORE_COLLECTION_REQUEST_FIELDS}
  mutation (
    $machineId: ID!
    $crModel: String
    $crInterval: Int
    $crMaxFrames: Int
    $crStartTimestamp: Int
    $crEndTimestamp: Int
    $crDetectionItems: String
    $crManualReview: Int
    $crStartNow: Int
    $crNotes: String
  ) {
    createCollectionRequest(
      attributes: {
        machineId: $machineId
        crModel: $crModel
        crInterval: $crInterval
        crMaxFrames: $crMaxFrames
        crStartTimestamp: $crStartTimestamp
        crEndTimestamp: $crEndTimestamp
        crDetectionItems: $crDetectionItems
        crManualReview: $crManualReview
        crStartNow: $crStartNow
        crNotes: $crNotes
      }
    ) {
      data {
        ...CoreCollectionRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const UPDATE_COLLECTION_REQUEST = gql`
  ${CORE_COLLECTION_REQUEST_FIELDS}
  mutation (
    $id: ID!
    $crModel: String
    $crInterval: Int
    $crMaxFrames: Int
    $crStartTimestamp: Int
    $crEndTimestamp: Int
    $crDetectionItems: String
    $crManualReview: Int
    $crStartNow: Int
    $crNotes: String
  ) {
    updateCollectionRequest(
      id: $id
      attributes: {
        crModel: $crModel
        crInterval: $crInterval
        crMaxFrames: $crMaxFrames
        crStartTimestamp: $crStartTimestamp
        crEndTimestamp: $crEndTimestamp
        crDetectionItems: $crDetectionItems
        crManualReview: $crManualReview
        crStartNow: $crStartNow
        crNotes: $crNotes
      }
    ) {
      data {
        ...CoreCollectionRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;
export const DELETE_COLLECTION_REQUEST = gql`
  mutation ($id: ID!) {
    deleteCollectionRequest(id: $id) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_COLLECTION_REQUESTS = gql`
  ${CORE_COLLECTION_REQUEST_FIELDS}
  query ($companyId: ID!) {
    listCollectionRequest(companyId: $companyId) {
      data {
        ...CoreCollectionRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const COMBINED_LIST_COLLECTION_AND_TRAINING_REQUESTS = gql`
  ${CORE_COLLECTION_REQUEST_FIELDS}
  ${CORE_TRAINING_REQUEST_FIELDS}
  query ($companyId: ID!) {
    listCollectionRequest: listCollectionRequest(companyId: $companyId) {
      data {
        ...CoreCollectionRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }

    listTrainingRequest: listTrainingRequest(companyId: $companyId) {
      data {
        ...CoreTrainingRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_COLLECTION_REQUEST = gql`
  ${CORE_COLLECTION_REQUEST_FIELDS}
  ${CORE_FILE_ASSET_FIELDS}
  query ($id: ID!) {
    getCollectionRequest(id: $id) {
      data {
        ...CoreCollectionRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_FILES = gql`
  ${CORE_FILE_ASSET_FIELDS}
  query (
    $modelVersionId: ID
    $collectionRequestId: ID
    $annotationRequestId: ID
    $modelTrainingRequestId: ID
  ) {
    listFiles(
      modelVersionId: $modelVersionId
      collectionRequestId: $collectionRequestId
      annotationRequestId: $annotationRequestId
      modelTrainingRequestId: $modelTrainingRequestId
    ) {
      data {
        ...CoreFileAssetFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_ANNOTATION_REQUESTS = gql`
  ${CORE_ANNOTATION_REQUEST_FIELDS}
  query ($companyId: ID!) {
    listAnnotationRequest(companyId: $companyId) {
      data {
        ...CoreAnnotationRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_ANNOTATION_REQUEST = gql`
  ${CORE_ANNOTATION_REQUEST_FIELDS}
  query ($id: ID!) {
    getAnnotationRequest(id: $id) {
      data {
        ...CoreAnnotationRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_TRAINING_REQUESTS = gql`
  ${CORE_TRAINING_REQUEST_FIELDS}
  query ($companyId: ID!) {
    listTrainingRequest(companyId: $companyId) {
      data {
        ...CoreTrainingRequestFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const GET_RENTEDGPU_BY_REQUEST_ID = gql`
  ${CORE_RENTEDGPU_FIELDS}
  query ($id: ID!) {
    getRentedGPUsByRequestId(id: $id) {
      data {
        ...CoreRentedGPUFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_RENTED_INSTANCES = gql`
  ${CORE_RENTEDGPU_FIELDS}
  query ($companyId: ID!) {
    listRentedGPUs(companyId: $companyId) {
      data {
        ...CoreRentedGPUFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const STOP_RENTED_INSTANCE = gql`
  ${CORE_RENTEDGPU_FIELDS}
  mutation ($id: ID!, $rentedGPUsNotes: String) {
    stopRentedGPU(id: $id, attributes: { rentedGPUsNotes: $rentedGPUsNotes }) {
      data {
        ...CoreRentedGPUFields
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const LIST_AVAILABLE_GPU_INSTANCES = gql`
  query searchVastAIGPUInstances {
    searchVastAIGPUInstances {
      data {
        id
        compute_cap
        cpu_arch
        cpu_cores
        cpu_cores_effective
        cpu_ghz
        cpu_name
        cpu_ram
        cuda_max_good
        disk_space
        dph_total
        duration
        geolocation
        gpu_name
        gpu_ram
        gpu_total_ram
        machine_id
        num_gpus
        reliability
        score
        total_flops
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;
