import React from "react";
// Styles
import Styled from "./SubLink.styled";
// Tools
import { useParams } from "react-router-dom";

const SubLink = ({ label, path, venueId }) => {
  const { venueId: currentVenueId } = useParams();
  const isSelected = currentVenueId === venueId;

  return (
    <Styled.SubLinkContainer to={path}>
      <Styled.SubLinkLabel isSelected={isSelected}>{label}</Styled.SubLinkLabel>
    </Styled.SubLinkContainer>
  );
};

export default SubLink;
