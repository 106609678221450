import React, { useRef } from "react";
import Styles from "./styles";
//Components
import DefaultDashBtn from "../Buttons/DefaultDashBtn";
import PrimaryBtn from "../Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../Buttons/SecondaryBtn/SecondaryBtn";

const ModalDefault = ({
  showModal,
  closeModal,
  title,
  handleModalForwardClick,
  infoModal,
  subtitle,
}) => {
  const modalRef = useRef();

  const closeModalRef = (e) => {
    if (modalRef.current === e.target) {
      closeModal();
    }
  };

  return (
    <>
      {showModal ? (
        <Styles.Background onClick={closeModalRef} ref={modalRef}>
          <Styles.ModalWrapper showModal={showModal}>
            <Styles.CloseModalButton aria-label="Close modal" onClick={() => closeModal()} />
            <Styles.ModalContent>
              <Styles.Title>{title}</Styles.Title>
              {subtitle ? <Styles.SubTitle>{subtitle}</Styles.SubTitle> : null}
              <Styles.ButtonsContainer>
                {infoModal ? (
                  <DefaultDashBtn
                    orangebg
                    smallsize
                    label="Ok"
                    handleClick={() => handleModalForwardClick()}
                  />
                ) : (
                  <>
                    <SecondaryBtn
                      label="No"
                      withIcon={false}
                      width="138px"
                      height="40px"
                      onClick={() => closeModal()}
                    />
                    <PrimaryBtn
                      label="Yes"
                      withIcon={false}
                      width="138px"
                      height="40px"
                      onClick={() => handleModalForwardClick()}
                    />
                  </>
                )}
              </Styles.ButtonsContainer>
            </Styles.ModalContent>
          </Styles.ModalWrapper>
        </Styles.Background>
      ) : null}
    </>
  );
};

export default ModalDefault;
