import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
//Styles
import Styled from "./Controllers.styled";
//Components
import DockerCard from "../../../components/Shared/DockerCard";
import AddUpdateDockerModal from "../../Modals/AddUpdateDocker";
import DockerCmdLog from "../../Modals/DockerCmdLog";
import { checkUserPermissionIsAdmin } from "../../../configs/helpers";
import { AuthContext } from "../../../context/authContext";
import { useToastModal } from "../../../context/ToastModalContext";
import {
  LIST_DOCKERS,
  DOCKER_STATUS_UPDATED,
  DOCKER_CMD_LOG_UPDATE_SUBSCRIPTION,
} from "../../../graphql/graph";
import Icon from "components/Shared/Icon/Icon";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";

const Controllers = ({ venuesList }) => {
  const { companyId } = useParams();
  const { addToast } = useToastModal();
  const { loggedUserAttributes } = useContext(AuthContext);
  const [showDockerCmdLog, setShowDockerCmdLog] = useState(false);
  const [dockerList, setDockerList] = useState([]);
  const [dockerToUpdate, setDockerToUpdate] = useState(null);
  const [showAddDockerModal, setShowAddDockerModal] = useState(false);
  const [dockerCmdLogData, setDockerCmdLogData] = useState([]);

  const { data: dockerCmdLogUpdated } = useSubscription(DOCKER_CMD_LOG_UPDATE_SUBSCRIPTION, {
    variables: { companyId },
  });

  const { data: dockerStatusUpdate } = useSubscription(DOCKER_STATUS_UPDATED, {
    variables: { companyId },
  });

  const { data: dockersData } = useQuery(LIST_DOCKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const handleAddOrUpdateLog = (updatedCmdLog) => {
    // find index of the dockerCmdLogData with the same dockerId
    const index = dockerCmdLogData.findIndex(
      (dockerCmdLog) => dockerCmdLog.id === updatedCmdLog.id,
    );

    // if index is -1, it means that the dockerCmdLogData is not in the array
    if (index === -1) {
      setDockerCmdLogData((prev) => [...prev, updatedCmdLog]);
    } else {
      const newDockerCmdLogData = [...dockerCmdLogData];
      newDockerCmdLogData[index] = updatedCmdLog;
      setDockerCmdLogData(newDockerCmdLogData);
    }
  };

  useEffect(() => {
    if (dockerCmdLogUpdated && dockerCmdLogUpdated.dockerCmdLogUpdated) {
      handleAddOrUpdateLog(dockerCmdLogUpdated.dockerCmdLogUpdated);
    }
  }, [dockerCmdLogUpdated]);

  useEffect(() => {
    if (dockersData && dockersData.listDocker) {
      setDockerList(dockersData.listDocker.data);
    }
  }, [dockersData]);

  useEffect(() => {
    if (dockerStatusUpdate && dockerStatusUpdate.dockerStatusUpdated) {
      handleDockerTimeStampUpdate(dockerStatusUpdate.dockerStatusUpdated);
    }
  }, [dockerStatusUpdate]);

  const handleDockerTimeStampUpdate = ({ dockerId, dockerLastPingTime }) => {
    setDockerList(
      dockerList.map((docker) => {
        if (docker.id === dockerId) {
          return {
            ...docker,
            dockerLastPingTime,
          };
        }

        return {
          ...docker,
        };
      }),
    );
  };

  const handleClickAddUpdateDocker = (docker) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      setDockerToUpdate(docker);
      setShowAddDockerModal((prev) => !prev);
    } else {
      addToast("You must be a Venue Admin to add or update Docker!", "error");
    }
  };

  const renderDockers = () => {
    if (!dockerList.length) {
      return <DockerCard title="No Docker Controllers" />;
    }
    return dockerList.map((docker) => {
      return (
        <DockerCard
          key={docker.id}
          title={docker.dockerName}
          dockerLastPingTime={docker.dockerLastPingTime}
          updateDocker={() => handleClickAddUpdateDocker(docker)}
        />
      );
    });
  };

  const toggleDockerCmdLog = () => {
    setShowDockerCmdLog((prev) => !prev);
  };

  return (
    <Styled.ControllersContainer>
      <Styled.Header>
        <Styled.Title>Controllers</Styled.Title>
        <Styled.DockerCMDLog onClick={toggleDockerCmdLog}>
          <Styled.IconContainer>
            <Icon name="licenses" size="20px" />
          </Styled.IconContainer>
          Docker CMD Log
        </Styled.DockerCMDLog>
      </Styled.Header>
      <Styled.DockerContainer>
        <PrimaryBtn
          label="Add Controller"
          handleClick={() => handleClickAddUpdateDocker(null)}
          icon={"plus"}
          iconSize={"20px"}
        />
        {renderDockers()}
      </Styled.DockerContainer>
      {showAddDockerModal && (
        <AddUpdateDockerModal
          showModal={showAddDockerModal}
          setShowModal={setShowAddDockerModal}
          setDockerToUpdate={setDockerToUpdate}
          dockerToUpdate={dockerToUpdate}
          venues={venuesList}
          companyId={companyId}
        />
      )}
      {showDockerCmdLog && (
        <DockerCmdLog
          showModal={showDockerCmdLog}
          setShowModal={setShowDockerCmdLog}
          dockerCmdLogData={dockerCmdLogData}
        />
      )}
    </Styled.ControllersContainer>
  );
};

export default Controllers;
