import React from "react";
// Styles
import Styled from "./CompanyCard.styled";
// Components
import Icon from "components/Shared/Icon/Icon";
import CompanySectionItem from "../CompanySectionItem/CompanySectionItem";

const CompanyCard = ({ company }) => {
  return (
    <Styled.CompanyCardContainer>
      <Styled.HeaderContainer>
        <Styled.CompanyNameContainer>
          <Styled.LogoContainer>
            <Icon name="venue" size="38px" />
          </Styled.LogoContainer>
          <Styled.CompanyName>{company.name}</Styled.CompanyName>
        </Styled.CompanyNameContainer>

        {/* <Styled.Button>More</Styled.Button> */}
      </Styled.HeaderContainer>

      <Styled.Divider />

      <Styled.SectionContainer>
        <CompanySectionItem
          title="Venues"
          items={company.Venues}
          icon={"venue"}
          numberOfItems={company.venueCount}
        />
        <CompanySectionItem
          title="Cameras"
          items={company.Cameras}
          icon={"cameras"}
          numberOfItems={company.cameraCount}
        />
        <CompanySectionItem
          title="Inferences"
          items={company.Inferences}
          icon={"inferences"}
          numberOfItems={company.inferenceCount}
        />
        <CompanySectionItem
          title="Users"
          items={company.Users}
          icon={"users"}
          numberOfItems={company.userCount}
        />
        <CompanySectionItem
          title="Licenses"
          items={company.Licenses}
          icon={"licenses"}
          numberOfItems={company.licenseCount}
        />
      </Styled.SectionContainer>
    </Styled.CompanyCardContainer>
  );
};

export default CompanyCard;
