import React from "react";
// Styles
import Styled from "./Company.styled";
// Components
import SpinnerComponent from "components/Loader";
import Overview from "components/Shared/Overview/Overview";
import Network from "./Network/Network";
import Controllers from "./Controllers/Controllers";
import Brokers from "./Brokers/Brokers";
import Training from "./Training/Training";
import FallbackUI from "components/Shared/Fallback/Fallback";
import ApiKeys from "./ApiKeys/ApiKeys";
// Hooks
import useCompanyData from "hooks/useCompanyData";
// Tools
import { useParams } from "react-router-dom";

const Company = () => {
  const { companyId } = useParams();
  const { companyData, overviewItems, venueDetails, loading, error, refetchCompany } =
    useCompanyData(companyId);

  if (loading) {
    return (
      <Styled.SpinnerContainer>
        <SpinnerComponent width={"50"} height={"50"} />
      </Styled.SpinnerContainer>
    );
  }

  if (error) {
    return <FallbackUI onRetry={refetchCompany} />;
  }

  return (
    <Styled.CompanyContainer>
      <Overview title={companyData?.companyName} items={overviewItems} />
      <Network venueDetails={venueDetails} />
      <Controllers venuesList={venueDetails} />
      <Brokers />
      <ApiKeys />
      <Training venuesList={venueDetails} />
    </Styled.CompanyContainer>
  );
};

export default Company;
