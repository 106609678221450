import React, { useState, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import Styles from "./styles";
import FileAssetCard from "../FileAssetCard";
import ProgressBar from "../ProgressBar";
import { getUri } from "../../configs/configEnviroment";

const defaultFileTypes = [
  "PT",
  "PTH",
  "H5",
  "CKPT",
  "ONNX",
  "WEIGHTS",
  "TFLITE",
  "PB",
  "BIN",
  "ENGINE",
  "TRT",
  "XML",
  "CFG",
  "JSON",
  "PNG",
  "ZIP",
];

// eslint-disable-next-line react/display-name
const FileUpload = forwardRef((props, ref) => {
  const { title, fileTypes, deleteFile, selectedFiles, setSelectedFiles, selectedFilesError } =
    props;
  // console.log(selectedFiles);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (file) => {
    setSelectedFiles((prev) => [...prev, ...file]);
  };

  const handleFileDelete = async (file) => {
    const { id, name } = file;
    // if temp remove from selectedFiles
    if (!id) {
      setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.name !== name));
    } else {
      deleteFile(id);
    }
  };

  useImperativeHandle(ref, () => ({
    async handleModelFileUpload(modelVersionId) {
      // console.log(`modelVersionId ${modelVersionId}`, selectedFiles);
      const formData = new FormData();

      formData.append("modelVersionId", modelVersionId);

      // upload only temp files
      selectedFiles.forEach((file) => {
        console.log(file);
        if (!file.id) {
          formData.append("models", file);
        }
      });

      // if formData is empty, return
      if (formData.getAll("models").length === 0) {
        return;
      }

      // const uploadUrl = "http://localhost:4000/upload/model";
      const uploadUrl = `https://${getUri()}/upload/model`;

      await handleFileUpload(formData, uploadUrl);
    },
    async handleAnnotationFileUpload(annotationRequestId) {
      // console.log("annotationRequestId", annotationRequestId);
      const formData = new FormData();

      formData.append("annotationRequestId", annotationRequestId);

      // upload only temp files
      selectedFiles.forEach((file) => {
        if (!file.id) {
          formData.append("file", file);
        }
      });

      // const uploadUrl = "http://localhost:4000/upload/annotationData";
      const uploadUrl = `https://${getUri()}/upload/annotationData`;

      await handleFileUpload(formData, uploadUrl);
    },
  }));

  const handleFileUpload = async (formData, uploadUrl) => {
    // grab token
    const token = localStorage.getItem("access_token");

    const config = {
      method: "post",
      url: uploadUrl,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(percentCompleted);
        setUploadProgress(percentCompleted);
      },
    };

    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Styles.Container>
      <Styles.Label>{title || "File Upload"}</Styles.Label>
      <FileUploader
        maxSize={500}
        onTypeError={(type) => alert(`File type ${type} not allowed`)}
        multiple
        handleChange={handleFileChange}
        name="file"
        types={fileTypes || defaultFileTypes}
      >
        <Styles.DragAndDropContainer>
          <Styles.Label>Drag and drop your files here</Styles.Label>
          <Styles.DragAndDropFileContainer>
            {[...selectedFiles].map((file) => (
              <FileAssetCard
                key={file.name || file.id}
                file={file}
                handleFileDelete={handleFileDelete}
              />
            ))}
          </Styles.DragAndDropFileContainer>
        </Styles.DragAndDropContainer>
      </FileUploader>
      <Styles.SubmitError>{selectedFilesError}</Styles.SubmitError>
      {uploadProgress > 0 && <ProgressBar progress={uploadProgress} />}
    </Styles.Container>
  );
});

export default FileUpload;
