import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
// Styles
import Styled from "./VenueCard.styled";
// Components
import StatusCircle from "components/Shared/StatusCircle/StatusCircle";
import { useToastModal } from "../../../context/ToastModalContext";
import Icon from "components/Shared/Icon/Icon";
import { checkUserPermissionIsAdmin } from "../../../configs/helpers";
import AddUpdateCameraModal from "../../../containers/Modals/AddUpdateCamera/AddUpdateCamera";
// Hooks
import { AuthContext } from "../../../context/authContext";
import useInferenceHandlers from "hooks/useInferenceHandlers";
import useNavigateCamera from "hooks/useNavigateCamera";
// Utils
import { formatName } from "utils/formatName";

const VenueCard = ({ camera, venue }) => {
  const { handleClickStartInference, handleClickStopInference } = useInferenceHandlers(camera);
  const { handleOpenCamera } = useNavigateCamera();
  const [showAddUpdateCameraModal, setShowAddUpdateCameraModal] = useState(false);
  const { companyId } = useParams();
  const [createUpdateObj, setCreateUpdateObj] = useState({});
  const { loggedUserAttributes } = useContext(AuthContext);
  const { addToast } = useToastModal();

  const handleClickAddUpdateCamera = (venue, camera) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venue?.id || camera.venueId)) {
      if (camera) {
        setCreateUpdateObj({ ...camera, venueAreas: venue.Areas });
      } else if (venue && !camera) {
        setCreateUpdateObj({ venueId: venue.id, venueAreas: venue.Areas });
      }

      setShowAddUpdateCameraModal((prev) => !prev);
    } else {
      addToast("You must be an Admin to update!", "error");
    }
  };

  const closeModals = () => {
    setShowAddUpdateCameraModal(false);

    // clear state
    setCreateUpdateObj({});
  };

  return (
    <Styled.VenueCardContainer>
      <Styled.Image
        src={camera.thumbnail?.machineThumbnailURL}
        img={camera.thumbnail}
        hasDockerId={camera.dockerId}
        onClick={() => handleOpenCamera(camera)}
      />

      <Styled.CheckboxContainer>{/* <Checkbox /> */}</Styled.CheckboxContainer>
      <Styled.StatusCircleContainer>
        <StatusCircle isActive={camera.machineStatus === 1} />
      </Styled.StatusCircleContainer>

      <Styled.Info>
        <Styled.Content>
          <Styled.Title>{camera.machineName}</Styled.Title>
          <Styled.Subtitle>{formatName(camera.machineModel)}</Styled.Subtitle>
        </Styled.Content>

        <Styled.Actions>
          {camera.machineStatus > 0 ? (
            <Icon
              name="pause"
              size="20px"
              className="action-icon"
              onClick={handleClickStopInference}
            />
          ) : (
            <Icon
              name="play"
              size="20px"
              className="action-icon"
              onClick={handleClickStartInference}
            />
          )}
          <Icon
            name={"edit_camera"}
            size="15px"
            className="action-icon"
            onClick={() => handleClickAddUpdateCamera(venue, camera)}
          />
        </Styled.Actions>
      </Styled.Info>
      <AddUpdateCameraModal
        showModal={showAddUpdateCameraModal}
        setShowModal={closeModals}
        cameraObj={createUpdateObj}
      />
    </Styled.VenueCardContainer>
  );
};

export default VenueCard;
