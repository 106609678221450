import styled from "styled-components/macro";

const styles = {
  ProfileContainer: styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;
    flex-shrink: 0;
    border-radius: 12px;
    gap: 32px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  Divider: styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    width: 95%;
    align-self: center;
  `,
  FormAndTitleContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h3`
    margin: 0;
    padding-bottom: 2rem;
    font-size: ${({ theme }) => theme.fontSizes.subHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 17px;
  `,
  FormContainer: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  `,
  InputContainer: styled.div`
    display: flex;
    padding: 0px 14px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.neutral.grey200};
  `,
  Input: styled.input`
    display: flex;
    height: 40px;
    width: 100%;
    gap: 8px;
    border: none;
    outline: none;
  `,
  SubmitError: styled.div`
    display: flex;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes.field}
    color: ${({ theme }) => theme.colors.error.red300};
    margin-bottom: 10px;
  `,
  SubmitSuccess: styled.div`
    display: flex;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes.field};
    color: ${({ theme }) => theme.colors.success.green700};
    margin-bottom: 10px;
  `,
  IconWrapper: styled.div`
    cursor: pointer;
    @media screen and (max-width: 480px) {
      right: 10px;
      top: 27px;
    }
    align-self: center;
  `,
};

export default styles;
