import styled from "styled-components";

const styles = {
  CompanyCardContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 12px;
    margin-top: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,

  HeaderContainer: styled.div`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
  `,

  CompanyNameContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,

  LogoContainer: styled.div`
    border-radius: 95px;
    width: 38px;
    height: 38px;
  `,

  CompanyName: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.subHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,

  Button: styled.div`
    display: flex;
    height: 44px;
    padding: 10px 20px;
    align-items: center;
    border-radius: 50px;
    background: ${({ theme }) => theme.colors.primary.blue800};
    color: ${({ theme }) => theme.colors.neutral.white};
    font-size: ${({ theme }) => theme.fontSizes.button};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,

  Divider: styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    width: 95%;
    align-self: center;
  `,

  SectionContainer: styled.div`
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  `,
};

export default styles;
