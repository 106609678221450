import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Tools
import Styles from "../styles";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import { useToastModal } from "../../../context/ToastModalContext";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

import { CREATE_BROKER, UPDATE_BROKER, DELETE_BROKER, LIST_BROKERS } from "../../../graphql/graph";

//needed inside to dynamically set required field based on modal type
const inputFormSchema = yup.object().shape({
  brokerName: yup.string().required("Broker name is a required field."),
  brokerType: yup.string().required(" Broker Type is Required"),
  // rest required fields
  brokerURL: yup.string().when(["brokerType"], {
    is: (brokerType) => brokerType === "rest",
    then: yup.string().required("Broker URL is required"),
    otherwise: yup.string(),
  }),
  brokerURLType: yup.string().when(["brokerType"], {
    is: (brokerType) => brokerType === "rest",
    then: yup.string().required("Broker URL type is required"),
    otherwise: yup.string(),
  }),
  //mqtt required fields
  brokerChannel: yup.string().when(["brokerType"], {
    is: (brokerType) => brokerType === "mqtt",
    then: yup.string().required("Broker channel is required"),
    otherwise: yup.string(),
  }),
  brokerHost: yup.string().when(["brokerType"], {
    is: (brokerType) => brokerType === "mqtt",
    then: yup.string().required("Broker host is required"),
    otherwise: yup.string(),
  }),
  brokerPort: yup.string().when(["brokerType"], {
    is: (brokerType) => brokerType === "mqtt",
    then: yup.string().required("broker port is required"),
    otherwise: yup.string(),
  }),
});

const AddUpdateBrokerModal = ({
  showModal,
  setShowModal,
  companyId,
  brokerToUpdate,
  setBrokerToUpdate,
}) => {
  const { addToast, addModal } = useToastModal();

  const [
    updateBrokerMutation,
    { data: updateBrokerMutationResult, error: updateBrokerMutationError },
  ] = useMutation(UPDATE_BROKER, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_BROKERS,
        context: { clientName: "graph" },
        variables: {
          companyId: companyId,
        },
      },
    ],
  });

  const [deleteBrokerMutation, { data: deleteBrokerData, error: deleteBrokerError }] = useMutation(
    DELETE_BROKER,
    {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_BROKERS,
          context: { clientName: "graph" },
          variables: {
            companyId: companyId,
          },
        },
      ],
    },
  );

  const [addBrokerMutation, { data: addBrokerMutationResult, error: addBrokerMutationError }] =
    useMutation(CREATE_BROKER, {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_BROKERS,
          context: { clientName: "graph" },
          variables: {
            companyId: companyId,
          },
        },
      ],
    });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    unregister,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(inputFormSchema),
    },
    { defaultValues: brokerToUpdate },
  );

  const [userBrokerType, setUserBrokerType] = useState("");

  const watchBrokerType = watch("brokerType");

  useEffect(() => {
    if (brokerToUpdate) {
      setUserBrokerType(brokerToUpdate.brokerType);
    }
    if (watchBrokerType) {
      setUserBrokerType(watchBrokerType);
    }
  }, [brokerToUpdate, watchBrokerType]);

  useEffect(() => {
    if (deleteBrokerData) {
      addToast("Broker deleted successfully!", "success");
      handleClickCancelBtn();
    }
    if (deleteBrokerError) {
      addToast("Something went wrong! Broker did not delete", "error");
    }
    if (addBrokerMutationResult) {
      addToast("Broker created successfully!", "success");
    }
    if (addBrokerMutationResult) {
      addToast("Something went wrong! Broker did not create properly", "error");
    }
    if (updateBrokerMutationResult) {
      addToast("Broker updated successfully!", "success");
    }
    if (updateBrokerMutationError) {
      addToast("Something went wrong! Broker did not update", "error");
    }
  }, [
    updateBrokerMutationResult,
    updateBrokerMutationError,
    deleteBrokerData,
    deleteBrokerError,
    addBrokerMutationResult,
    addBrokerMutationError,
  ]);

  useEffect(() => {
    reset(brokerToUpdate);
  }, [reset, brokerToUpdate]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  const handleDeleteBroker = () => {
    addModal("Delete broker?", false, () => {
      deleteBrokerMutation({
        variables: {
          id: brokerToUpdate.id,
        },
      });
    });
  };

  //Handle submit Form, create Venue
  const submitForm = (inputForm) => {
    //check if mqtt or rest
    //check if broker to update
    if (brokerToUpdate) {
      updateBrokerMutation({
        variables: {
          id: brokerToUpdate.id,
          companyId,
          brokerURL: inputForm.brokerURL,
          brokerName: inputForm.brokerName,
          brokerURLType: inputForm.brokerURLType,
          brokerPort: inputForm.brokerPort,
          brokerHost: inputForm.brokerHost,
          brokerChannel: inputForm.brokerChannel,
          brokerUser: inputForm.brokerUser,
          brokerPassword: inputForm.brokerPassword,
          brokerSecret: inputForm.brokerSecret,
          brokerStatus: parseInt(inputForm.brokerStatus),
          brokerType: inputForm.brokerType,
        },
      });
    } else {
      addBrokerMutation({
        variables: {
          companyId,
          brokerURL: inputForm.brokerURL,
          brokerName: inputForm.brokerName,
          brokerURLType: inputForm.brokerURLType,
          brokerPort: inputForm.brokerPort,
          brokerHost: inputForm.brokerHost,
          brokerChannel: inputForm.brokerChannel,
          brokerUser: inputForm.brokerUser,
          brokerPassword: inputForm.brokerPassword,
          brokerSecret: inputForm.brokerSecret,
          brokerType: inputForm.brokerType,
          brokerStatus: parseInt(inputForm.brokerStatus),
        },
      });
    }
    reset();
    setShowModal((prev) => !prev);
    setBrokerToUpdate(null);
  };

  usePreventEnterAction(submitForm);

  const modalTitle = brokerToUpdate ? "Update Broker" : "Add Broker";

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={modalTitle}>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Broker Type</Styles.Label>
            <Styles.Select
              name="brokerType"
              id="brokerType"
              {...register("brokerType")}
              defaultValue=""
            >
              <option value="" disabled>
                Select
              </option>
              <option value="mqtt">MQTT</option>
              <option value="rest">REST</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.brokerType?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          {userBrokerType === "mqtt" && (
            <>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Name </Styles.Label>
                <Styles.Input type="text" name="brokerName" {...register("brokerName")} />
                <Styles.SubmitError>{errors.brokerName?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Host</Styles.Label>
                <Styles.Input type="text" name="brokerHost" {...register("brokerHost")} />
                <Styles.SubmitError>{errors.brokerHost?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Port</Styles.Label>
                <Styles.Input type="text" name="brokerPort" {...register("brokerPort")} />
                <Styles.SubmitError>{errors.brokerPort?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Channel</Styles.Label>
                <Styles.Input type="text" name="brokerChannel" {...register("brokerChannel")} />
                <Styles.SubmitError>{errors.brokerChannel?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker User </Styles.Label>
                <Styles.Input type="text" name="brokerUser" {...register("brokerUser")} />
                <Styles.SubmitError>{errors.brokerUser?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Password </Styles.Label>
                <Styles.Input type="text" name="brokerPassword" {...register("brokerPassword")} />
                <Styles.SubmitError>{errors.brokerPassword?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Secret </Styles.Label>
                <Styles.Input type="text" name="brokerSecret" {...register("brokerSecret")} />
                <Styles.SubmitError>{errors.brokerSecret?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
            </>
          )}
          {userBrokerType === "rest" && (
            <>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker Name </Styles.Label>
                <Styles.Input type="text" name="brokerName" {...register("brokerName")} />
                <Styles.SubmitError>{errors.brokerName?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>Broker URL </Styles.Label>
                <Styles.Input type="text" name="brokerURL" {...register("brokerURL")} />
                <Styles.SubmitError>{errors.brokerURL?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
              <Styles.InputAndErrorContainer>
                <Styles.Label>URL Type</Styles.Label>
                <Styles.Select
                  name="brokerURLType"
                  id="brokerURLType"
                  {...register("brokerURLType")}
                  defaultValue=""
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="get">Get</option>
                  <option value="post">Post</option>
                </Styles.Select>
                <Styles.SubmitError>{errors.brokerURLType?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
            </>
          )}
          <Styles.InputAndErrorContainer>
            <Styles.Label>Broker Status</Styles.Label>
            <Styles.Select
              name="brokerStatus"
              id="brokerStatus"
              {...register("brokerStatus")}
              defaultValue="1"
            >
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.brokerStatus?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              withIcon={false}
              width="138px"
              height="40px"
              onClick={handleClickCancelBtn}
            />
            <PrimaryBtn
              label={modalTitle}
              withIcon={false}
              width="138px"
              height="40px"
              type="submit"
            />
          </Styles.ButtonsContainer>
          {brokerToUpdate && (
            <Styles.ContainerRow>
              <PrimaryBtn
                label="Delete Docker"
                onClick={handleDeleteBroker}
                withIcon={false}
                width="138px"
                height="40px"
              />
            </Styles.ContainerRow>
          )}
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default AddUpdateBrokerModal;
