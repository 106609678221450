import React from "react";
// Styles
import Styled from "./Overview.styled";
// Components
import OverviewItem from "./OverviewItem/OverviewItem";

const Overview = ({ title = "Overview", items }) => {
  return (
    <Styled.OverviewContainer>
      <Styled.Title>{title}</Styled.Title>
      <Styled.ItemsContainer>
        {items.map((item, index) => (
          <OverviewItem key={index} value={item.value} label={item.label} icon={item.icon} />
        ))}
      </Styled.ItemsContainer>
    </Styled.OverviewContainer>
  );
};

export default Overview;
