import styled from "styled-components/macro";

const styles = {
  ControllersContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
  `,
  Title: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  DockerCMDLog: styled.div`
    align-items: center;
    display: flex;
    gap: 6px;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  `,
  IconContainer: styled.div`
    margin-top: 2px;
  `,
  DockerContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
  `,
};

export default styles;
