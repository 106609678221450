import styled from "styled-components/macro";

export const sharedTableStyles = {
  Table: styled.table`
    table-layout: fixed;
    width: 100%;
    overflow-y: scroll;
  `,
  TableHeader: styled.thead``,
  TableHeaderEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.grey400};
    margin-bottom: 3px;
  `,
  TableHeaderEntries: styled.th`
    width: 100%;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.field};
    padding: 1%;
    flex-basis: ${(props) => (props.narrow ? 0 : null)};
  `,
  TableRow: styled.tbody`
    width: 100%;
  `,
  TableRowEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.grey400};
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.secondary.cyan200};
    }
  `,
  TableRowEntries: styled.td`
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    text-align: ${(props) => props.align || "left"};
    flex-basis: ${(props) => (props.narrow ? 0 : null)};
    padding: 0.75%;
    cursor: pointer;
  `,
};

const sharedStyles = {
  CompanyDashboardContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    cursor: pointer;
  `,
  SubTitle: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  DockerContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  IconButton: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  Section: styled.div`
    margin-bottom: 1rem;
  `,
  TableTitle: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.subHeadline};
    margin: 0;
  `,
  Actions: styled.div`
    display: flex;
    gap: 10px;
    flex-display: flex-col;
    flex-grow: 1;
    justify-content: flex-end;
  `,
};

export default sharedStyles;
