import React, { useState } from "react";
//Tools
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
//Styles
import { Content } from "../styles/globalStyles";
//Components
import ScrollToTop from "../components/ScrollToTop";
import Navbar from "../components/Navbar";
import MobileNav from "../components/NavMobile";
import SignInPage from "../pages/SignIn";
import RequestAccountPage from "../pages/RequestAccount";
import UserProfile from "../containers/BackOffice/Settings/ProfileSettings";
import RequireAuth from "../components/RequireAuth";
import PageNotFound from "../components/PageNotFound";
import Padme from "../pages/Padme";
import VenueManagement from "../containers/BackOffice/VenueManagement";
import CompanyDashboard from "../containers/BackOffice/CompanyDashboard";
import VenueDashboard from "../containers/BackOffice/VenueManagement/VenueDashboard";
import VenueCameras from "../containers/BackOffice/VenueManagement/VenueCameras";
import VenueLicenses from "../containers/BackOffice/VenueManagement/VenueLicenses";
import VenueUsers from "../containers/BackOffice/VenueManagement/VenueUsers";
import ManageCompanies from "../containers/BackOffice/ManageCompanies";
import UserSettings from "../containers/BackOffice/Settings/UserSettings";
import CompanySettings from "../containers/BackOffice/Settings/CompanySettings";
import ModelManager from "../containers/BackOffice/Settings/ModelManager";
import Settings from "../containers/BackOffice/Settings";
import ForgotPassword from "../pages/ForgotPassword";
import ValidateInvitePage from "../pages/ValidateInvite";
import ResetPassword from "../pages/ResetPassword";
import PlayerContainer from "../containers/PlayerContainer";
import Logout from "../pages/Logout";
import AnonymousRoutes from "../components/AnonymousRoutes";
import TryModels from "../containers/TryModels";
import InferenceResult from "../pages/InferenceResult";
import { DockerControlModal } from "../containers/Modals/DockerController";
import Dashboard from "components/Dashboard/Dashboard";
import Company from "containers/Company/Company";
import Venue from "containers/Venue/Venue";

const AppRoutes = () => {
  const [mobileNavisOpen, setMobileNavisOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavisOpen(!mobileNavisOpen);
  };

  return (
    <Router>
      <Content>
        {/* <MobileNav isOpen={mobileNavisOpen} toggle={toggleMobileNav} /> */}
        {/* <Navbar toggle={toggleMobileNav} /> */}

        <ScrollToTop>
          <Routes>
            <Route path="/:modelTitle" element={<TryModels />} />
            <Route path="/demo-inference-result/:inferenceCode" element={<InferenceResult />} />
            <Route
              path="/signin"
              element={
                <AnonymousRoutes>
                  <SignInPage />
                </AnonymousRoutes>
              }
            />
            <Route
              path="/request-account"
              element={
                <AnonymousRoutes>
                  <RequestAccountPage />
                </AnonymousRoutes>
              }
            />
            <Route
              path="/talktous"
              element={
                <AnonymousRoutes>
                  <RequestAccountPage />
                </AnonymousRoutes>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AnonymousRoutes>
                  <ForgotPassword />
                </AnonymousRoutes>
              }
            />
            <Route
              path="/accept-invite/:inviteCode"
              element={
                <AnonymousRoutes>
                  <ValidateInvitePage />
                </AnonymousRoutes>
              }
            />
            <Route
              path="/reset-password/:resetPassCode"
              element={
                <AnonymousRoutes>
                  <ResetPassword />
                </AnonymousRoutes>
              }
            />
            <Route
              path="logout"
              element={
                <RequireAuth>
                  <Logout />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Padme />
                </RequireAuth>
              }
            >
              <Route index element={<Navigate to="manage-companies" replace />} />
              <Route
                path="manage-companies"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="manage-companies/:companyId"
                element={
                  <RequireAuth>
                    <Company />
                  </RequireAuth>
                }
              />
              <Route
                path="manage-companies/:companyId/:venueId/:cameraId"
                element={
                  <RequireAuth>
                    <PlayerContainer />
                  </RequireAuth>
                }
              />
              <Route
                path="manage-venues/:companyId/:venueId"
                element={
                  <RequireAuth>
                    <Venue />
                  </RequireAuth>
                }
              />
              <Route
                path="manage-venues/:companyId/:venueId/:cameraId"
                element={
                  <RequireAuth>
                    <PlayerContainer />
                  </RequireAuth>
                }
              />
              <Route
                path="settings"
                element={
                  <RequireAuth>
                    <Settings />
                  </RequireAuth>
                }
              >
                <Route
                  path="user-settings"
                  element={
                    <RequireAuth>
                      <UserSettings />
                    </RequireAuth>
                  }
                />
                <Route
                  path="company-settings"
                  element={
                    <RequireAuth>
                      <CompanySettings />
                    </RequireAuth>
                  }
                />
                <Route
                  path="model-manager"
                  element={
                    <RequireAuth>
                      <ModelManager />
                    </RequireAuth>
                  }
                />
                <Route
                  path="profile-settings"
                  element={
                    <RequireAuth>
                      <UserProfile />
                    </RequireAuth>
                  }
                />
                <Route
                  path="docker-settings"
                  element={
                    <RequireAuth>
                      <DockerControlModal />
                    </RequireAuth>
                  }
                />
              </Route>

              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
      </Content>
    </Router>
  );
};

export default AppRoutes;
