import React, { useState, useEffect } from "react";
// Styles
import Styled from "../Shared/Shared.styled";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/chevron_up.svg";
// Components
import AddUpdateApiModal from "../../Modals/AddUpdateApi/index.jsx";
import ApiTable from "./ApiTable/ApiTable";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
// Graphql
import { LIST_APIS } from "../../../graphql/graph";
// Hook
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

const ApiKeys = () => {
  const { companyId } = useParams();
  const [showTable, setShowTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requestToUpdate, setRequestToUpdate] = useState(null);

  const [getApiList, { data: apiData }] = useLazyQuery(LIST_APIS, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    if (companyId) {
      getApiList({
        variables: {
          companyId,
        },
      });
    }
  }, [companyId]);

  const handleToggleContainer = () => {
    setShowTable(!showTable);
  };

  const handleApiModal = () => {
    setShowModal(!showModal);
  };

  const onCloseModal = () => {
    setRequestToUpdate(null);
  };

  const handleUpdateItem = (item) => {
    setRequestToUpdate(item);
    setShowModal(true);
  };

  const apiList = apiData?.listApi?.data || [];

  return (
    <Styled.CompanyDashboardContainer>
      <Styled.Header>
        <Styled.IconButton onClick={handleToggleContainer}>
          <Styled.SubTitle>Api</Styled.SubTitle>
          {showTable ? <ChevronDown /> : <ChevronUp />}
        </Styled.IconButton>
      </Styled.Header>
      <Styled.DockerContainer>
        {showTable && (
          <Styled.Section>
            <Styled.Header>
              <Styled.TableTitle>Api Keys</Styled.TableTitle>
              <Styled.Actions>
                <PrimaryBtn
                  label={"Add Api Key"}
                  handleClick={handleApiModal}
                  padding="10px 20px"
                />
              </Styled.Actions>
            </Styled.Header>
            <ApiTable data={apiList} updateItem={handleUpdateItem} />
          </Styled.Section>
        )}
      </Styled.DockerContainer>
      {showModal && (
        <AddUpdateApiModal
          companyId={companyId}
          showModal={showModal}
          setShowModal={setShowModal}
          apiToUpdate={requestToUpdate}
          setApiToUpdate={setRequestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
    </Styled.CompanyDashboardContainer>
  );
};

export default ApiKeys;
