import React from "react";
import ReactDOM from "react-dom/client";
// Components
import App from "./App";
// Global styles
import { GlobalStyles } from "./styles/globalStyles";
import { theme } from "./styles/theme";
// Providers
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </ThemeProvider>,
);
