import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Tools
import Styles from "../styles";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import { useToastModal } from "../../../context/ToastModalContext";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";

import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

import { CREATE_API, UPDATE_API, LIST_APIS } from "../../../graphql/graph";

//needed inside to dynamically set required field based on modal type
const inputFormSchema = yup.object().shape({
  apiName: yup.string().required("Api name is a required field."),
});

const AddUpdateApiModal = ({ showModal, setShowModal, companyId, apiToUpdate, setApiToUpdate }) => {
  const { addToast, addModal } = useToastModal();

  const [
    updateApiMutation,
    { data: updateApiMutationResult, error: updateApiMutationError, reset: updateApiMutationReset },
  ] = useMutation(UPDATE_API, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_APIS,
        context: { clientName: "graph" },
        variables: {
          companyId,
        },
      },
    ],
  });

  const [
    addApiMutation,
    { data: addApiMutationResult, error: addApiMutationError, reset: addApiMutationReset },
  ] = useMutation(CREATE_API, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_APIS,
        context: { clientName: "graph" },
        variables: {
          companyId,
        },
      },
    ],
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    unregister,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(inputFormSchema),
    },
    {
      defaultValues: {
        apiName: apiToUpdate ? apiToUpdate.apiName : "",
        apiStatus: apiToUpdate ? apiToUpdate.apiStatus : 1,
      },
    },
  );

  useEffect(() => {
    if (addApiMutationResult) {
      addToast("Api created successfully!", "success");
    }
    if (addApiMutationError) {
      addToast("Something went wrong! Api did not create properly", "error");
      addApiMutationReset();
    }
    if (updateApiMutationResult) {
      addToast("Api updated successfully!", "success");
    }
    if (updateApiMutationError) {
      addToast("Something went wrong! Api did not update", "error");
      updateApiMutationReset();
    }
  }, [updateApiMutationResult, updateApiMutationError, addApiMutationResult, addApiMutationError]);

  useEffect(() => {
    reset(apiToUpdate);
  }, [reset, apiToUpdate]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, create Venue
  const submitForm = (inputForm) => {
    //check if mqtt or rest
    //check if broker to update
    if (apiToUpdate) {
      updateApiMutation({
        variables: {
          id: apiToUpdate.id,
          apiName: inputForm.apiName,
          apiStatus: parseInt(inputForm.apiStatus, 10),
        },
      });
    } else {
      addApiMutation({
        variables: {
          companyId,
          apiName: inputForm.apiName,
        },
      });
    }
    reset();
    setShowModal((prev) => !prev);
    setApiToUpdate(null);
  };

  usePreventEnterAction(submitForm);

  const modalTitle = apiToUpdate ? "Update Api" : "Add Api";

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={modalTitle}>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Api Key Name</Styles.Label>
            <Styles.Input type="text" name="apiName" {...register("apiName")} />
            <Styles.SubmitError>{errors.apiName?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Api Status</Styles.Label>
            <Styles.Select
              name="apiStatus"
              id="apiStatus"
              {...register("apiStatus")}
              defaultValue="1"
            >
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.apiStatus?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              handleClick={handleClickCancelBtn}
              width="138px"
              height="40px"
            />
            <PrimaryBtn type="submit" id="submit" label={modalTitle} width="138px" height="40px" />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default AddUpdateApiModal;
