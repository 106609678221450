import { createGlobalStyle } from "styled-components";
import styled, { css } from "styled-components/macro";

//Styles
import { theme } from "./theme.js";
import { Fonts } from "../styles/fonts.js";
import reactTagAutocomplete from "../styles/react-tag-autocomplete.css";

export const GlobalStyles = createGlobalStyle`
${Fonts}
${reactTagAutocomplete}

* {
    box-sizing: border-box;   
}

html {
  scroll-behavior: smooth;
}
    
:root {
    --toastify-color-success: ${theme.colors.primary.blue800};
}

body {
    font-family: ${theme.fonts.primary};
    color: ${theme.text.primary};
    background: ${theme.surfaces.background};
    margin: 0;
    padding: 0;
    
}
`;

export const Content = styled.div`
  min-height: calc(100vh);
`;

export const containerScrollbar = css`
  /* Track */
  ::-webkit-scrollbar {
    background: #e0e0e0;
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e6866e;
    border-radius: 5px;
    height: 8px;
    width: 8px;
  }
`;

export const containerGrayScrollbar = css`
  /* Track */
  ::-webkit-scrollbar {
    background: #e0e0e0;
    width: 4px;
    height: 4px;
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
    height: 4px;
    width: 4px;
  }
`;
