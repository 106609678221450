import { useState, useRef, useCallback } from "react";
import useClickOutside from "./useClickOutside";

const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState({ isVisible: false, areaId: null, position: {} });
  const contextMenuRef = useRef(null);

  const closeContextMenu = useCallback(() => {
    setContextMenu({ isVisible: false, areaId: null, position: {} });
  }, []);

  const toggleContextMenu = (event, areaId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();

    setContextMenu((prev) => {
      if (prev.isVisible && prev.areaId === areaId) {
        return { isVisible: false, areaId: null, position: {} };
      }
      return {
        isVisible: true,
        areaId,
        position: {
          top: rect.bottom + window.scrollY + "px",
          left: rect.left + window.scrollX + "px",
        },
      };
    });
  };

  useClickOutside(contextMenuRef, closeContextMenu);

  return { contextMenu, toggleContextMenu, closeContextMenu, contextMenuRef };
};

export default useContextMenu;
