import { useMemo } from "react";

const useFilteredData = (tableData, selectedFilters, searchTerm) => {
  return useMemo(() => {
    const {
      venues = [],
      areas = [],
      cameras = [],
      inferences = [],
      useCases = [],
    } = selectedFilters || {};

    return tableData.filter((camera) => {
      const isVenueMatch = !venues.length || venues.includes(camera.venueId);
      const isAreaMatch = !areas.length || areas.includes(camera.areaId);
      const isCameraMatch = !cameras.length || cameras.includes(camera.id);
      const isInferenceMatch = !inferences.length || inferences.includes(camera.inference);
      const isUseCaseMatch = !useCases.length || useCases.includes(camera.useCase);
      const isSearchTermMatch =
        !searchTerm || camera.camera.toLowerCase().includes(searchTerm.toLowerCase());

      return (
        isVenueMatch &&
        isAreaMatch &&
        isCameraMatch &&
        isInferenceMatch &&
        isUseCaseMatch &&
        isSearchTermMatch
      );
    });
  }, [tableData, selectedFilters, searchTerm]);
};

export default useFilteredData;
