import { useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { LIST_DASHBOARD } from "graphql/graph";

const useDashboardData = () => {
  const [dashboardData, setDashboardData] = useState(null);

  const {
    loading: dashboardLoading,
    error: dashboardError,
    data,
    refetch,
  } = useQuery(LIST_DASHBOARD, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    if (data) {
      setDashboardData(data.listDashboard.data);
    }
  }, [data]);

  const overviewItems = useMemo(() => {
    if (!dashboardData) return [];

    return [
      { value: dashboardData.totalCompanies, label: "Companies", icon: "venue" },
      { value: dashboardData.totalVenues, label: "Venues", icon: "venues" },
      { value: dashboardData.totalInferences, label: "Inferences", icon: "inferences" },
    ];
  }, [dashboardData]);

  return {
    dashboardData,
    dashboardLoading,
    dashboardError,
    refetch,
    overviewItems,
  };
};

export default useDashboardData;
