import React, { useState, useRef } from "react";
//Styles
import Styles from "./styles";
//Components
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
import AddVenueModal from "../../../Modals/AddUpdateVenue/AddVenue";
import UpdateVenueModal from "../../../Modals/AddUpdateVenue/UpdateVenue";
import { useToastModal } from "../../../../context/ToastModalContext";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import Icon from "components/Shared/Icon/Icon";
//Hooks
import useClickOutside from "hooks/useClickOutside";

const CompanyVenues = ({
  companyId,
  venues,
  updateVenue,
  deleteVenue,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
}) => {
  const [showUpdateVenueModal, setShowUpdateVenueModal] = useState(false);
  const [venueObj, setVenueObj] = useState({});
  const [selectedVenue, setSelectedVenue] = useState(null);
  const menuRef = useRef(null);

  const { addToast, addModal } = useToastModal();

  const handleClickUpdateVenue = (venue) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, venue.companyId, venue.id)) {
      setVenueObj({ venueName: venue.venueName, venueId: venue.id });
      setShowUpdateVenueModal((prev) => !prev);
    } else {
      addToast("You must be a Venue Admin to update Venue!", "error");
    }
  };

  const handleClickDeleteVenue = (venue) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, venue.companyId, venue.id)) {
      addModal(`Delete ${venue.venueName}?`, false, () => {
        deleteVenue({
          variables: {
            id: venue.id,
          },
        });
      });
    } else {
      addToast("You must be a Venue Admin to delete Venue!", "error");
    }
  };

  const handleToggleMenu = (venue) => {
    setSelectedVenue((prev) => (prev === venue ? null : venue));
  };

  useClickOutside(menuRef, () => setSelectedVenue(null));

  const renderMenuCard = (venue) => {
    return (
      <Styles.VenueMenuCard ref={menuRef}>
        <Styles.MenuItemsContainer>
          <Styles.MenuItem onClick={() => handleClickUpdateVenue(venue)}>
            <Icon name="edit" size="18px" />
            <span>Edit</span>
          </Styles.MenuItem>
          <Styles.MenuItem onClick={() => handleClickDeleteVenue(venue)}>
            <Icon name="delete" size="18px" />
            <span>Delete</span>
          </Styles.MenuItem>
        </Styles.MenuItemsContainer>
      </Styles.VenueMenuCard>
    );
  };

  return (
    <Styles.VenuesContainer>
      <Styles.VenuesHeaderContainer>
        <Styles.VenuesHeader>Venues</Styles.VenuesHeader>
      </Styles.VenuesHeaderContainer>
      {Array.isArray(venues) &&
        venues
          .slice()
          .sort((a, b) => (a.venueName > b.venueName ? 1 : -1))
          .map((venue, i) => (
            <Styles.VenueRowContainer key={venue.id}>
              <Styles.VenuesRow lastRow={venues.length - 1 === i}>
                {!venue.venueVoid ? (
                  <Styles.VenueName>{venue.venueName}</Styles.VenueName>
                ) : (
                  <Styles.VenueName>
                    {venue.venueName}{" "}
                    <span
                      style={{
                        fontSize: "10px",
                        fontFamily: "PoppinsRegular",
                        color: "#d22f2f",
                      }}
                    >
                      (deleted)
                    </span>
                  </Styles.VenueName>
                )}
                <Styles.MenuWrapper onClick={() => handleToggleMenu(venue)}>
                  {selectedVenue === venue && renderMenuCard(venue)}
                  <Icon name="menu" size="11.667px" />
                </Styles.MenuWrapper>
              </Styles.VenuesRow>
            </Styles.VenueRowContainer>
          ))}
      <UpdateVenueModal
        showModal={showUpdateVenueModal}
        setShowModal={setShowUpdateVenueModal}
        updateVenue={updateVenue}
        companyId={companyId}
        venueObj={venueObj}
        title="Update Venue"
      />
    </Styles.VenuesContainer>
  );
};

export default CompanyVenues;
