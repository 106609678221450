import styled from "styled-components";
import { Link } from "react-router-dom";

const styles = {
  SubLinkContainer: styled(Link)`
    text-decoration: none;
    border-radius: 5px;
  `,
  SubLinkLabel: styled(({ isSelected, ...props }) => <span {...props} />)`
    color: ${({ theme }) => theme.text.primary};
    font-size: 14px;
    font-weight: ${({ isSelected, theme }) =>
      isSelected ? theme.fonts.weights.bold : theme.fonts.weights.normal};

    &:hover {
      color: ${({ theme }) => theme.buttons.primaryHover};
    }
  `,
};

export default styles;
