import React, { useEffect, useState } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useParams } from "react-router-dom";
//Helpers
import { defineCurrentModelObj } from "../../configs/helpers";
//Components
import TryModelForm from "../../components/TryModelForm";
import InferenceEmailModal from "../Modals/InferenceEmail";
import TryModelCard from "../../components/TryModelCard";
import { useToastModal } from "../../context/ToastModalContext";
//Graphql
import { useMutation } from "@apollo/client";
import { CREATE_INFERENCE } from "../../graphql/graph";
//Data
import { machineLearningModels } from "../../configs/data";
//Swiper
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
SwiperCore.use([Pagination]);

const TryModels = () => {
  const { modelTitle } = useParams();
  const [userUrl, setUserUrl] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [currentModelObj, setCurrentModelObj] = useState({});
  const { addToast } = useToastModal();

  const [
    createInferenceMutation,
    {
      data: createInferenceData,
      //TODO Handle graphql errors and loading
      /* loading: createInferenceLoading, */
      /* error: updateNameGraphqlError, */
    },
  ] = useMutation(CREATE_INFERENCE, {
    context: { clientName: "user" },
  });

  useEffect(() => {
    setCurrentModelObj(defineCurrentModelObj(modelTitle));
  }, [modelTitle]);

  useEffect(() => {
    setDefaultImageUrl(currentModelObj.defaultImageUrl1);
  }, [currentModelObj]);

  useEffect(() => {
    if (createInferenceData) {
      addToast("Image uploaded, please check your email!", "success");
    }
  }, [createInferenceData]);

  const handleClickImage1 = () => {
    if (defaultImageUrl !== currentModelObj.defaultImageUrl1) {
      setDefaultImageUrl(currentModelObj.defaultImageUrl1);
    }
  };
  const handleClickImage2 = () => {
    if (defaultImageUrl !== currentModelObj.defaultImageUrl2) {
      setDefaultImageUrl(currentModelObj.defaultImageUrl2);
    }
  };

  return (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        <Styles.SectionsContainer>
          <Styles.UploadImageSection>
            <Styles.TitleAndDescription>
              <Styles.ModelTitle>{currentModelObj.title}</Styles.ModelTitle>
              <Styles.ModelDescription>{currentModelObj.description}</Styles.ModelDescription>
            </Styles.TitleAndDescription>

            <Styles.ImagesAndForm>
              <Styles.PreviwImageContainer>
                <Styles.ImagePreview src={defaultImageUrl} alt="cars" />
              </Styles.PreviwImageContainer>
              <Styles.UploadImageContainer>
                <Styles.ChooseImageText>Choose a sample image.</Styles.ChooseImageText>
                <Styles.ImagesThumbNailContainer>
                  <Styles.ImageThumbNail
                    src={currentModelObj.defaultImageUrl1}
                    alt={currentModelObj.defaultImage1Alt}
                    onClick={handleClickImage1}
                    selected={defaultImageUrl === currentModelObj.defaultImageUrl1 ? true : false}
                  />
                  <Styles.ImageThumbNail
                    src={currentModelObj.defaultImageUrl2}
                    alt={currentModelObj.defaultImage2Alt}
                    onClick={handleClickImage2}
                    selected={defaultImageUrl === currentModelObj.defaultImageUrl2 ? true : false}
                  />
                </Styles.ImagesThumbNailContainer>
                <Styles.ChooseImageText style={{ alignSelf: "center" }}>
                  OR...
                </Styles.ChooseImageText>
                <Styles.ChooseImageText>
                  Choose your own image. Make sure that the image can be downloaded using the url.
                  If no image is chosen, the pre-selected one will be used.
                </Styles.ChooseImageText>
                <TryModelForm setUserUrl={setUserUrl} setShowModal={setShowModal} />
              </Styles.UploadImageContainer>
            </Styles.ImagesAndForm>
          </Styles.UploadImageSection>

          {/* <Styles.TryOtherModelsSection>
            <Styles.TryOtheresTitle>Try other models.</Styles.TryOtheresTitle>
            <Styles.SwiperContainer>
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                pagination={true}
                breakpoints={{
                  // when window width is >= 640px
                  0: {
                    slidesPerView: 1,
                  },
                  480: {
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 3,
                  },
                }}
              >
                {machineLearningModels.map(
                  (model) =>
                    model.title !== currentModelObj.title && (
                      <SwiperSlide key={model.key}>
                        <TryModelCard
                          title={model.title}
                          description={model.demoCardDescription}
                          modelUrl={model.demoModelUrl}
                        />
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            </Styles.SwiperContainer>
          </Styles.TryOtherModelsSection> */}
        </Styles.SectionsContainer>
        <InferenceEmailModal
          showModal={showModal}
          setShowModal={setShowModal}
          modelTitle={modelTitle}
          createInferenceMutation={createInferenceMutation}
          userUrl={userUrl}
          defaultImageUrl={defaultImageUrl}
        />
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  );
};

export default TryModels;
