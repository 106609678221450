import styled from "styled-components";

const styles = {
  ItemContainer: styled.div`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,

  IconContainer: styled.div`
    display: flex;
    padding: 12px;
    border-radius: 1000px;
    background: ${({ theme }) => theme.surfaces.background};
  `,

  OverviewValue: styled.div`
    font-size: 24px;
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  `,

  OverviewLabel: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
  `,
};

export default styles;
