import React, { useEffect, useState } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//Graphql
import { useMutation } from "@apollo/client";
import { PROCESS_INFERENCE } from "../../graphql/user";
//Components
import SpinnerComponent from "../../components/Loader";
import BlueBtn from "../../components//Shared/Buttons/BlueBtn";

const InferenceResult = () => {
  const { inferenceCode } = useParams();
  const [resultUrl, setResultUrl] = useState(undefined);
  const navigate = useNavigate();

  const [
    processInferenceMutation,
    {
      data: processInferenceData,
      loading: processInferenceLoading,
      error: processInferenceError, //TODO Handle graphql error
    },
  ] = useMutation(PROCESS_INFERENCE, {
    context: { clientName: "user" },
  });

  const handleClickBackBtn = () => {
    navigate("/");
  };

  useEffect(() => {
    processInferenceMutation({ variables: { inferenceCode: inferenceCode } });
  }, [inferenceCode, processInferenceMutation]);

  useEffect(() => {
    if (processInferenceData) {
      // console.log(
      //   "processInferenceData.data: ",
      //   processInferenceData.processInference.result.resultUrl,
      // );
      window.open(processInferenceData.processInference.result.resultUrl, "_self");
    }
  }, [processInferenceData]);

  return (
    <Styles.SectionContainer>
      <Styles.AlignmentContainer>
        {processInferenceLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>Processing Image</h1>
            <SpinnerComponent width={100} height={100} />
          </div>
        ) : (
          <Styles.ErrorAndBackContainer>
            {processInferenceError && (
              <Styles.ErrorMessage>
                Sorry, something went wrong. Please try another model.
              </Styles.ErrorMessage>
            )}
            <BlueBtn handleClick={handleClickBackBtn} label="Back to Models" />
          </Styles.ErrorAndBackContainer>
        )}
      </Styles.AlignmentContainer>
    </Styles.SectionContainer>
  );
};

export default InferenceResult;
