import { gql } from "@apollo/client";

export const RENEW_TOKEN = gql`
  query renewToken($refreshToken: String!) {
    renewToken(refreshToken: $refreshToken) {
      token
      refreshToken
      isSuperAdmin
      expiresIn
      user {
        id
        userFirstName
        userLastName
        userEmail
        userPhone
        userStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const SIGN_IN = gql`
  query signIn($email: String, $password: String) {
    login(email: $email, password: $password) {
      token
      refreshToken
      isSuperAdmin
      expiresIn
      user {
        id
        userFirstName
        userLastName
        userEmail
        userPhone
        userStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation register($firstName: String, $lastName: String, $email: String, $password: String) {
    register(
      attributes: { firstName: $firstName, lastName: $lastName, email: $email, password: $password }
      group_name: "lotuslabs.ai"
    ) {
      data {
        id
        attributes {
          fullName
          firstName
          lastName
          email
          metadata {
            status
            role
          }
          created
          updated
        }
        relationships {
          group {
            data {
              type
              id
            }
          }
        }
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      resetCode
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const VALIDATE_INVITE_CODE = gql`
  mutation checkInvite($inviteCode: String!) {
    checkInvite(inviteCode: $inviteCode) {
      inviteValid
      companyName
      userEmail
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const COMPLETE_INVITE = gql`
  mutation completeRegister(
    $inviteCode: String!
    $userFirstName: String
    $userLastName: String
    $userPhone: String
    $userPassword: String
  ) {
    completeRegister(
      attributes: {
        inviteCode: $inviteCode
        userFirstName: $userFirstName
        userLastName: $userLastName
        userPhone: $userPhone
        userPassword: $userPassword
      }
    ) {
      data {
        id
        userFirstName
        userLastName
        userEmail
        userPhone
        userStatus
        createdAt
        updatedAt
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($resetCode: String!, $password: String!) {
    forgotPasswordComplete(resetCode: $resetCode, password: $password) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD_CHECK = gql`
  query ($resetCode: String!) {
    forgotPasswordCheck(resetCode: $resetCode) {
      resetValid
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const TALK_TO_US = gql`
  mutation talkToUs(
    $requestName: String
    $requestEmail: String
    $requestCompanyName: String
    $requestNotes: String
  ) {
    talkToUs(
      attributes: {
        requestName: $requestName
        requestEmail: $requestEmail
        requestCompanyName: $requestCompanyName
        requestNotes: $requestNotes
      }
    ) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const REQUEST_ACCOUNT = gql`
  mutation requestAccount(
    $requestFirstName: String
    $requestLastName: String
    $requestEmail: String
    $requestPhoneNumber: String
    $requestCompanyName: String
    $requestNotes: String
  ) {
    requestAccount(
      attributes: {
        requestFirstName: $requestFirstName
        requestLastName: $requestLastName
        requestEmail: $requestEmail
        requestPhoneNumber: $requestPhoneNumber
        requestCompanyName: $requestCompanyName
        requestNotes: $requestNotes
      }
    ) {
      message
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

//DEMO
export const CREATE_INFERENCE = gql`
  mutation createInference($userEmail: String!, $userUrl: String!, $inferenceModel: String!) {
    createInference(
      attributes: { userEmail: $userEmail, userUrl: $userUrl, inferenceModel: $inferenceModel }
    ) {
      data {
        id
        inferenceCode
        inferenceType
        inferenceModel
        inferenceStatus
        inferenceStartDate
        inferenceEndDate
        inferenceResult
        userEmail
        userUrl
        createdAt
        updatedAt
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;

export const PROCESS_INFERENCE = gql`
  mutation ($inferenceCode: ID!) {
    processInference(inferenceCode: $inferenceCode) {
      result {
        inferenceCode
        resultUrl
      }
      errors {
        title
        detail
        code
        source {
          pointer
          parameter
        }
      }
    }
  }
`;
