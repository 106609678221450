import { useState, useEffect, useCallback, useMemo } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { LIST_VENUES, RETRIEVE_COMPANY } from "graphql/graph";

const useCompanyData = (companyId) => {
  const [companyData, setCompanyData] = useState(null);
  const [venueIds, setVenueIds] = useState([]);

  const {
    data: retrieveCompanyData,
    loading: retrieveCompanyLoading,
    error: retrieveCompanyError,
    refetch: refetchCompany,
  } = useQuery(RETRIEVE_COMPANY, {
    context: { clientName: "graph" },
    variables: { id: companyId },
  });

  const [
    fetchVenues,
    { data: listVenuesData, loading: listVenuesLoading, error: listVenuesError },
  ] = useLazyQuery(LIST_VENUES, {
    context: { clientName: "graph" },
    variables: { venueIds },
  });

  const updateCompanyData = useCallback((data) => {
    const company = data.retrieveCompany.data;
    setCompanyData(company);
    setVenueIds(company.Venues.map((venue) => venue.id));
  }, []);

  useEffect(() => {
    if (retrieveCompanyData) {
      updateCompanyData(retrieveCompanyData);
    }
  }, [retrieveCompanyData, updateCompanyData]);

  useEffect(() => {
    if (venueIds.length > 0) {
      fetchVenues();
    }
  }, [venueIds, fetchVenues]);

  const overviewItems = useMemo(() => {
    if (!companyData) return [];

    const { venueCount, machineCount, inferenceCount } = companyData;
    return [
      { value: venueCount, label: "Venues", icon: "venues" },
      { value: machineCount, label: "Cameras", icon: "cameras" },
      { value: inferenceCount, label: "Inferences", icon: "inferences" },
    ];
  }, [companyData]);

  return {
    companyData,
    venueIds,
    overviewItems,
    venueDetails: listVenuesData?.listVenues?.data || [],
    loading: retrieveCompanyLoading || listVenuesLoading,
    error: retrieveCompanyError || listVenuesError,
    refetchCompany,
  };
};

export default useCompanyData;
