import { Link } from "react-router-dom";
import styled from "styled-components";
import { LinkIcon, LinkLabel, sharedLinkStyles } from "../Shared/Shared.styled";

const styles = {
  LinkContainer: styled(({ active, ...props }) => <Link {...props} />)`
    ${sharedLinkStyles}
  `,
  LinkIcon,
  LinkLabel,
};

export default styles;
