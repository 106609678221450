import React, { useState, useContext } from "react";
//Styles
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Styled from "./Brokers.styled";

import BrokerCard from "../../../components/Shared/BrokerCard";
import AddUpdateBrokerModal from "../../Modals/AddUpdateBroker";
import { useToastModal } from "../../../context/ToastModalContext";
import { checkUserPermissionIsAdmin } from "../../../configs/helpers";
import { AuthContext } from "../../../context/authContext";
import { LIST_BROKERS } from "../../../graphql/graph";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";

const Brokers = () => {
  const { companyId } = useParams();
  const { addToast } = useToastModal();
  const { loggedUserAttributes } = useContext(AuthContext);
  const [brokerToUpdate, setBrokerToUpdate] = useState(null);
  const [showAddBrokerModal, setShowAddBrokerModal] = useState(false);

  const { data: brokersData } = useQuery(LIST_BROKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const brokersList = brokersData?.listBroker?.data || [];

  const handleClickAddUpdateBroker = (broker) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      setBrokerToUpdate(broker);
      setShowAddBrokerModal((prev) => !prev);
    } else {
      addToast("You must be a Venue Admin to add or update Broker!", "error");
    }
  };

  const renderBrokers = () => {
    if (!brokersList.length) {
      return <BrokerCard title="No Brokers" />;
    }
    return brokersList.map((broker) => {
      return (
        <BrokerCard
          key={broker.id}
          title={broker.brokerName}
          updateBroker={() => handleClickAddUpdateBroker(broker)}
        />
      );
    });
  };

  return (
    <Styled.BrokersContainer>
      <Styled.Header>
        <Styled.Title>Brokers</Styled.Title>
      </Styled.Header>
      <Styled.DockerContainer>
        <PrimaryBtn
          label={"Add Broker"}
          handleClick={() => handleClickAddUpdateBroker(null)}
          icon={"plus"}
          iconSize={"20px"}
        />
        {renderBrokers()}
      </Styled.DockerContainer>
      {showAddBrokerModal && (
        <AddUpdateBrokerModal
          showModal={showAddBrokerModal}
          setShowModal={setShowAddBrokerModal}
          setBrokerToUpdate={setBrokerToUpdate}
          brokerToUpdate={brokerToUpdate}
          companyId={companyId}
        />
      )}
    </Styled.BrokersContainer>
  );
};

export default Brokers;
