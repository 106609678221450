import React, { useEffect } from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

import { UPDATE_AREA } from "../../../graphql/graph";

const updateAreaSchema = yup.object().shape({
  areaName: yup.string().required("Area name is a required field."),
});

const UpdateAreaModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  areaNameObj,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateAreaSchema),
    },
    { defaultValues: areaNameObj },
  );

  const [updateArea] = useMutation(UPDATE_AREA, {
    context: { clientName: "graph" },
  });

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(areaNameObj);
  }, [reset, areaNameObj]);

  //Handle submit Form, update Area
  const submitForm = (updateAreaFormData) => {
    updateArea({
      variables: {
        id: areaNameObj.id,
        areaName: updateAreaFormData.areaName,
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        handleModalForwardClick={handleModalForwardClick}
      >
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Name</Styles.Label>
            <Styles.Input
              type="text"
              name="areaName"
              /* placeholder="Area name ..." */
              {...register("areaName")}
            />
            <Styles.SubmitError>{errors.areaName?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Cancel"
              handleClick={handleClickCancelBtn}
            />
            <PrimaryBtn type="submit" id="submit" value="Update" label={"Update"} />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default UpdateAreaModal;
