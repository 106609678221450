import React from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const inviteUserSchema = yup.object().shape({
  userEmail: yup
    .string()
    .email("Please type a valid email.")
    .required("Email is a required field."),
});

const InferenceEmailModal = ({
  showModal,
  setShowModal,
  modelTitle,
  createInferenceMutation,
  userUrl,
  defaultImageUrl,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inviteUserSchema),
  });

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, update Area
  const submitForm = (inferenceEmailFormData) => {
    createInferenceMutation({
      variables: {
        userEmail: inferenceEmailFormData.userEmail,
        userUrl: userUrl ? userUrl : defaultImageUrl,
        inferenceModel: "CAR_COUNT",
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren
        showModal={showModal}
        setShowModal={setShowModal}
        title="To try this model, please enter your email so we can send you the link where the model inference will run."
        maxWidth50={true}
      >
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Email</Styles.Label>
            <Styles.Input
              type="text"
              name="userEmail"
              placeholder="Email ..."
              {...register("userEmail")}
            />
            <Styles.SubmitError>{errors.userEmail?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Cancel"
              handleClick={handleClickCancelBtn}
            />
            <Styles.SubmitInput type="submit" id="submit" value="Send" />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default InferenceEmailModal;
