import styled from "styled-components";

const styles = {
  HeaderContainer: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 16px;
    gap: 8px;
  `,
};

export default styles;
