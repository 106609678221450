import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation } from "@apollo/client";
//Tools
//Components
import Styles from "../styles";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import { useToastModal } from "../../../context/ToastModalContext";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

import { rtspDomain } from "../../../configs/configEnviroment";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

import {
  CREATE_DOCKER,
  DELETE_DOCKER,
  LIST_DOCKERS,
  UPDATE_DOCKER,
  LIST_BROKERS,
} from "../../../graphql/graph";

//needed inside to dynamically set required field based on modal type
const inputFormSchema = yup.object().shape({
  dockerName: yup.string().required("Docker name is a required field."),
});

const AddUpdateDockerModal = ({
  showModal,
  setShowModal,
  venues = [],
  dockerToUpdate,
  setDockerToUpdate,
  companyId,
}) => {
  const { addToast, addModal } = useToastModal();

  const { data: brokersData } = useQuery(LIST_BROKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const [addDockerMutation, { data: addDockerData, error: addDockerError }] = useMutation(
    CREATE_DOCKER,
    {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_DOCKERS,
          context: { clientName: "graph" },
          variables: {
            companyId,
          },
        },
      ],
    },
  );

  const [deleteDockerMutation, { data: deleteDockerData, error: deleteDockerError }] = useMutation(
    DELETE_DOCKER,
    {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_DOCKERS,
          context: { clientName: "graph" },
          variables: {
            companyId,
          },
        },
      ],
    },
  );

  const [updateDockerMutation, { data: updateDockerData, error: updateDockerError }] = useMutation(
    UPDATE_DOCKER,
    {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_DOCKERS,
          context: { clientName: "graph" },
          variables: {
            companyId,
          },
        },
      ],
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    unregister,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(inputFormSchema),
    },
    { defaultValues: dockerToUpdate },
  );

  useEffect(() => {
    reset(dockerToUpdate);
  }, [reset, dockerToUpdate]);

  useEffect(() => {
    if (deleteDockerData) {
      addToast("Docker deleted successfully!", "success");
      handleClickCancelBtn();
    }
    if (deleteDockerError) {
      addToast("Something went wrong! Docker did not delete", "error");
    }
    if (addDockerData) {
      addToast("Docker created successfully!", "success");
    }
    if (addDockerError) {
      addToast("Something went wrong! Docker did not create properly", "error");
    }
    if (updateDockerData) {
      addToast("Docker updated successfully!", "success");
    }
    if (updateDockerError) {
      addToast("Something went wrong! Docker did not update", "error");
    }
  }, [
    deleteDockerData,
    deleteDockerError,
    addDockerData,
    addDockerError,
    updateDockerData,
    updateDockerError,
  ]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  const handleDeleteDocker = () => {
    addModal("Delete docker?", false, () => {
      deleteDockerMutation({
        variables: {
          id: dockerToUpdate.id,
        },
      });
    });
  };

  //Handle submit Form, create Venue
  const submitForm = (inputForm) => {
    if (dockerToUpdate) {
      updateDockerMutation({
        variables: {
          id: dockerToUpdate.id,
          venueId: inputForm.venueId || null,
          dockerName: inputForm.dockerName,
          dockerCustomId: inputForm.dockerCustomId,
          dockerNginxIP: inputForm.dockerNginxIP,
          dockerStatus: parseInt(inputForm.dockerStatus),
          dockerInferencePerTask: 1,
          dockerTaskCount: 1,
          dockerAutoRestart: 1,
          dockerAutoScale: 1,
          brokerId: inputForm.brokerId === "" ? null : inputForm.brokerId,
        },
      });
    } else {
      addDockerMutation({
        variables: {
          companyId,
          venueId: inputForm.venueId || null,
          dockerName: inputForm.dockerName,
          dockerCustomId: inputForm.dockerCustomId,
          dockerNginxIP: inputForm.dockerNginxIP,
          dockerStatus: parseInt(inputForm.dockerStatus),
          dockerInferencePerTask: 1,
          dockerTaskCount: 1,
          dockerAutoRestart: 1,
          dockerAutoScale: 1,
          brokerId: inputForm.brokerId === "" ? null : inputForm.brokerId,
        },
      });
    }
    reset();
    setShowModal((prev) => !prev);
    setDockerToUpdate(null);
  };

  usePreventEnterAction(submitForm);

  const modalTitle = dockerToUpdate ? "Update Docker" : "Add Docker";

  const brokers = brokersData?.listBroker?.data || [];

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={modalTitle}>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>NGINX IP</Styles.Label>
            <Styles.Input type="text" name="dockerNginxIP" {...register("dockerNginxIP")} />
            <Styles.SubmitError>{errors.dockerNginxIP?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Name</Styles.Label>
            <Styles.Input type="text" name="dockerName" {...register("dockerName")} />
            <Styles.SubmitError>{errors.dockerName?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Assign to Venue</Styles.Label>
            <Styles.Select name="venueId" id="venue" {...register("venueId")} defaultValue="">
              <option value="">Not assigned</option>
              {venues.map((venue) => (
                <option value={venue.id} key={venue.id}>
                  {venue.venueName}
                </option>
              ))}
            </Styles.Select>
            <Styles.SubmitError>{errors.venueId?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          {/* list broker list in the options */}
          <Styles.InputAndErrorContainer>
            <Styles.Label>Broker </Styles.Label>
            <Styles.Select name="brokerId" id="brokerId" {...register("brokerId")} defaultValue="">
              <option value="">Not assigned</option>
              {brokers?.map((broker) => (
                <option value={broker.id} key={broker.id}>
                  {broker.brokerName}
                </option>
              ))}
            </Styles.Select>
            <Styles.SubmitError>{errors.venueId?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Docker Status</Styles.Label>
            <Styles.Select
              name="dockerStatus"
              id="dockerStatus"
              {...register("dockerStatus")}
              defaultValue=""
            >
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.dockerStatus?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              withIcon={false}
              width="138px"
              height="40px"
              onClick={handleClickCancelBtn}
            />
            <PrimaryBtn
              label={modalTitle}
              withIcon={false}
              width="138px"
              height="40px"
              type="submit"
            />
          </Styles.ButtonsContainer>
          {dockerToUpdate && (
            <Styles.ContainerRow>
              <Styles.Row>docker key: {dockerToUpdate.id}</Styles.Row>
              <Styles.Row>
                docker url: https://{dockerToUpdate.dockerURL}
                {rtspDomain}
              </Styles.Row>
              <PrimaryBtn
                label="Delete Docker"
                onClick={handleDeleteDocker}
                withIcon={false}
                width="138px"
                height="40px"
              />
            </Styles.ContainerRow>
          )}
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default AddUpdateDockerModal;
