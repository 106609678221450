import styled from "styled-components";

const styles = {
  NetworkContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
  `,
  Title: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  TableHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};

export default styles;
