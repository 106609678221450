import React, { useState, useEffect } from "react";
//Styles
import Styles from "../Auth/styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
/* import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"; */
//Components
import { FormGroup } from "../Shared/FormGroup";
import SpinnerComponent from "../Loader";
//GraphQL
import { FORGOT_PASSWORD } from "../../graphql/user";
import { useMutation } from "@apollo/client";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";
import { useToastModal } from "../../context/ToastModalContext";

const forgotPassSchema = yup.object().shape({
  email: yup.string().email().required("Email is a required field."),
});

const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPassSchema),
  });
  const [forgotPassErrors /* , setForgotPassErrors */] = useState("");
  /* const [forgotPassDataSuccess, setForgotPassDataSuccess] = useState(false); */

  const [
    forgotPasswordMutation,
    { data: forgotPassData, loading: forgotPassLoading, error: forgotPassError }, //TODO Handle graphql errors and loading
  ] = useMutation(FORGOT_PASSWORD, { context: { clientName: "user" } });

  const submitForm = (forgotPassFormData) => {
    forgotPasswordMutation({
      variables: {
        email: forgotPassFormData.email,
      },
    });
  };

  usePreventEnterAction(submitForm);

  const { addToast } = useToastModal();

  useEffect(() => {
    if (forgotPassData) {
      /* setForgotPassDataSuccess(true); */
      addToast("Forgot password email sent successfully!", "success");
      reset();
      return;
    }
    if (forgotPassError) {
      addToast("Something went wrong, please try again or contact our support!", "error");
      reset();
      return;
    }
  }, [forgotPassData, forgotPassError, reset]);

  return (
    <Styles.FormContainer>
      <Styles.Title>Forgot Password</Styles.Title>
      <Styles.Paragraph>You will receive an email to reset your password!</Styles.Paragraph>

      <Styles.Form onSubmit={handleSubmit(submitForm)}>
        <FormGroup labelText={"Enter your email address"} errorMessage={errors.email?.message}>
          <Styles.Input type="text" name="email" {...register("email")} />
        </FormGroup>
        {/* {forgotPassDataSuccess ? (
          <Styles.SubmitSuccess>
            Email sent successfully!
          </Styles.SubmitSuccess>
        ) :  */}
        {forgotPassErrors ? (
          <Styles.SubmitServerError>{forgotPassErrors}</Styles.SubmitServerError>
        ) : null}
        {forgotPassLoading ? (
          <SpinnerComponent />
        ) : (
          <Styles.SubmitInput type="submit" id="submit" value="Submit" />
        )}
      </Styles.Form>
      <Styles.Paragraph backtosignin>
        Back to <Link to="/signin">Sign in!</Link>
      </Styles.Paragraph>
    </Styles.FormContainer>
  );
};

export default ForgotPasswordForm;
