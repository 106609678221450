import styled from "styled-components";
import Icon from "components/Shared/Icon/Icon";

const styles = {
  CheckboxContainer: styled.div`
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
  `,
  StyledCheckbox: styled.input.attrs({ type: "checkbox" })`
    width: 18px;
    height: 18px;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.neutral.white};
    border: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    appearance: none;
    transition: background-color 0.3s;

    &:checked {
      background-color: ${({ theme }) => theme.buttons.primary};
    }

    &:hover:not(:disabled) {
      border: 1px solid ${({ theme }) => theme.colors.primary.blue500};
    }

    &:checked:hover:not(:disabled) {
      background-color: ${({ theme }) => theme.buttons.primaryHover};
    }

    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.neutral.grey500};
    }

    &:checked:disabled {
      background-color: ${({ theme }) => theme.colors.primary.blue500};
    }
  `,
  CheckmarkIcon: styled(Icon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
      path {
        stroke: ${({ theme }) => theme.colors.neutral.white} !important;
      }
    }
  `,
};

export default styles;
