import styled, { css } from "styled-components";

const styles = {
  Container: styled.div`
    border-radius: 50%;
    background: ${({ image }) => `url(${image})`};
    background-size: cover;

    ${({ size, theme }) => {
      if (size) {
        return css`
          height: ${size}px;
          width: ${size}px;
          border: 2px solid ${theme.colors.primary.blue800};
        `;
      }
      return css`
        height: 50px;
        width: 50px;
        border: 2px solid ${theme.colors.primary.blue800};
      `;
    }}
  `,
};

export default styles;
