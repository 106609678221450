import React, { useState, useEffect, useContext } from "react";
//Styles
import Styles from "./styles";
//Components
import CompanyRow from "./CompanyRow";
import AddCompanyModal from "../../../Modals/AddUpdateCompany/AddCompany";
import SpinnerComponent from "../../../../components/Loader";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
//GraphQl
import { useQuery, useMutation } from "@apollo/client/react";
import {
  LIST_COMPANY,
  CREATE_VENUE,
  UPDATE_VENUE,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_VENUE,
} from "../../../../graphql/graph";
//Context
import { AuthContext } from "../../../../context/authContext";
import { useToastModal } from "../../../../context/ToastModalContext";
//helpers
import { checkUserPermissionIsAdmin } from "../../../../configs/helpers";

const CompanySettings = () => {
  const [companiesStateData, setCompaniesStateData] = useState([]);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const { isSuperAdmin, loggedUserAttributes } = useContext(AuthContext);
  const { addToast } = useToastModal();

  const {
    data: companiesData,
    loading: companiesLoading,
    /* error: companiesError, */ //TODO Handle graphql error
  } = useQuery(LIST_COMPANY, {
    context: { clientName: "graph" },
  });

  const [
    createCompany,
    //TODO: Handle GraphQl errors (if needed)
    {
      data: createCompanyData,
      /* loading: createCompanyLoading, */
      error: createCompanyError,
    },
  ] = useMutation(CREATE_COMPANY, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COMPANY,
        context: { clientName: "graph" },
      },
    ],
  });

  const [
    updateCompany,
    //TODO: Handle GraphQl data+errors+loading (if needed)
    {
      data: updateCompanyData,
      /* loading: updateCompanLoading,
      error: updateCompanylError, */
    },
  ] = useMutation(UPDATE_COMPANY, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COMPANY,
        context: { clientName: "graph" },
      },
    ],
  });

  const [
    createVenue,
    //TODO: Handle GraphQl data+errors+loading (if needed)
    {
      data: createVenueData,
      /* loading: createVenueLoading,
      error: updateNameGraphqlError, */
    },
  ] = useMutation(CREATE_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COMPANY,
        context: { clientName: "graph" },
      },
    ],
  });

  const [
    updateVenue,
    //TODO: Handle GraphQl data+errors+loading (if needed)
    {
      data: updateVenueData,
      /* loading: updateVenueLoading,
      error: updateVenuelError, */
    },
  ] = useMutation(UPDATE_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COMPANY,
        context: { clientName: "graph" },
      },
    ],
  });

  const [deleteVenue, { data: deleteVenueData, error: deleteVenueError }] = useMutation(
    DELETE_VENUE,
    {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_COMPANY,
          context: { clientName: "graph" },
        },
      ],
    },
  );

  useEffect(() => {
    if (companiesData) {
      setCompaniesStateData(companiesData.listCompany.data);
    }
  }, [companiesData]);

  const handleClickAddCompany = () => {
    setShowAddCompanyModal((prev) => !prev);
  };

  useEffect(() => {
    if (deleteVenueData) {
      addToast("Venue deleted successfully !!!", "success");
    } else if (deleteVenueError) {
      addToast("Venue deletion unsuccessful !!!", "error");
    }
  }, [deleteVenueData, deleteVenueError]);

  //Toast Update
  useEffect(() => {
    if (updateCompanyData || updateVenueData) {
      addToast("Successfully updated!", "success");
    }
  }, [updateCompanyData, updateVenueData]);

  //Toast Create
  useEffect(() => {
    if (createCompanyData || createVenueData) {
      addToast("Successfully added!", "success");
    }
  }, [createCompanyData, createVenueData]);

  //Tast Error
  useEffect(() => {
    if (createCompanyError) {
      if (createCompanyError.graphQLErrors[0].message === "Not authorized") {
        addToast("Sorry, you don't have permission to add a new company!", "error");
      } else {
        addToast("Sorry, something went wrong!", "error");
      }
    }
  }, [createCompanyError]);

  // //toast success/error delete venue
  // useEffect(() => {
  //   if (deleteVenueError) {
  //     addToast("Venue deletion unsuccessful !!!", "error");
  //   } else {
  //     addToast("Venue deleted successfully !!!", "success");
  //   }
  // }, [deleteVenueData, deleteVenueError]);

  return (
    <Styles.CompanySettingsContainer>
      <Styles.Header>
        <Styles.HeaderText>Companies</Styles.HeaderText>
        {isSuperAdmin && (
          <PrimaryBtn
            label="Add Company"
            icon="plus"
            handleClick={handleClickAddCompany}
            padding="10px 20px"
          />
        )}

        <AddCompanyModal
          showModal={showAddCompanyModal}
          setShowModal={setShowAddCompanyModal}
          createCompany={createCompany}
          title={"Add Company"}
        />
      </Styles.Header>
      <Styles.CompaniesContainer>
        {companiesLoading ? (
          <Styles.LoaderContainer>
            <SpinnerComponent />
          </Styles.LoaderContainer>
        ) : (
          companiesStateData.map((company) => (
            <CompanyRow
              key={company.id}
              company={company}
              createVenue={createVenue}
              updateVenue={updateVenue}
              deleteVenue={deleteVenue}
              updateCompany={updateCompany}
              checkUserPermissionIsAdmin={checkUserPermissionIsAdmin}
              loggedUserAttributes={loggedUserAttributes}
              isSuperAdmin={isSuperAdmin}
            />
          ))
        )}
      </Styles.CompaniesContainer>
    </Styles.CompanySettingsContainer>
  );
};

export default CompanySettings;
