import React, { useState, useEffect, useContext } from "react";
//Styles
import Styles from "./styles";
//Configs
import { modalTitles } from "../../../../configs/staticText";
//Components
import UserRow from "./UserRow";
/* import SearchBar from "../../../../components/SearchBar"; */
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
import SpinnerComponent from "../../../../components/Loader";
import AddVenueToUserModal from "../../../Modals/AddVenueToUser";
import UpdateVenuePermissionModal from "../../../Modals/UpdateVenuePermission";
import InviteUserGeneralModal from "../../../Modals/InviteUserSettings";
import UpdateCompanyPermissionModal from "../../../Modals/UpdateCompanyPermission";
//Context
import { AuthContext } from "../../../../context/authContext";
//GraphQl
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  LIST_USER_BY_COMPANY,
  LIST_COMPANY,
  INVITE_USER,
  INVITE_USER_TO_VENUE,
  UPDATE_USER_VENUE_PERMISSIONS,
  REMOVE_USER_FROM_VENUE,
  REMOVE_USER_FROM_COMPANY,
  RETRIEVE_USER_VENUE_AND_COMPANY,
  UPDATE_USER_COMPANY_PERMISSIONS,
} from "../../../../graphql/graph";
//Helpers
import { checkUserPermissionIsAdmin } from "../../../../configs/helpers";
import { useToastModal } from "../../../../context/ToastModalContext";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";

const UserSettings = () => {
  /* const [, searchTerm, setSearchTerm] = useState(""); */
  const { loggedUserAttributes } = useContext(AuthContext);
  const [usersByCompanyList, setUsersByCompanyList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [venuesFromSelectedCompany, setVenuesFromSelectedCompany] = useState([]);
  const [clickedUserId, setClickedUserId] = useState("");
  const [userCurrentVenuePermission, setUserCurrentVenuePermission] = useState({});
  const [userCompanyVariables, setUserCompanyVariables] = useState({});
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  //Modals State
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showAddVenueToUserModal, setShowAddVenueToUserModal] = useState(false);
  const [showVenuePermissionTypeModal, setShowVenuePermissionTypeModal] = useState(false);
  const [showCompanyPermissionTypeModal, setShowCompanyPermissionTypeModal] = useState(false);

  const { addToast } = useToastModal();

  //GraphQl Queries/Mutations
  const {
    data: companiesData,
    //TODO Handle graphql loading+error
    /* loading: companiesLoading,
    error: companiesError, */
  } = useQuery(LIST_COMPANY, {
    context: { clientName: "graph" },
  });

  const [
    getUsersByCompany,
    { data: usersByCompanyData, /* error: usersByCompanyError, */ loading: usersByCompanyLoading },
  ] = useLazyQuery(LIST_USER_BY_COMPANY, {
    context: { clientName: "graph" },
    /* fetchPolicy: "no-cache", */
  });

  const [inviteUser, { data: inviteUSerData, loading: inviteUSerLoading, error: inviteUserError }] =
    useMutation(INVITE_USER, {
      context: { clientName: "graph" },
    });

  const [
    inviteUserToVenueMutation,
    /* {
      data: addUserToVenueData,
      loading: addUserToVenueLoading,
      error: addUserToVenueError,
    }, */
  ] = useMutation(INVITE_USER_TO_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: RETRIEVE_USER_VENUE_AND_COMPANY,
        context: { clientName: "graph" },
        variables: {
          id: clickedUserId,
        },
      },
    ],
  });

  const [
    updateUserVenuePermissions,
    {
      data: updateUserVenuePermissionsData,
      /* loading: updateUserVenuePermissionsLoading, */
      error: updateUserVenuePermissionsError,
    },
  ] = useMutation(UPDATE_USER_VENUE_PERMISSIONS, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: RETRIEVE_USER_VENUE_AND_COMPANY,
        context: { clientName: "graph" },
        variables: {
          id: clickedUserId,
        },
      },
    ],
  });

  const [
    removeUserFromVenue,
    {
      data: removeUserFromVenueData,
      /* loading: removeUserLoading, */
      error: removeUserFromVenueError,
    },
  ] = useMutation(REMOVE_USER_FROM_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: RETRIEVE_USER_VENUE_AND_COMPANY,
        context: { clientName: "graph" },
        variables: {
          id: clickedUserId,
        },
      },
    ],
  });

  const [
    updateUserCompanyPermissions,
    { data: updateUserCompanyPermissionsData, error: updateUserCompanyPermissionsError },
  ] = useMutation(UPDATE_USER_COMPANY_PERMISSIONS, {
    context: { clientName: "graph" },
  });

  const [
    removeUserFromCompany,
    {
      data: removeUserFromCompanyUserData,
      /* loading: removeUserFromCompanyLoading, */
      error: removeUserFromCompanyError,
    },
  ] = useMutation(REMOVE_USER_FROM_COMPANY, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_USER_BY_COMPANY,
        context: { clientName: "graph" },
        variables: {
          companyId: selectedCompanyId,
        },
      },
    ],
  });

  useEffect(() => {
    if (usersByCompanyData) {
      setUsersByCompanyList(usersByCompanyData.listUserByCompany.data);
    }
  }, [usersByCompanyData]);

  useEffect(() => {
    if (companiesData) {
      setCompaniesList(companiesData.listCompany.data);
      setSelectedCompanyId(companiesData.listCompany.data[0].id);
    }
  }, [companiesData]);

  useEffect(() => {
    if (selectedCompanyId) {
      getUsersByCompany({
        variables: {
          companyId: selectedCompanyId,
        },
      });
    }
  }, [getUsersByCompany, selectedCompanyId]);

  //Set Venues list from selected company
  useEffect(() => {
    if (companiesList) {
      const selectedCompanyVenues = companiesList.find((company) => {
        return company.id === selectedCompanyId;
      });
      setVenuesFromSelectedCompany(selectedCompanyVenues?.Venues);
    }
  }, [selectedCompanyId, companiesList]);

  //Handling Success and Errors
  //TODO: refactor to grab error message and dynamically display error message avoiding multiple useEffects?
  useEffect(() => {
    if (inviteUSerData) {
      addToast("User invited successfully!", "success");
    }
    if (inviteUserError) {
      if (inviteUserError.graphQLErrors[0].message.includes("Invite already pending")) {
        addToast("User already invited and invitation code still valid!", "error");
      }
    }
  }, [inviteUSerData, inviteUserError]);

  useEffect(() => {
    if (updateUserCompanyPermissionsData) {
      addToast("User company permissions updated successfully!", "success");
    }
    if (updateUserCompanyPermissionsError) {
      addToast("Error updating user company permissions!", "error");
    }
  }, [updateUserCompanyPermissionsData, updateUserCompanyPermissionsError]);

  /* useEffect(() => {
    if (addUserToVenueData) {
      addToast("User added successfully!", "success");
    }
    if (addUserToVenueError) {
      addToast(`${addUserToVenueError.graphQLErrors[0].message}`, 'error');
    }
  }, [addUserToVenueData, addUserToVenueError]); */

  useEffect(() => {
    if (removeUserFromVenueData || removeUserFromCompanyUserData) {
      addToast("User removed successfully!", "success");
    }
    if (removeUserFromVenueError || removeUserFromCompanyError) {
      addToast("Something went wrong!", "error");
    }
  }, [
    removeUserFromVenueData,
    removeUserFromCompanyUserData,
    removeUserFromVenueError,
    removeUserFromCompanyError,
  ]);

  useEffect(() => {
    if (updateUserVenuePermissionsData) {
      addToast("Permission updated successfully!", "success");
    }
    if (updateUserVenuePermissionsError) {
      addToast("Something went wrong!", "error");
    }
  }, [updateUserVenuePermissionsData, updateUserVenuePermissionsError, selectedCompanyId]);

  const handleInviteUserClick = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, selectedCompanyId)) {
      setShowInviteUserModal((prev) => !prev);
    } else {
      addToast("You must be a company Admin to invite other users!", "error");
    }
  };

  const handleSelectChange = (e) => {
    setSelectedCompanyId(e.target.value);
  };

  const handleRemoveUserFromCompany = (userId) => {
    removeUserFromCompany({
      variables: {
        companyId: selectedCompanyId,
        userId,
      },
    });
  };

  const handleRemoveUserFromVenue = (userId, venueId) => {
    removeUserFromVenue({
      variables: {
        venueId,
        userId,
      },
    });
  };

  return (
    <Styles.ManageUsersContainer>
      <Styles.Header>
        {companiesList.length > 1 ? (
          <Styles.Select onChange={handleSelectChange}>
            {companiesList.map((company) => (
              <option key={company.id} value={company.id}>
                {company.companyName}
              </option>
            ))}
          </Styles.Select>
        ) : (
          <Styles.Title>{companiesList[0]?.companyName}</Styles.Title>
        )}

        {!inviteUSerLoading ? (
          <PrimaryBtn label="Invite User" padding="10px 20px" handleClick={handleInviteUserClick} />
        ) : (
          <SpinnerComponent />
        )}
        <InviteUserGeneralModal
          showModal={showInviteUserModal}
          setShowModal={setShowInviteUserModal}
          inviteUser={inviteUser}
          companyId={selectedCompanyId}
          venues={venuesFromSelectedCompany}
          title={"Invite new user"}
        />
      </Styles.Header>
      <Styles.SearchContainer>
        {/*  <SearchBar
          handleOnChange={(event) => setSearchTerm(event.target.value)}
        /> */}
      </Styles.SearchContainer>
      {usersByCompanyLoading ? (
        <Styles.LoaderContainer>
          <SpinnerComponent width={75} height={75} />
        </Styles.LoaderContainer>
      ) : usersByCompanyList.length > 0 ? (
        usersByCompanyList.map((user) => (
          <UserRow
            key={user.userId}
            user={user}
            setShowAddVenueToUserModal={setShowAddVenueToUserModal}
            setShowVenuePermissionTypeModal={setShowVenuePermissionTypeModal}
            setClickedUserId={setClickedUserId}
            setUserCurrentVenuePermission={setUserCurrentVenuePermission}
            modalTitles={modalTitles}
            companyId={selectedCompanyId}
            setShowCompanyPermissionTypeModal={setShowCompanyPermissionTypeModal}
            setUserCompanyVariables={setUserCompanyVariables}
            checkUserPermissionIsAdmin={checkUserPermissionIsAdmin}
            loggedUserAttributes={loggedUserAttributes}
            setSelectedUserEmail={setSelectedUserEmail}
            removeUserFromVenue={handleRemoveUserFromVenue}
            removeUserFromCompany={handleRemoveUserFromCompany}
          />
        ))
      ) : (
        <Styles.LoaderContainer>
          <Styles.NoUsersText>No Users on the selected company!</Styles.NoUsersText>
        </Styles.LoaderContainer>
      )}
      {
        <AddVenueToUserModal
          showModal={showAddVenueToUserModal}
          setShowModal={setShowAddVenueToUserModal}
          venues={venuesFromSelectedCompany}
          userId={clickedUserId}
          userEmail={selectedUserEmail}
          companyId={selectedCompanyId}
          inviteUserToVenueMutation={inviteUserToVenueMutation}
          title={"Asssign a venue to this user!"}
        />
      }
      <UpdateVenuePermissionModal
        showModal={showVenuePermissionTypeModal}
        setShowModal={setShowVenuePermissionTypeModal}
        userCurrentVenuePermission={userCurrentVenuePermission}
        updateUserVenuePermissions={updateUserVenuePermissions}
        title={"Update venue permissions!"}
      />
      <UpdateCompanyPermissionModal
        showModal={showCompanyPermissionTypeModal}
        setShowModal={setShowCompanyPermissionTypeModal}
        userCompanyVariables={userCompanyVariables}
        updateUserCompanyPermissions={updateUserCompanyPermissions}
        title={"Update company permissions!"}
      />
    </Styles.ManageUsersContainer>
  );
};

export default UserSettings;
