import styled from "styled-components/macro";

const styles = {
  FormWrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  `,
  Label: styled.label`
    font-size: ${(props) => (props.profileForm ? "14px" : "12px")};
    padding-bottom: ${(props) => (props.profileForm ? "0.2rem" : "0.5rem")};
    @media screen and (max-width: 480px) {
      font-size: 17px;
      /*  padding-bottom: ${(props) => (props.profileForm ? "0.2rem" : "0.5rem")}; */
    }
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};

    ${(props) =>
      props.required &&
      `
      &:after {
        content: " *";
        color: ${props.theme.colors.error.red500};
      }
    `}
  `,
  SubmitError: styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.error.red300};
  `,
};

export default styles;
