import styled from "styled-components";
import { LinkIcon, LinkLabel, sharedLinkStyles } from "../Shared/Shared.styled";

const styles = {
  ExpandableLinkContainer: styled(({ isExpanded, ...props }) => <div {...props} />)`
    background-color: ${({ isExpanded, theme }) =>
      isExpanded ? theme.surfaces.dropdown : "transparent"};
    border-radius: 34px 34px 12px 12px;
    transition: background-color 0.3s;
    margin-bottom: 5px;
  `,
  LinkContainer: styled.div`
    ${sharedLinkStyles}
    font-weight: ${({ active, theme }) => (active ? theme.fonts.weights.semibold : "normal")};
  `,
  LinkIcon,
  LinkLabel,
  SubLinksContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0px 12px 44px;
  `,
};

export default styles;
