import { css } from "styled-components";

export const Fonts = css`
  @font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url("/fonts/Inter-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 500;
    src: url("/fonts/Inter-Medium.woff2") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 600;
    src: url("/fonts/Inter-SemiBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 700;
    src: url("/fonts/Inter-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 800;
    src: url("/fonts/Inter-ExtraBold.woff2") format("woff2");
  }
`;
