import React from "react";
// Styles
import Styled from "./Checkbox.styled";

const Checkbox = ({ checked, onChange, disabled }) => {
  return (
    <Styled.CheckboxContainer>
      <Styled.StyledCheckbox checked={checked} onChange={onChange} disabled={disabled} />
      {checked && <Styled.CheckmarkIcon name="check" />}
    </Styled.CheckboxContainer>
  );
};

export default Checkbox;
