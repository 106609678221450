import React, { useCallback, useMemo } from "react";
// Styles
import Styled from "./ExpandableLink.styled";
// Components
import SubLink from "../SubLink/SubLink";
import Icon from "components/Shared/Icon/Icon";
// Tools
import { useNavigate, useParams } from "react-router-dom";

const ExpandableLink = ({ company, isExpanded, onClick }) => {
  const navigate = useNavigate();
  const { companyId, venueId } = useParams();

  const handleNavigateClick = useCallback(() => {
    onClick();
    navigate(`/manage-companies/${company.id}`);
  }, [navigate, onClick, company.id]);

  const handleExpandClick = useCallback(
    (event) => {
      event.stopPropagation();
      onClick();
    },
    [onClick],
  );

  const isSubLinkSelected = useMemo(() => {
    return company.Venues?.some((venue) => venue.id === venueId);
  }, [company.Venues, venueId]);

  return (
    <Styled.ExpandableLinkContainer isExpanded={isExpanded}>
      <Styled.LinkContainer
        onClick={handleNavigateClick}
        active={company.id === companyId && !isSubLinkSelected}
      >
        <Styled.LinkIcon onClick={handleExpandClick}>
          <Icon
            name={"venue"}
            className="sidebar-icon"
            isActive={isExpanded && !isSubLinkSelected}
          />
        </Styled.LinkIcon>
        <Styled.LinkLabel>{company.companyName}</Styled.LinkLabel>
      </Styled.LinkContainer>

      {isExpanded && (
        <Styled.SubLinksContainer>
          {company.Venues.map((venue) => (
            <SubLink
              key={venue.id}
              label={venue.venueName}
              path={`/manage-venues/${company.id}/${venue.id}`}
              venueId={venue.id}
            />
          ))}
        </Styled.SubLinksContainer>
      )}
    </Styled.ExpandableLinkContainer>
  );
};

export default ExpandableLink;
