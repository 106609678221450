import React from "react";
import { Button } from "./styles";
import ReactTooltip from "react-tooltip";
import { colors } from "styles/colors";

const RoundButton = ({ icon, handleClick, boxshadow, size, cameraInferenceBtn, tooltipText }) => {
  return (
    <>
      <Button
        onClick={() => handleClick()}
        boxshadow={boxshadow ? true : false}
        size={size}
        cameraInferenceBtn={cameraInferenceBtn ? true : false}
        data-tip={tooltipText}
      >
        {icon}
        <ReactTooltip
          place="bottom"
          type="dark"
          effect="solid"
          className="customeTooltipTheme"
          arrowColor={colors.primary.blue800}
        />
      </Button>
    </>
  );
};

export default RoundButton;
