import styled from "styled-components";

const styles = {
  ContextMenuContainer: styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: absolute;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.13);
    z-index: 1;
  `,

  ContextMenuHeading: styled.h3`
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  MenuItem: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${({ theme }) => theme.fontSizes.field};
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.primary.lightBlue};
    }
  `,
};

export default styles;
