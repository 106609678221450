import React, { useState, useRef } from "react";
//Styles
import Styles from "./styles";
//Components
import Icon from "components/Shared/Icon/Icon";
import StatusCircle from "components/Shared/StatusCircle/StatusCircle";
//Hooks
import useClickOutside from "hooks/useClickOutside";
//Utils
import { formatDate } from "utils/formatDate";

const ModelRow = ({ modelVersion, model, updateModel, addVersion }) => {
  const [selectedModel, setSelectedModel] = useState(null);
  const menuRef = useRef(null);

  useClickOutside(menuRef, () => setSelectedModel(null));

  const handleToggleMenu = (model) => {
    setSelectedModel((prev) => (prev === model ? null : model));
  };

  const renderMenuCard = (model) => {
    return (
      <Styles.MenuCard ref={menuRef}>
        <Styles.MenuItemsContainer>
          <Styles.MenuItem onClick={() => addVersion({ model })}>
            <Icon name="add_plus_circle" size="20px" />
            <span>Add Version</span>
          </Styles.MenuItem>
          <Styles.MenuItem onClick={() => updateModel({ model, modelVersion })}>
            <Icon name="arrows_reload" size="20px" />
            <span>Update Version</span>
          </Styles.MenuItem>
        </Styles.MenuItemsContainer>
      </Styles.MenuCard>
    );
  };

  return (
    <>
      <Styles.TableCell>{model.modelName}</Styles.TableCell>
      <Styles.TableCell>{model.modelType}</Styles.TableCell>
      <Styles.TableCell>{formatDate(modelVersion.createdAt)}</Styles.TableCell>
      <Styles.TableCell>
        {modelVersion.modelVersionNotes ? modelVersion.modelVersionNotes : "-"}
      </Styles.TableCell>
      <Styles.TableCell>
        <Styles.StatusContainer isActive={modelVersion.modelVersionStatus}>
          <StatusCircle className="table" isActive={modelVersion.modelVersionStatus} />
          <span>{modelVersion.modelVersionStatus ? "Enabled" : "Disabled"}</span>
        </Styles.StatusContainer>
      </Styles.TableCell>
      <Styles.TableCell>
        <Styles.MenuWrapper onClick={() => handleToggleMenu(model)}>
          {selectedModel === model && renderMenuCard(model)}
          <Icon name="menu" size="11.667px" />
        </Styles.MenuWrapper>
      </Styles.TableCell>
    </>
  );
};

export default ModelRow;
