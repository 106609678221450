import { useNavigate } from "react-router-dom";

const useNavigateCamera = () => {
  const navigate = useNavigate();

  const handleOpenCamera = (camera) => {
    if (camera.id) {
      const path = window.location.pathname.includes("manage-companies")
        ? `${camera.venueId}/${camera.id}`
        : `${camera.id}`;

      navigate(path);
    }
  };

  return { handleOpenCamera };
};

export default useNavigateCamera;
