import React from "react";
// Styles
import Styled from "./OverviewItem.styled";
// Components
import Icon from "components/Shared/Icon/Icon";

const OverviewItem = ({ value, label, icon }) => {
  return (
    <Styled.ItemContainer>
      <div>
        <Styled.OverviewValue>{value}</Styled.OverviewValue>
        <Styled.OverviewLabel>{label}</Styled.OverviewLabel>
      </div>

      <Styled.IconContainer>
        <Icon name={icon} />
      </Styled.IconContainer>
    </Styled.ItemContainer>
  );
};

export default OverviewItem;
