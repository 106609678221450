import styled from "styled-components/macro";

const styles = {
  SearchContainer: styled.div`
    display: flex;
    padding: 8px 0px;
    align-items: center;
    margin-bottom: 16px;
  `,
  SearchInputField: styled.input`
    border: none;
    background: none;
    padding: 0.5rem;
    width: 186px;

    ::placeholder {
      font-size: 14px;
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }
  `,
};

export default styles;
