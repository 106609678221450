import styled from "styled-components";

const styles = {
  DashboardContainer: styled.div``,
  CompaniesTitle: styled.h1`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 32px 0px 0px 0px;
  `,
  CompaniesContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  `,
};

export default styles;
