import { colors } from "./colors";

export const theme = {
  colors,
  buttons: {
    primary: colors.primary.blue800,
    secondary: colors.primary.blue200,
    primaryHover: colors.primary.blue900,
    popupCallToAction: colors.neutral.grey800,
  },
  camera: {
    active: colors.success.green500,
    error: colors.error.red500,
    inactive: colors.neutral.grey400,
  },
  surfaces: {
    background: colors.primary.blue200,
    dropdown: colors.neutral.grey300,
  },
  text: {
    primary: colors.neutral.black900,
    secondary: colors.neutral.grey600,
  },
  lines: {
    iconsPrimary: colors.primary.blue800,
    iconsSecondary: colors.neutral.grey500,
  },
  fonts: {
    primary: "Inter, sans-serif",
    weights: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
  },
  fontSizes: {
    largeHeadline: "28px",
    dashboardHeadline: "20px",
    subHeadline: "16px",
    largeBody: "16px",
    dashboardBody: "14px",
    button: "14px",
    field: "14px",
  },
};
