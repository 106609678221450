import { useMemo } from "react";
// Utils
import { formatName } from "utils/formatName";

const useExtractFilters = (venueDetails) => {
  return useMemo(() => {
    const venues = [];
    const areas = [];
    const cameras = [];
    const useCases = new Set();

    venueDetails.forEach((venue) => {
      venues.push({ id: venue.id, name: venue.venueName });

      venue.Areas.forEach((area) => {
        areas.push({ id: area.id, name: area.areaName });

        area.Machines.forEach((camera) => {
          cameras.push({ id: camera.id, name: camera.machineName });
          useCases.add(formatName(camera.machineModel));
        });
      });
    });

    const filters = [
      { id: 1, name: "Venues", data: venues, type: "venues" },
      { id: 2, name: "Areas", data: areas, type: "areas" },
      { id: 3, name: "Cameras", data: cameras, type: "cameras" },
      {
        id: 4,
        name: "Inference",
        data: [{ name: "Active" }, { name: "Inactive" }],
        type: "inferences",
      },
      {
        id: 5,
        name: "Use Case",
        data: Array.from(useCases).map((useCase) => ({ name: useCase })),
        type: "useCases",
      },
    ];

    return filters;
  }, [venueDetails]);
};

export default useExtractFilters;
