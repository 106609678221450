import React, { useState, useEffect } from "react";
// Styles
import Styled from "../Shared/Shared.styled";
// Components
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import StartCaptureRequest from "../../Modals/StartCaptureRequest/index.jsx";
import StartAnnotationRequest from "../../Modals/StartAnnotationRequest/index.jsx";
import StartTrainingRequest from "../../Modals/StartTrainingRequest/index.jsx";
import AnnotationRequests from "./AnnotationRequests/AnnotationRequests";
import TrainingRequests from "./TrainingRequests/TrainingRequests";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/chevron_up.svg";
// Graphql
import { COMBINED_LIST_COLLECTION_AND_TRAINING_REQUESTS } from "../../../graphql/graph.js";
// Hooks
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

const Training = ({ venuesList }) => {
  const { companyId } = useParams();
  const [showTraining, setShowTraining] = useState(false);
  const [showCaptureModal, setShowCaptureModal] = useState(false);
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [requestToUpdate, setRequestToUpdate] = useState(null);

  const [getCollectionRequests, { data: combinedRequestData }] = useLazyQuery(
    COMBINED_LIST_COLLECTION_AND_TRAINING_REQUESTS,
    {
      context: { clientName: "graph" },
    },
  );

  const handleCollectionRequestsFetch = async (companyId) => {
    await getCollectionRequests({
      variables: {
        companyId,
      },
    });
  };

  useEffect(() => {
    if (companyId) {
      handleCollectionRequestsFetch(companyId);
    }
  }, [companyId]);

  const handleToggleTrainingContainer = () => {
    setShowTraining(!showTraining);
  };

  const handleTrainNewModel = () => {
    setShowTrainingModal(!showTrainingModal);
  };

  const handleStartCapture = () => {
    setShowCaptureModal(!showCaptureModal);
  };

  const handleUpdateItem = (item) => {
    // if collection is status 3, show annotation instead
    if (item.crCollectionStatus === 3) {
      setRequestToUpdate(item);
      setShowAnnotationModal(true);
    } else {
      setRequestToUpdate(item);
      setShowCaptureModal(true);
    }
  };

  const handleUpdateTrainingItem = (item) => {
    setRequestToUpdate(item);
    setShowTrainingModal(true);
  };

  const onCloseModal = async () => {
    setRequestToUpdate(null);
    await handleCollectionRequestsFetch(companyId);
  };

  const collectionAnnotationData = combinedRequestData?.listCollectionRequest?.data || [];
  const collectionTrainingData = combinedRequestData?.listTrainingRequest?.data || [];

  const cameraMap = venuesList.reduce((prev, curr) => {
    curr.Areas.forEach((area) => {
      area.Machines.forEach((machine) => {
        prev[machine.id] = `${curr.venueName} - ${area.areaName} - ${machine.machineName}`;
      });
    });
    return prev;
  }, {});

  return (
    <Styled.CompanyDashboardContainer>
      <Styled.Header>
        <Styled.IconButton onClick={handleToggleTrainingContainer}>
          <Styled.SubTitle>Training</Styled.SubTitle>
          {showTraining ? <ChevronDown /> : <ChevronUp />}
        </Styled.IconButton>
      </Styled.Header>

      <Styled.DockerContainer>
        {showTraining && (
          <>
            <Styled.Section>
              <Styled.Header>
                <Styled.TableTitle>Collection and Annotation Requests</Styled.TableTitle>
                <Styled.Actions>
                  <PrimaryBtn
                    label={"Start capture request"}
                    handleClick={handleStartCapture}
                    padding="10px 20px"
                  />
                </Styled.Actions>
              </Styled.Header>

              <AnnotationRequests
                requestsList={collectionAnnotationData}
                updateItem={handleUpdateItem}
                cameraMap={cameraMap}
              />
            </Styled.Section>

            <Styled.Section>
              <Styled.Header>
                <Styled.TableTitle>Training Requests</Styled.TableTitle>
                <Styled.Actions>
                  <PrimaryBtn
                    label={"Train new model"}
                    handleClick={handleTrainNewModel}
                    padding="10px 20px"
                  />
                </Styled.Actions>
              </Styled.Header>
              <TrainingRequests
                requestsList={collectionTrainingData}
                updateItem={handleUpdateTrainingItem}
              />
            </Styled.Section>
          </>
        )}
      </Styled.DockerContainer>

      {showCaptureModal && (
        <StartCaptureRequest
          companyId={companyId}
          showModal={showCaptureModal}
          venuesList={venuesList}
          setShowModal={setShowCaptureModal}
          requestToUpdate={requestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
      {showAnnotationModal && (
        <StartAnnotationRequest
          companyId={companyId}
          showModal={showAnnotationModal}
          venuesList={venuesList}
          setShowModal={setShowAnnotationModal}
          requestToUpdate={requestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
      {showTrainingModal && (
        <StartTrainingRequest
          companyId={companyId}
          showModal={showTrainingModal}
          cameraMap={cameraMap}
          collectionAnnotationData={collectionAnnotationData}
          setShowModal={setShowTrainingModal}
          requestToUpdate={requestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
    </Styled.CompanyDashboardContainer>
  );
};

export default Training;
