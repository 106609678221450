import styled from "styled-components";

const styles = {
  SidebarContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    width: 189px;
    padding: 24px 0px 12px 8px;
    user-select: none;
  `,
  SpinnerContainer: styled.div`
    text-align: center;
    padding: 20px;
  `,
};

export default styles;
