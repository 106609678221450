import styled from "styled-components";
import { sharedStyles } from "../Shared/Shared.styled";

const styles = {
  StyledButton: styled.button`
    ${sharedStyles}
    background: ${({ theme }) => theme.colors.primary.blue800};

    &:hover {
      background-color: ${({ theme }) => theme.buttons.primaryHover};
    }
  `,
  ButtonLabel: styled.span`
    color: ${({ theme }) => theme.colors.neutral.white};
  `,
};

export default styles;
