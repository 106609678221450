import { colors } from "styles/colors";

export const highlightText = (text, highlight) => {
  if (!highlight) return text;

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: colors.warning.orange300 }}>
        {part}
      </span>
    ) : (
      part
    ),
  );
};
