import styled from "styled-components/macro";

const styles = {
  AuthContainer: styled.div`
    width: 80%;
    border-radius: 22px;
    background-color: #fff;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  FormContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    justify-content: space-around;
    padding: 3rem 4rem;
    font-size: 12px;

    @media screen and (max-width: 768px) {
      padding: 3rem;
    }
    @media screen and (max-width: 480px) {
      padding: 2rem 1.5rem;
    }
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
    gap: 1rem;
  `,
  Input: styled.input`
    height: 50px;
    padding: 7px 17px;
    background: ${({ theme }) => theme.colors.neutral.grey200};
    font-size: 12px;

    outline: none;
    border: none;
    border-radius: 8px;

    @media screen and (max-width: 480px) {
      font-size: 17px;
      height: 43px;
    }

    &::placeholder {
      opacity: 0.4;
    }
  `,
  InputLabel: styled.label`
    font-size: 12px;
  `,

  TextArea: styled.textarea`
    font-size: 12px;
    padding: 1rem;
    height: 7rem;
    resize: vertical;
    min-height: 1.5rem;
    max-height: 7rem;
    outline: none;

    border: 2px solid ${({ theme }) => theme.colors.neutral.grey200};
    border-radius: 8px;

    @media screen and (max-width: 480px) {
      font-size: 17px;
    }
  `,
  SubmitInput: styled.input`
    background: ${({ theme }) => theme.buttons.primary};

    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    color: ${({ theme }) => theme.colors.neutral.white};
    width: 100%;
    height: 50px;
    padding: 7px 17px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.buttons.primaryHover};
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 30px;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 480px) {
      font-size: 44px;
    }
    @media screen and (max-width: 480px) {
      line-height: 54px;
    }
  `,
  Paragraph: styled.p`
    font-size: 12px;
    margin: 0;
    padding: 0;
    display: ${({ forgotPassword }) => (forgotPassword ? "flex" : null)};
    justify-content: ${({ forgotPassword }) => (forgotPassword ? "center" : null)};
    margin: ${({ forgotPassword }) => (forgotPassword ? "1rem 0" : null)};

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary.blue800};
    }
  `,
  SubmitServerError: styled.div`
    display: flex;
    font-size: 12px;
    margin-bottom: 0.8rem;
    color: ${({ theme }) => theme.colors.error.red300};
  `,
  IconWrapper: styled.div`
    position: absolute;
    right: 15px;
    top: 35px;
    cursor: pointer;
    @media screen and (max-width: 480px) {
      right: 10px;
      top: 36px;
    }
  `,
  LoadingAndErrorContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 2rem 0;
    border-radius: 30px;
    background-color: #ffffff;
  `,
  RequestAccLink: styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;

    span {
      color: ${({ theme }) => theme.colors.neutral.grey500};
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary.blue800};
    }
  `,

  PasswordLabelsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ForgotPasswordLink: styled.div`
    color: ${({ theme }) => theme.colors.primary.blue800};

    a {
      text-decoration: none;
      color: inherit;
    }
  `,
};

export default styles;
