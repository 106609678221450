import React from "react";
// Styles
import Styled from "./Dashboard.styled";
// Components
import Overview from "../Shared/Overview/Overview";
import CompanyCard from "./CompanyCard/CompanyCard";
import SpinnerComponent from "components/Loader";
import FallbackUI from "components/Shared/Fallback/Fallback";
// Hooks
import useDashboardData from "hooks/useDashboardData";

const Dashboard = () => {
  const { dashboardData, dashboardLoading, dashboardError, refetch, overviewItems } =
    useDashboardData();

  if (dashboardLoading) {
    return <SpinnerComponent />;
  }

  if (dashboardError) {
    return <FallbackUI onRetry={refetch} />;
  }

  return (
    <Styled.DashboardContainer>
      <Overview items={overviewItems} />

      <Styled.CompaniesTitle>Companies</Styled.CompaniesTitle>
      <Styled.CompaniesContainer>
        {dashboardData?.Companies.map((company) => (
          <CompanyCard key={company.id} company={company} />
        ))}
      </Styled.CompaniesContainer>
    </Styled.DashboardContainer>
  );
};

export default React.memo(Dashboard);
