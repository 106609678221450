import React, { useContext, useState } from "react";
// Styles
import Styled from "./Venue.styled";
// Components
import Overview from "components/Shared/Overview/Overview";
import VenueCard from "./VenueCard/VenueCard";
import SpinnerComponent from "components/Loader";
import Icon from "components/Shared/Icon/Icon";
import ContextMenu from "components/ContextMenu/ContextMenu";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import UpdateAreaModal from "containers/Modals/UpdateArea";
import AddUpdateCamera from "containers/Modals/AddUpdateCamera/AddUpdateCamera";
// Hooks
import { useParams } from "react-router-dom";
import useContextMenu from "hooks/useContextMenu";
import useVenueData from "hooks/useVenueData";
import { useVenueHandlers } from "hooks/useVenueHandlers";
// Context
import { AuthContext } from "context/authContext";

const Venue = () => {
  const [showUpdateAreaModal, setShowUpdateAreaModal] = useState(false);
  const [showAddUpdateCameraModal, setShowAddUpdateCameraModal] = useState(false);
  const [areaNameObj, setAreaNameObj] = useState({});
  const [createUpdateObj, setCreateUpdateObj] = useState({});
  const { loggedUserAttributes } = useContext(AuthContext);

  const { companyId, venueId } = useParams();
  const { venue, expandedAreas, setExpandedAreas, retrieveVenueLoading, overviewItems } =
    useVenueData(venueId);
  const { contextMenu, toggleContextMenu, closeContextMenu, contextMenuRef } = useContextMenu();
  const {
    handleClickPreviewRTSP,
    handleClickStartInference,
    handleClickUpdateArea,
    handleClickDeleteArea,
    handleClickAddUpdateCamera,
  } = useVenueHandlers(
    setShowUpdateAreaModal,
    setAreaNameObj,
    setCreateUpdateObj,
    setShowAddUpdateCameraModal,
    loggedUserAttributes,
    companyId,
  );

  if (retrieveVenueLoading) {
    return <SpinnerComponent />;
  }

  const toggleArea = (areaId) => {
    setExpandedAreas((prev) => ({
      ...prev,
      [areaId]: !prev[areaId],
    }));
  };

  return (
    <Styled.VenueContainer ref={contextMenuRef}>
      <Overview title="Venue Overview" items={overviewItems} />

      {venue ? (
        <Styled.CardsButtonContainer>
          <Styled.VenueCardsContainer>
            {venue?.Areas.map((area) => (
              <Styled.AreaContainer key={area.id}>
                <Styled.AreaHeader>
                  <Styled.AreaName>{area.areaName}</Styled.AreaName>
                  <Styled.AreaIcons>
                    <Icon
                      name="dot_menu"
                      size="24px"
                      onClick={(e) => toggleContextMenu(e, area.id)}
                    />
                    <Icon
                      name={expandedAreas[area.id] ? "arrow_up" : "arrow_down"}
                      size="24px"
                      onClick={() => toggleArea(area.id)}
                    />
                  </Styled.AreaIcons>
                </Styled.AreaHeader>

                {expandedAreas[area.id] && (
                  <Styled.CamerasContainer>
                    {area.Machines.length === 0 ? (
                      <p>There are no Cameras found! Please add one!</p>
                    ) : (
                      area.Machines.map((machine) => (
                        <VenueCard key={machine.id} camera={machine} venue={venue} />
                      ))
                    )}
                  </Styled.CamerasContainer>
                )}

                {contextMenu.isVisible && contextMenu.areaId === area.id && (
                  <ContextMenu
                    isVisible={contextMenu.isVisible}
                    position={contextMenu.position}
                    onClose={closeContextMenu}
                    onRefreshRTSP={() => handleClickPreviewRTSP(venueId)}
                    onStartInferencing={() => handleClickStartInference(venueId, area)}
                    onUpdateArea={() => handleClickUpdateArea(area)}
                    onDeleteArea={() => handleClickDeleteArea(area, venueId, area.id)}
                  />
                )}
              </Styled.AreaContainer>
            ))}
          </Styled.VenueCardsContainer>

          <PrimaryBtn
            label="Add Camera"
            height="44px"
            padding="10px 20px"
            icon={"plus"}
            iconSize={"20px"}
            onClick={() => handleClickAddUpdateCamera(venue)}
          />
        </Styled.CardsButtonContainer>
      ) : null}

      <UpdateAreaModal
        title={"Update Area Name"}
        showModal={showUpdateAreaModal}
        setShowModal={setShowUpdateAreaModal}
        areaNameObj={areaNameObj}
      />

      {showAddUpdateCameraModal && (
        <AddUpdateCamera
          title={"Add/Update Camera"}
          showModal={showAddUpdateCameraModal}
          setShowModal={setShowAddUpdateCameraModal}
          cameraObj={createUpdateObj}
        />
      )}
    </Styled.VenueContainer>
  );
};

export default Venue;
