import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

//Styles
import Styles from "./styles";
import Icon from "components/Shared/Icon/Icon";

const Settings = () => {
  const { isSuperAdmin } = useContext(AuthContext);
  const [activeLocation, setActiveLocation] = useState("");
  /* const [companiesStateData] = useOutletContext(); */
  const location = useLocation();

  useEffect(() => {
    setActiveLocation(location.pathname);
  }, [location]);

  const handleActiveTab = (route) => {
    if (activeLocation.includes(route)) {
      return +true;
    }
  };

  return (
    <>
      <Styles.NavAndOutletContainer>
        <Styles.SettingsNavigationContainer>
          <Styles.Link to="company-settings" activeTab={handleActiveTab("company-settings")}>
            <Styles.LinkContainer activeTab={handleActiveTab("company-settings")}>
              <Icon
                name="company"
                className="sidebar-icon"
                isActive={handleActiveTab("company-settings")}
              />
              Companies
            </Styles.LinkContainer>
          </Styles.Link>

          {isSuperAdmin && (
            <Styles.Link to="model-manager" activeTab={handleActiveTab("model-manager")}>
              <Styles.LinkContainer activeTab={handleActiveTab("model-manager")}>
                <Icon
                  name="command"
                  className="sidebar-icon"
                  isActive={handleActiveTab("model-manager")}
                />
                Models
              </Styles.LinkContainer>
            </Styles.Link>
          )}

          <Styles.Link to="user-settings" activeTab={handleActiveTab("user-settings")}>
            <Styles.LinkContainer activeTab={handleActiveTab("user-settings")}>
              <Icon
                name="users"
                className="sidebar-icon"
                isActive={handleActiveTab("user-settings")}
              />
              Users
            </Styles.LinkContainer>
          </Styles.Link>

          <Styles.Link to="profile-settings" activeTab={handleActiveTab("profile-settings")}>
            <Styles.LinkContainer activeTab={handleActiveTab("profile-settings")}>
              <Icon
                name="user_square"
                className="sidebar-icon"
                isActive={handleActiveTab("profile-settings")}
              />
              Profile
            </Styles.LinkContainer>
          </Styles.Link>
        </Styles.SettingsNavigationContainer>
        <Styles.SettingsOutletContainer>
          <Outlet />
        </Styles.SettingsOutletContainer>
      </Styles.NavAndOutletContainer>
    </>
  );
};

export default Settings;
