import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const styles = {
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  `,
  Title: styled.h1`
    font-size: 20px;
    margin: 0;
  `,

  NavAndOutletContainer: styled.div``,

  VenueNavigationContainer: styled.div`
    display: flex;
    background: ${({ theme }) => theme.colors.neutral.white};
    gap: 8px;
    margin-bottom: 10px;
    align-items: center;
    border-radius: 10px;
    padding: 8px;
    height: 60px;
  `,
  SecOutletContainer: styled.div`
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 10px;
    /* min-height: 681px; */
    /*  min-height: 600px; */
    height: 100%;
    /* max-width: 1031px; */
    padding: 2rem;
  `,

  LinkContainer: styled.div`
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ activeTab, theme }) =>
      activeTab ? theme.colors.primary.blue800 : theme.colors.primary.blue200};
    box-sizing: border-box;
    border-radius: 50px;
    gap: 8px;
    padding: 10px 20px;
  `,
  Link: styled(Link)`
    color: ${({ activeTab, theme }) =>
      activeTab ? theme.colors.neutral.white : theme.colors.primary.blue900};
    font-size: ${({ theme }) => theme.fontSizes.button};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    text-decoration: none;
  `,
};

export default styles;
