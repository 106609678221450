import styled from "styled-components/macro";
import { MdClose } from "react-icons/md";
import { HiOutlineFolderDownload } from "react-icons/hi";

const styles = {
  DownLoadIcon: styled(HiOutlineFolderDownload)`
    cursor: pointer;
    color: white;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 8px;
    right: 7px;
  `,
  DisplayCameraContainer: styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: ${({ hasVideo }) => (hasVideo ? "center" : "flex-start")}; */
    justify-content: flex-start;
    background: ${({ theme }) => theme.colors.neutral.grey200};
    padding: 2rem;

    .customeTooltipTheme {
      color: ${({ theme }) => theme.colors.neutral.white};
      font-size: 12px !important;
      background-color: ${({ theme }) => theme.colors.primary.blue800};
      pointer-events: auto !important;

      & :hover {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  `,
  Header: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1150px) {
      flex-direction: column;
      gap: 1rem;
    }
    gap: 12px;
    align-self: stretch;
  `,
  CameraTitle: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    margin: 0;
  `,
  ButtonsContainer: styled.div`
    margin-bottom: 16px;
    display: flex;
    height: 48px;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 12px 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  MaskingWrapperImg: styled.div`
    width: ${({ width }) => `${width || 960}px`};
    height: ${({ height }) => `${height || 540}px`};
    position: absolute;
  `,
  PreviewWrapper: styled.div`
    width: 100%;
    height: 100%;
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  MaskingWrapper: styled.div`
    position: relative;
    width: ${({ width }) => `${width || 960}px`};
  `,
  CloseButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    color: #464646;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    color: #c4c4c4;
  `,
  SpinnerContainer: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  `,
  ErrorContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    padding: 2rem 0;
  `,
  ErrorMessage: styled.p`
    text-align: center;
    font-size: ${({ red }) => (red ? "17px" : "14px")};
    color: ${({ red }) => (red ? "#ff3333" : "#fff")};
    margin: 0;
  `,
  LoadingMessage: styled.p`
    font-size: 1rem;

    animation: bounce 2s ease-in-out infinite;

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
        text-shadow: 0px 10px rgba(0, 0, 0, 0.1);
      }
      100% {
        transform: translateY(0);
      }
    }
  `,
  RetrieveThumbBtnsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
  RetrieveThumbnailBtn: styled.button`
    font-size: ${({ theme }) => theme.fontSizes.field};
    color: ${({ theme }) => theme.colors.neutral.white};
    background-color: ${({ theme }) => theme.buttons.primary};
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: 50px;
  `,
  LoaderContainer: styled.div`
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
  `,
  PreviewAndOutputContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    padding: 0px 68px 0px 67px;
    background: ${({ theme }) => theme.colors.neutral.black900};
    border-radius: 12px 12px 0px 0px;
  `,
  LabelContainer: styled.div`
    background-color: ${({ theme }) => theme.colors.primary.blue800};
    padding: 0.25rem 1rem;
    width: fit-content;
    font-size: 12px;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    flex-direction: row;
    display: flex;
  `,
  Column: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  TextWarning: styled.div`
    margin-left: 10px;
    font-size: ${({ theme }) => theme.fontSizes.field};
    color: orange;
  `,
  TextCritical: styled.div`
    margin-left: 10px;
    font-size: 15px;
    color: red;
  `,
  LivePreviewCanvas: styled.canvas`
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-out;
  `,
  LivePreviewMasks: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-out;
  `,
  LivePreviewExtra: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  IconContainer: styled.div`
    display: flex;
    padding: 12px;
    align-items: flex-start;
    cursor: pointer;
  `,
  ActionButtons: styled.div`
    display: flex;
    align-items: center;
  `,
  Divider: styled.div`
    width: 1px;
    height: 22px;
    background-color: ${({ theme }) => theme.colors.primary.blue300};
  `,
  OutputAndMaskingContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  ConfigureMasking: styled.div`
    cursor: pointer;
    font-size: 12px;
    opacity: 0.8;
  `,
  DataContainer: styled.div`
    display: flex;
    align-items: stretch;
    gap: 16px;
  `,
  EstimateContainer: styled.div`
    display: flex;
    flex: 2 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  EstimateTitle: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.subHeadline};
  `,
  EstimateValue: styled.span`
    font-size: 36px;
  `,
  Button: styled.div`
    display: flex;
    padding: 4px 0px 0px 3px;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
    background: ${({ theme }) => theme.colors.primary.blue800};
    cursor: pointer;
  `,
  DetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 16px;
    flex-shrink: 0;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
    gap: 8px;
  `,
  DetailItem: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  DetailTitle: styled.span`
    font-size: 12px;
    opacity: 0.7;
  `,
  DetailValue: styled.span`
    font-size: 12px;
  `,
};

// define this style here so we can reference LivePreviewCanvas and LivePreviewMasks
styles.PreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.videoStarted ? "100%" : `${props.height}px`)};

  & ${styles.LivePreviewCanvas} {
    opacity: ${(props) => (props.videoStarted ? "1" : "0")};
  }
  ${styles.LivePreviewMasks} {
    opacity: ${(props) => (props.videoStarted ? "1" : "0")};
  }
`;

export default styles;
