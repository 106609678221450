import React, { useEffect } from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

const updateVenuePermissionSchema = yup.object().shape({
  permissionType: yup.string().required("Permission name is a required field."),
});

const UpdateVenuePermissionModal = ({
  showModal,
  setShowModal,
  title,
  userVenuePermissionType,
  userVenuePermissionId,
  updateUserVenuePermissions,
  userCurrentVenuePermission,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateVenuePermissionSchema),
    },
    { defaultValues: userCurrentVenuePermission },
  );

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(userCurrentVenuePermission);
  }, [reset, userCurrentVenuePermission]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, create Venue
  const submitForm = (updateVenuePermissionFormData) => {
    updateUserVenuePermissions({
      variables: {
        id: userCurrentVenuePermission.id,
        permissionType: updateVenuePermissionFormData.permissionType,
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={title}>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Permission Type</Styles.Label>
            <Styles.Select
              name="permissionType"
              id="permissionType"
              {...register("permissionType")}
              defaultValue=""
            >
              <option value="" disabled>
                Select venue permission
              </option>
              <option value="ADMIN">Admin</option>
              <option value="VIEW_ONLY">View Only</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.permissionType?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              withIcon={false}
              width="138px"
              height="40px"
              onClick={handleClickCancelBtn}
            />
            <PrimaryBtn
              label="Update"
              withIcon={false}
              width="138px"
              height="40px"
              type="submit"
              id="submit"
            />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default UpdateVenuePermissionModal;
