import React from "react";
// Styles
import Styled from "./NetworkTable.styled";
// Components
import StatusCircle from "components/Shared/StatusCircle/StatusCircle";
// Hooks
import useTableData from "hooks/useTableData";
import useFilteredData from "hooks/useFilteredData";
import useNavigateCamera from "hooks/useNavigateCamera";
// Utils
import { highlightText } from "utils/highlightText";

const NetworkTable = ({ venueDetails, selectedFilters, searchTerm }) => {
  const tableData = useTableData(venueDetails);
  const filteredData = useFilteredData(tableData, selectedFilters, searchTerm);
  const { handleOpenCamera } = useNavigateCamera();

  return (
    <Styled.TableContainer>
      <Styled.Table>
        <Styled.TableHead>
          <Styled.TableRow>
            <Styled.TableHeader>Venue</Styled.TableHeader>
            <Styled.TableHeader>Area</Styled.TableHeader>
            <Styled.TableHeader>Camera</Styled.TableHeader>
            <Styled.TableHeader>Controller</Styled.TableHeader>
            <Styled.TableHeader>Inference</Styled.TableHeader>
            <Styled.TableHeader>Use Case</Styled.TableHeader>
            <Styled.TableHeader>Output</Styled.TableHeader>
          </Styled.TableRow>
        </Styled.TableHead>

        <Styled.TableBody>
          {filteredData.length === 0 ? (
            <Styled.TableRow>
              <Styled.TableCell colSpan="7">
                <Styled.EmptyMessage>No matching records found.</Styled.EmptyMessage>
              </Styled.TableCell>
            </Styled.TableRow>
          ) : (
            filteredData.map((camera, index) => (
              <Styled.TableRow
                key={`${camera.venueId}-${index}`}
                onClick={() => handleOpenCamera(camera)}
                clickable={!!camera.id}
              >
                <Styled.TableCell>{camera.venue}</Styled.TableCell>
                <Styled.TableCell>{camera.area}</Styled.TableCell>
                <Styled.TableCell>{highlightText(camera.camera, searchTerm)}</Styled.TableCell>
                <Styled.TableCell>{camera.controller}</Styled.TableCell>
                <Styled.TableCell>
                  <Styled.StatusContainer isActive={camera.isActive}>
                    <StatusCircle className="table" isActive={camera.isActive} />
                    <span>{camera.inference}</span>
                  </Styled.StatusContainer>
                </Styled.TableCell>
                <Styled.TableCell>{camera.useCase}</Styled.TableCell>
                <Styled.TableCell>
                  {camera.thumbnail && (
                    <Styled.OutputPreview img={camera.thumbnail} hasDockerId={camera.dockerId} />
                  )}
                </Styled.TableCell>
              </Styled.TableRow>
            ))
          )}
        </Styled.TableBody>
      </Styled.Table>
    </Styled.TableContainer>
  );
};

export default NetworkTable;
