import styled from "styled-components";

const styles = {
  CompanyContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
  `,
  SpinnerContainer: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
};

export default styles;
