import styled from "styled-components";

const styles = {
  SectionItemContainer: styled.div`
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  `,

  HeaderContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 20px;
  `,

  IconContainer: styled.div`
    display: flex;
    margin-top: 3px;
  `,

  SectionLabel: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    opacity: 0.9;
  `,

  ItemsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
  `,

  Item: styled.div`
    display: flex;
    padding: 7px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 97px;
    background: ${({ theme }) => theme.colors.neutral.grey200};
    font-size: ${({ theme }) => theme.fontSizes.field};
  `,
  NoItems: styled.div`
    color: ${({ theme }) => theme.colors.neutral.grey600};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-style: italic;
  `,
};

export default styles;
