import styled, { css } from "styled-components";
import noImgIcon from "../../../../assets/images/no-image-icon.png";
import noDockerIcon from "../../../../assets/images/no-docker-icon.png";

const styles = {
  TableContainer: styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;
  `,
  TableHead: styled.thead``,
  TableBody: styled.tbody``,
  TableRow: styled.tr`
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    }

    ${({ clickable }) =>
      clickable
        ? css`
            cursor: pointer;
            &:hover {
              background: ${({ theme }) => theme.colors.primary.lightBlue};
            }
          `
        : css`
            cursor: default;
          `}
  `,
  TableHeader: styled.th`
    background: ${({ theme }) => theme.colors.neutral.white};
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    padding: 0px 16px 12px 16px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    opacity: 0.6;
  `,
  TableCell: styled.td`
    height: 40px;
    padding: 12px;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    vertical-align: middle;
  `,
  StatusContainer: styled.div`
    display: flex;
    padding: 8px 10px 8px 8px;
    align-items: center;
    justify-content: space-evenly;
    gap: 7px;
    width: 100px;
    border-radius: 180px;
    background: ${({ theme }) => theme.surfaces.background};
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.camera.active : "none")};
  `,
  OutputPreview: styled.div`
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.surfaces.background};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => (props.img ? `url(${props.img})` : `url(${noImgIcon})`)};
    cursor: pointer;

    &:hover,
    &:focus {
      ${(props) => !props.hasDockerId && "background-image: url(" + noDockerIcon + ")"};
    }
  `,
  EmptyMessage: styled.div`
    padding: 16px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral.grey500};
  `,
};

export default styles;
