import styled from "styled-components";

const styles = {
  FallbackContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  `,
  ErrorMessage: styled.p`
    color: ${({ theme }) => theme.colors.error.red300};
    margin-bottom: 16px;
  `,
  RetryButton: styled.button`
    background-color: ${({ theme }) => theme.buttons.primary};
    color: ${({ theme }) => theme.colors.neutral.white};
    width: 180px;
    padding: 10px 20px;

    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.buttons.primaryHover};
    }
  `,
  ContactSupport: styled.p`
    margin-top: 32px;
  `,
};

export default styles;
