import React, { useState, useRef } from "react";
// Styles
import Styled from "./Filters.styled";
// Components
import Icon from "components/Shared/Icon/Icon";
import FiltersDropdown from "./FiltersDropdown/FiltersDropdown";
// Hooks
import useClickOutside from "hooks/useClickOutside";
import useExtractFilters from "hooks/useExtractFilters";

const Filters = ({ selectedFilters, setSelectedFilters, venueDetails }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const dropdownRef = useRef(null);
  const filters = useExtractFilters(venueDetails);

  const toggleDropdown = (id) => {
    setActiveFilter(activeFilter === id ? null : id);
  };

  useClickOutside(dropdownRef, () => setActiveFilter(null));

  return (
    <Styled.FiltersContainer ref={dropdownRef}>
      {filters.map((filter) => (
        <div key={filter.id}>
          <Styled.FilterButton onClick={() => toggleDropdown(filter.id)}>
            <span>{filter.name}</span>
            <Icon name="arrow_filter" />
          </Styled.FilterButton>
          {activeFilter === filter.id && (
            <FiltersDropdown
              filterData={filter.data}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              filterType={filter.type}
            />
          )}
        </div>
      ))}
    </Styled.FiltersContainer>
  );
};

export default Filters;
