import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const styles = {
  NavAndOutletContainer: styled.div``,
  SettingsNavigationContainer: styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    align-items: center;
    border-radius: 40px;
    padding: 8px;
    height: 60px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  SettingsOutletContainer: styled.div`
    background: ${({ theme }) => theme.colors.neutral.grey200};
  `,
  LinkContainer: styled.div`
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ activeTab, theme }) =>
      activeTab ? theme.colors.primary.blue800 : theme.colors.primary.blue200};
    box-sizing: border-box;
    border-radius: 50px;
    gap: 8px;
    padding: 10px 20px;
  `,
  Link: styled(Link)`
    color: ${({ activeTab, theme }) =>
      activeTab ? theme.colors.neutral.white : theme.colors.neutral.grey600};
    font-size: ${({ theme }) => theme.fontSizes.button};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    text-decoration: none;
  `,
};

export default styles;
