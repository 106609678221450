import { css } from "styled-components";

export const sharedStyles = css`
  display: flex;
  padding: ${({ padding }) => padding || "8px 12px"};
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: ${({ width }) => width || "max-content"};
  height: ${({ height }) => height || "auto"};

  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  font-size: ${({ theme }) => theme.fontSizes.button};
  font-weight: ${({ theme }) => theme.fonts.weights.semibold};
`;
