import React, { useState, useContext } from "react";
// Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
// Styles
import Styles from "./styles";
// Components
import { FormGroup } from "../Shared/FormGroup";
import SpinnerComponent from "../Loader";
// Context
import { AuthContext } from "../../context/authContext";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";

const signInSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email.").required("Email is a required field."),
  password: yup.string().min(4).max(15).required(),
});

const SignInComponent = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
  });
  const [loginErrors, setLoginErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { handleSignIn, signInGraphQlError, singInLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const submitForm = async (signInFormData) => {
    const loginResults = await handleSignIn(signInFormData.email, signInFormData.password);
    if (loginResults) {
      setLoginErrors(loginResults);
      reset();
    } else {
      navigate("/manage-companies");
      reset();
    }
  };

  usePreventEnterAction(submitForm);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Styles.AuthContainer>
      <Styles.FormContainer>
        <Styles.Title>Sign In</Styles.Title>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup labelText={"Email"} errorMessage={errors.email?.message}>
            <Styles.Input
              type="text"
              placeholder="email@website.com"
              name="email"
              {...register("email")}
            />
          </FormGroup>

          <div>
            <Styles.PasswordLabelsContainer>
              <Styles.InputLabel>Password</Styles.InputLabel>
              <Styles.ForgotPasswordLink>
                <Link to="/forgot-password">Forgot your password?</Link>
              </Styles.ForgotPasswordLink>
            </Styles.PasswordLabelsContainer>

            <FormGroup errorMessage={errors.password?.message}>
              <Styles.Input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Min. 4 character"
                onKeyUp={(e) => {
                  // if password is filled out and enter is pressed, submit form
                  if (e.key === "Enter" && e.target.value.length > 0) {
                    handleSubmit(submitForm)();
                  }
                }}
                {...register("password")}
              />
            </FormGroup>
          </div>

          {loginErrors ? (
            <Styles.SubmitServerError>{loginErrors}</Styles.SubmitServerError>
          ) : signInGraphQlError ? (
            <Styles.SubmitServerError>{signInGraphQlError}</Styles.SubmitServerError>
          ) : null}
          {singInLoading ? (
            <Styles.LoadingAndErrorContainer>
              <SpinnerComponent width={40} height={40} />
            </Styles.LoadingAndErrorContainer>
          ) : (
            <Styles.SubmitInput type="submit" id="submit" value="Login" />
          )}
        </Styles.Form>

        <Styles.RequestAccLink>
          <span>Don&apos;t have an account?</span>
          <Link to="/request-account">Sign up for Padmé</Link>
        </Styles.RequestAccLink>
      </Styles.FormContainer>
    </Styles.AuthContainer>
  );
};

export default SignInComponent;
