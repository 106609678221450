import React, { useMemo } from "react";
// Styles
import Styled from "./CompanySectionItem.styled";
// Components
import Icon from "components/Shared/Icon/Icon";

const CompanySectionItem = ({ title, items, icon, numberOfItems }) => {
  const { visibleItems, remainingItemsCount } = useMemo(() => {
    return {
      visibleItems: items.slice(0, 2),
      remainingItemsCount: items.length - 2,
    };
  }, [items]);

  return (
    <Styled.SectionItemContainer>
      <Styled.HeaderContainer>
        <Styled.IconContainer>
          <Icon name={icon} size="20px" />
        </Styled.IconContainer>

        <Styled.SectionLabel>
          {title} ({numberOfItems})
        </Styled.SectionLabel>
      </Styled.HeaderContainer>

      <Styled.ItemsContainer>
        {visibleItems.length > 0 ? (
          visibleItems.map((item) => <Styled.Item key={item.id}>{item.name}</Styled.Item>)
        ) : (
          <Styled.NoItems>No {title.toLowerCase()} available</Styled.NoItems>
        )}
        {remainingItemsCount > 0 && <Styled.Item>+{remainingItemsCount}</Styled.Item>}
      </Styled.ItemsContainer>
    </Styled.SectionItemContainer>
  );
};

export default CompanySectionItem;
